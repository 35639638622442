import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import {
  NavParams,
  ModalController,
  ToastController,
  Events,
  LoadingController
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import * as moment from "moment";

@Component({
  selector: 'app-manage-external-inventory-stones',
  templateUrl: './manage-external-inventory-stones.page.html',
  styleUrls: ['./manage-external-inventory-stones.page.scss'],
})
export class ManageExternalInventoryStonesPage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;

  public id: any;
  public selected = []
  public companyDetails = []
  public customerDetails = []
  public flagOpenDoc = 'stoneDetail';
  public actionName: any;
  public page = 'manageExternalStone'
  public count;
  public DcListCount: Number = 0;
  public columns = []
  public columnsForTransfer = []
  public issueStoneList = []
  public requestList = []
  public flagForReturn = false;
  public requestJson = {}
  public segment: any;
  public DCList = []
  public voucherNo: any;
  public voucherId: any;
  public isSelect: Boolean = false;
  public transType: any;
  public selectedStoneList = [];
  public deliveryChallanNo: any;
  public actionId: any;
  public status = "External"
  public voucherBookId: any;
  public fxRate: any;
  public returnCSSPList = [];
  public optionPO: any;
  public columnsPO = []
  public transporterDetails = [];
  public isNext = true;
  public listOfActions = []
  public userData: any;
  public toRole: any;
  public nameOfPersonReceivingGood: any;
  public flag: any;
  public terms: any;
  public disc1: any;
  public disc2: any;
  public voucherDate: any;
  public modeOfTransport: any;
  public remarks: any;
  public multipleChallanList = [];
  public fromPage: any;
  public isApproved: any;
  public boardBaseUrl: any;
  public RFidList: any = "";
  public pageSize = 50;
  public stonesIndex: any = [];
  public ledOn: boolean = false;
  companyId: any;
  voucherDetail: any;
  public invoiceNo = null;
  public invoiceDate = null;
  copyValue: any;
  public loadGrid: boolean = false;
  public showAddress: boolean = true;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public toastCtrl: ToastController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public event: Events,
    public loadingController: LoadingController
  ) { }

  @HostListener("window:keyup", ["$event"])
  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
      let x = document.activeElement.tagName;
      if (x != "ION-TEXTAREA") {
        if (navigator["clipboard"]) {
          navigator["clipboard"].readText().then((data) => {
            this.copyValue = data;
            console.log(this.copyValue);
            this.selectStones();
          });
        }
      }
    }
  }

  selectStones() {
    this.stonesIndex = []
    let records = this.copyValue.split("\n");
    let stoneWithPriceArr = [];
    for (let i = 1; i < records.length; i++) {
      if (records[i]) {
        let rowArray = records[i].trim().split("\t");
        stoneWithPriceArr.push({
          stoneId: rowArray[0],
          price: rowArray[1],
          remarks: rowArray[2],
        });
      }
    }
    for (let i = 0; i < this.issueStoneList.length; i++) {
      if (stoneWithPriceArr.filter(item => item.stoneId == this.issueStoneList[i].stoneId).length > 0) {
        let newCopyObjData = stoneWithPriceArr.filter(item => item.stoneId == this.issueStoneList[i].stoneId)[0]
        this.issueStoneList[i].offerPrice = newCopyObjData.price;
        this.issueStoneList[i].remarks = newCopyObjData.remarks || null;
        this.stonesIndex.push(this.issueStoneList[i])
      }
    }
    this.ledOn = true;
    setTimeout(() => {
      this.ledOn = false;
    }, 1000)
    console.log(this.stonesIndex)
  }

  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId();
    this.multipleChallanList = [{ id: 1, name: 'ORIGINAL', isSelect: true }, { id: 2, name: 'DUPLICATE/TRANSPORTER', isSelect: true }, { id: 3, name: 'TRIPLICATE', isSelect: true }]
    await this.getUserData()
    await this.getAllActions()
    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Stone Id", prop: "stoneId" },
      { name: "Rate per carat", prop: "requestPrice" },
      { name: "Sold Rate", prop: "offerPrice" },
      { name: "Ct.", prop: "stoneCts" },
      { name: "Remarks", prop: "remarks" },
      { name: "Status", prop: "status" },
    ];
    // this.columnsPO = [
    //   { name: "Sr No", prop: "" },
    //   { name: "Stone Id", prop: "stoneId" },
    //   { name: "Rate per carat", prop: "requestPrice" },
    //   { name: "Ct.", prop: "stoneCts" },
    // ];
    this.columnsForTransfer = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "CSSP", prop: "stoneId" },
      { name: "Issued Ct.", prop: "stoneCts" },
      { name: "Return Ct.", prop: "stoneCts" },
    ]
    this.voucherDetail = await this.navParams.get("data");

    this.actionName = await this.navParams.get("actionName");
    console.log(this.actionName)
    this.fromPage = await this.navParams.get("fromPage");
    this.segment = await this.navParams.get("title");
    this.flag = await this.navParams.get('flag')
    this.isApproved = this.voucherDetail.isApproved;
    if (this.fromPage == 'requestPage') {
      this.columns = [
        {
          name: 'Sr No',
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          }
        },
        { name: "Stone Id", prop: "stoneId" },
        { name: "Rate per carat", prop: "requestPrice" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Remarks", prop: "remarks" },
        { name: "Status", prop: "status" },
      ];
      console.log(this.voucherDetail)
      this.isApproved = this.voucherDetail.isTransferApproved;
      this.id = this.voucherDetail.referenceVoucherId;
      this.voucherId = this.voucherDetail.referenceVoucherId;
      this.deliveryChallanNo = this.voucherDetail.referenceVoucher;
      this.voucherNo = this.voucherDetail.referenceVoucher;
    } else {
      this.id = this.voucherDetail.id;
      this.voucherId = this.voucherDetail.id;
      this.deliveryChallanNo = this.voucherDetail.shortCode + "-" + this.voucherDetail.bookSerialNumber;
      this.voucherNo = this.voucherDetail.shortCode + "-" + this.voucherDetail.bookSerialNumber;
      this.invoiceNo = !!this.voucherDetail.voucherLabel ? this.voucherDetail.voucherLabel : null;
      if (!!this.voucherDetail.accInvoiceDate) {
        this.invoiceDate = moment(this.voucherDetail.accInvoiceDate).format('YYYY-MM-DD');
      }
    }
    this.voucherDate = this.voucherDetail.voucherDate;
    console.log(this.voucherDetail, this.actionName)
    await this.getCompanyDetail();
    if (this.voucherDetail.brokerName) {
      this.nameOfPersonReceivingGood = this.voucherDetail.brokerName
    }
    else if (this.voucherDetail.partyMasterId) {
      this.nameOfPersonReceivingGood = this.voucherDetail.customerContact
    }
    else if (this.voucherDetail.toLocation) {
      this.nameOfPersonReceivingGood = this.voucherDetail.username;
    }
    if (this.voucherDetail.partyMasterId) {
      await this.getCustomerDetails(this.voucherDetail.partyMasterId);
    } else if (this.voucherDetail.toLocation) {
      await this.getCompanyDetailByName(this.voucherDetail.toLocation)
    }
    await this.findTransferRequestStoneByVoucherId();
    await this.findAllTransferRequestedByVoucherIdForDC();
  }

  async reloadChallan() {
    await this.configService.showLoader()
    await this.configService.dismiss();
  }

  //To company detail for recut and observation 

  async getCompanyDetailByName(companyName) {
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async getUserData() {
    this.userData = await this.authService.userData();
    if (this.userData.boardBaseUrl) {
      this.boardBaseUrl = this.userData.boardBaseUrl;
    }
    console.log(this.userData)
  }

  // transporter detail 

  // async getTransporterDetail(type) {
  //   let res: any;
  //   res = await this.httpApiService.getAllCustomersByType(type);
  //   let data = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     if (data.data.length > 0) {
  //       this.transporterDetails = data.data[0];
  //     }
  //     console.log("transporterDetails", this.transporterDetails);
  //   }
  // }

  async getAllActions() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getAllActionsByUserID(userData.userId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
    }
  }

  async getDefaultSetting(actionName, actionId) {
    let res: any;
    res = await this.httpApiService.getDefaultSetting(
      this.userData.userId,
      actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
      }
      console.log(this.toRole);
      console.log(this.voucherBookId);
    }
  }

  async findTransferRequestStoneByVoucherId() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.findTransferRequestStoneByVoucherId(this.id, this.actionName);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.issueStoneList = data.data;
      if (this.issueStoneList.length > 0) {
        this.transType = this.issueStoneList[0].transType;
        this.issueStoneList.forEach(el => {
          el.offerPrice = el.requestPrice
        });
      }
      if (this.transType == 'MIX') {

        if (this.actionName == 'Recut' || this.actionName == 'Observation') {
          this.columns = [
            {
              name: 'Sr No',
              cellTemplate: function (cellElement, cellInfo) {
                cellElement.append(cellInfo.row.rowIndex + 1);
              }
            },
            { name: "CSSP", prop: "stoneId" },
            { name: "Rate per carat", prop: "requestPrice" },
            { name: "Pcs", prop: "pcs" },
            { name: "Sold Rate", prop: "offerPrice" },
            { name: "Ct.", prop: "stoneCts" },
          ];
        } else {
          this.columns = [
            {
              name: 'Sr No',
              cellTemplate: function (cellElement, cellInfo) {
                cellElement.append(cellInfo.row.rowIndex + 1);
              }
            },
            { name: "CSSP", prop: "stoneId" },
            { name: "Rate per carat", prop: "requestPrice" },
            { name: "Sold Rate", prop: "offerPrice" },
            { name: "Ct.", prop: "stoneCts" },
          ];
        }
      }
      this.count = this.issueStoneList.length;
      console.log(this.issueStoneList)

    }
  }

  async findAllTransferRequestedByVoucherIdForDC() {
    let res: any;
    res = await this.httpApiService.getTransferRequestedByVoucherIdForDC(this.id, this.actionName);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.DCList = data.data;
      this.DcListCount = data.data.length;
      if (this.DCList.length > 0) {
        this.fxRate = !!this.DCList[0].exgRate ? this.DCList[0].exgRate : 0;
        this.modeOfTransport = !!this.DCList[0].modeOfTransport ? this.DCList[0].modeOfTransport : '';
        this.transType = this.DCList[0].transType;
        this.remarks = !!this.DCList[0].remarks ? this.DCList[0].remarks.toString() : '';
        this.terms = !!this.DCList[0].terms ? this.DCList[0].terms : 0;
        this.disc1 = !!this.DCList[0].disc1 ? this.DCList[0].disc1 : 0;
        this.disc2 = !!this.DCList[0].disc2 ? this.DCList[0].disc2 : 0;
      }
      console.log(this.fxRate, this.DCList)
    }
  }

  async getCompanyDetail() {
    let companyName = await this.configService.getFromLocation()
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.companyDetails = data.data;
    }
  }

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  async previewDocument(view, po) {
    this.multipleChallanList = [{ id: 1, name: 'ORIGINAL', isSelect: true }, { id: 2, name: 'DUPLICATE/TRANSPORTER', isSelect: true }, { id: 3, name: 'TRIPLICATE', isSelect: true }]
    this.flagOpenDoc = view;
    if (po) {
      this.optionPO = po;
    }
  }

  async printDocument() {
    await this.configService.print();
  }

  // async generatePo() {
  //   await this.getVoucherBook('PO');
  //   if (!!this.voucherBookId) {
  //     this.selected.forEach((el, i) => {
  //       this.requestJson[el.id] = el.offerPrice ? el.offerPrice.toString() : null
  //     });
  //     await this.configService.showLoader();
  //     let res: any;
  //     let jsonObj = {
  //       voucherDate: moment().format("YYYY-MM-DD"),
  //       voucherBookId: this.voucherBookId,
  //       status: "POConfirmed",
  //       type: "PO Confirmed",
  //       mapOfStonePrice: this.requestJson,
  //     }
  //     console.log(jsonObj)
  //     res = await this.httpApiService.markStoneAsConfirmed(jsonObj);
  //     let data = res.json()
  //     await this.configService.dismiss();
  //     if (res.status == 401) {
  //       this.authService.logout();
  //     } else if (res.status == 500) {
  //       await this.configService.showToast("error", data.error);
  //     } else if (res.status == 400) {
  //       await this.configService.showToast("error", data.error);
  //     } else {
  //       await this.configService.showToast("success", data.message);
  //       this.modalCtrl.dismiss(this.flag);
  //     }
  //   } else {
  //     await this.configService.showToast("error", 'Voucherbook not found');
  //   }
  // }

  async selectAll(item, itemList) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(itemList)
      this.isSelect = true;
      this.selectedStoneList = [];
      this.selected.forEach(el => {
        this.selectedStoneList.push(el.stoneId)
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedStoneList = []
    }
  }

  onSelect(ev, itemList) {
    this.selected = ev.selectedRowsData;
    console.log(this.selected)
    this.selectedStoneList = []
    this.selected.forEach(el => {
      this.selectedStoneList.push(el.stoneId)

    });
    if (this.selected.length == itemList.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  // async getAllPOStonesByVoucherId() {
  //   let res: any;
  //   res = await this.httpApiService.getAllConfirmedStonesWithStatus(this.id, 'POConfirmed');
  //   let data = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.issueStoneList = data.data.content;
  //     console.log(this.issueStoneList, data)
  //     this.count = this.issueStoneList.length;
  //     this.issueStoneList.forEach(el => {
  //       this.selectedStoneList.push(el.stoneId)
  //     });
  //     if (this.issueStoneList.length > 0) {
  //       this.terms = !!this.issueStoneList[0].termName ? this.issueStoneList[0].termName : 0;
  //       this.disc1 = !!this.issueStoneList[0].disc1 ? this.issueStoneList[0].disc1 : 0;
  //       this.disc2 = !!this.issueStoneList[0].disc2 ? this.issueStoneList[0].disc2 : 0;
  //     }
  //   }
  // }

  async markAsSold() {
    await this.getVoucherBook('Sold');
    if (!!this.voucherBookId) {
      await this.configService.showLoader();
      let mapOfStonePrice = {}
      this.selected.forEach((el, i) => {
        console.log(el)
        mapOfStonePrice[el.id] = el.offerPrice ? el.offerPrice.toString() : el.requestPrice.toString()
      });
      let dataObj = {
        mapOfStonePrice: mapOfStonePrice,
        setOfValues: this.selectedStoneList,
        voucherBookId: this.voucherBookId,
        remarks: "Sold Stone",
        status: "Sold Confirmed",
        type: 'Sold',
        transferOnRequestStatus: 'Pending',
        voucherDate: moment().format("YYYY-MM-DD"),
        companyId: this.companyId
      };

      let res = await this.httpApiService.markStoneAsConfirmed(dataObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 200) {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.flag);
      }
    } else {
      await this.configService.showToast("error", 'Voucherbook not found');
    }
  }

  async transferToAction() {
    await this.getVoucherBook('Customer Confirm');
    if (!!this.voucherBookId) {
      await this.configService.showLoader();
      let stone = {}
      this.selected.forEach((el, i) => {
        stone[el.id] = {
          "requestPrice": el.offerPrice ? el.offerPrice.toString() : el.requestPrice.toString(),
          "remarks": el.remarks ? el.remarks : null
        };
      });
      console.log(stone)
      let dataObj = {
        stoneRequestParams: stone,
        voucherDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        voucherBookId: parseInt(this.voucherBookId),
        // setOfValues: this.selectedStoneList,
        isApproved: true,
        fromAction: "Memo client",
        toAction: "Customer Confirm",
        companyId: this.companyId
      }
      console.log(dataObj);
      let res = await this.httpApiService.transferStoneByAction(dataObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 200) {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.flag);
      }
    } else {
      await this.configService.showToast("error", 'Voucherbook not found');
    }
  }

  openReturn() {
    this.returnCSSPList = []
    this.flagForReturn = true;
    let arr = []
    this.returnCSSPList = arr.concat(this.selected);
    this.returnCSSPList.forEach(el => {
      el.size = parseFloat(el.stoneCts);
    })
    console.log(this.returnCSSPList)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  //update price in cart

  async updateValue(event, cell, rowIndex) {
    console.log('inline editing rowIndex', cell, rowIndex, event.target.value)

    this.returnCSSPList[rowIndex][cell] = parseFloat(event.target.value);

    this.returnCSSPList = [...this.returnCSSPList];
    if (this.returnCSSPList[rowIndex][cell] > this.returnCSSPList[rowIndex]['stoneCts'] ||
      this.returnCSSPList[rowIndex][cell] == 0 ||
      this.returnCSSPList[rowIndex][cell] == "") {
      this.returnCSSPList[rowIndex]['errorCt'] = true;
    } else {
      this.returnCSSPList[rowIndex]['errorCt'] = false;
    }
    if (!this.returnCSSPList[rowIndex][cell]) {
      this.returnCSSPList[rowIndex][cell] = 0
    }
    for (let i = 0; i < this.returnCSSPList.length; i++) {
      if (
        parseFloat(this.returnCSSPList[i].size) >
        parseFloat(this.returnCSSPList[i].stoneCts) ||
        parseFloat(this.returnCSSPList[i].size) == 0 ||
        this.returnCSSPList[i].size == ""
      ) {
        this.isNext = false;
      } else {
        this.isNext = true;
      }
    }
    console.log(this.isNext, this.returnCSSPList)
  }

  async getVoucherBook(actionName) {
    let actionArray = this.listOfActions.filter(function (item) {
      if (item.actionName == actionName) {
        return item.actionName;
      }
    });
    console.log(actionArray)
    if (actionArray.length > 0) {
      await this.getDefaultSetting(this.userData.userId, actionArray[0].id);
    }
  }

  async returnCssp() {
    await this.getVoucherBook('Return');
    let setOfStoneIds = []
    this.requestList = []
    this.returnCSSPList.forEach((el) => {
      setOfStoneIds.push(el.id)
      this.requestList.push({
        "stoneId": el.id.toString(),
        "size": el.size ? el.size : el.stoneCts,
      })
    })
    if (!!this.voucherBookId) {
      await this.configService.showLoader()
      let jsonObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        // setOfStoneIds: setOfStoneIds,
        setOfStoneParameter: this.requestList,
        voucherBookId: this.voucherBookId,
        toRole: this.toRole,
        companyId: this.companyId
      };
      console.log(jsonObj)
      let res: any;
      res = await this.httpApiService.returnStone(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.flag)
      }
    } else {
      await this.configService.showToast('error', 'Voucher book not found.')
    }

  }


  async offerValueUpdate(event) {
    this.updatePrice(event.value, event.column.dataField, event.rowIndex)
  }


  //update price in cart
  async updatePrice(value, cell, rowIndex) {
    console.log('inline editing rowIndex', cell, rowIndex)
    this.issueStoneList[rowIndex][cell] = parseFloat(value);
    if (cell == 'pcs') {
      this.DCList[rowIndex][cell] = parseFloat(value);
    }
    this.issueStoneList = [...this.issueStoneList];
    // this.editing[rowIndex + '-' + cell] = false;
    let errorPrice = 'errorPrice'
    if (!!this.issueStoneList[rowIndex][cell] && this.issueStoneList[rowIndex][cell] > 0
    ) {
      this.isNext = true;
      this.issueStoneList[rowIndex][errorPrice] = false;
    } else {
      this.issueStoneList[rowIndex][errorPrice] = true;
      this.issueStoneList[rowIndex][cell] = 0;
    }
    let flag: boolean;
    this.issueStoneList.forEach(item => {
      if (parseFloat(item.offerPrice) == null || parseFloat(item.offerPrice) == undefined ||
        parseFloat(item.offerPrice) <= 0) {
        this.isNext = false;
      }
    })
    // this.issueStoneList.forEach(item => {
    //   if (parseFloat(item.offerPrice) > 0 && item.size != "") {
    //     flag = true;
    //     this.isNext = true;
    //   }
    // })
    // if (flag == false || flag == undefined) {
    //   this.isNext = false;
    // }
    console.log(this.issueStoneList, this.isNext);
  }

  async updateInvoiceDetail() {
    if (!!this.invoiceNo && !!this.invoiceDate) {
      let jsobObj = {
        invoiceDate: moment(this.invoiceDate).format('DD-MMM-YYYY'),
        invoiceNo: this.invoiceNo
      }
      await this.configService.showLoader()
      let res: any = await this.httpApiService.updateInvoiceDetail(jsobObj, this.voucherId);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    } else {
      await this.configService.showToast('error', 'Please fill invoice no and date')
    }
  }

  async approveVoucher() {
    let res: any;
    let mapOfStonePrice = []
    this.issueStoneList.forEach(el => {
      mapOfStonePrice.push({
        stoneId: el.id,
        sellingPrice: el.offerPrice ? el.offerPrice.toString() : el.requestPrice.toString(),
        size: !!el.stoneCts ? el.stoneCts : el.size
      })
    });
    let jsobObj = {
      voucherId: this.id,
      setOfStoneParameter: mapOfStonePrice
    }
    console.log(jsobObj)
    await this.configService.showLoader()
    res = await this.httpApiService.approveVoucher(jsobObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss()
    }
  }

  async returnStone() {
    await this.getVoucherBook('Return');
    if (!!this.voucherBookId) {
      this.requestList = []
      this.selected.forEach(el => {
        this.requestList.push({
          "stoneId": el.id.toString(),
          "size": el.size ? el.size : el.stoneCts,
        })
      })
      let jsonObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        // setOfStoneIds: setOfStoneIds,
        setOfStoneParameter: this.requestList,
        voucherBookId: this.voucherBookId,
        toRole: this.toRole,
        companyId: this.companyId
      };

      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.returnStone(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.flag)
      }
    } else {
      await this.configService.showToast("error", 'Voucherbook not found');
    }
  }

  async scanToSelect() {
    await this.configService.showLoader();
    let filtered = this.RFidList.split("\n");
    let issueList = []
    filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    filtered = issueList.filter(function (el) {
      return el != "";
    });
    if (filtered.length > 0) {
      this.selected = [];
      this.selectedStoneList = []
      for (let i = 0; i < filtered.length; i++) {
        let el = filtered[i];
        let index = this.issueStoneList.findIndex(item => item.stoneId == el);
        this.stonesIndex.push(index);
        if (index > -1) {
          this.selected.push(this.issueStoneList[index])
          this.selectedStoneList.push(this.issueStoneList[index].stoneId);
        }
      }
      this.ledOn = true;
      setTimeout(() => {
        this.ledOn = false;
      }, 1000)
      if (this.selectedStoneList.length == this.issueStoneList.length) {
        this.isSelect = true;
      } else {
        this.isSelect = false;
      }
      console.log(this.selected, this.selectedStoneList)
    } else {
      await this.configService.showToast("error", 'Stones not found');
    }
    this.configService.dismiss();

  }


  async donwloadExcelByVoucherId() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.donwloadFileByVoucherId(this.voucherDetail.id, this.actionName);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data)
      await this.configService.showToast("success", data.message);
      if (!!data.data) {
        await this.download(data.data)
      }
    }

  }

  async download(url) {
    await this.httpApiService.downloadPackingList(url)
  }

  changeDisplayAddress() {

  }
}



