import { Component, Input } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  Events,
  NavParams
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { ManageFtpMasterPage } from "../manage-ftp-master/manage-ftp-master.page";

@Component({
  selector: 'app-ftp-master',
  templateUrl: './ftp-master.component.html',
  styleUrls: ['./ftp-master.component.scss']
})
export class FtpMasterComponent implements ProcessActionComponent {
  @Input() data: any;

  public refreshGrid: Boolean = false;
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 100;
  public selectedIds = []
  public items = []
  public count = 0;
  public columns = [];
  public ftpData = [];
  public page = "FTP Master";

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Id", prop: "id" },
    { name: "Export Template", prop: "templateName" },
    { name: "Search Criteria", prop: "searchTitle" },
    { name: "Party Name", prop: "partyName" },
    { name: "Export Via", prop: "exportVia" },
    { name: "File Name", prop: "fileName" },
    { name: "isActive", prop: "isActive" },
    // { name: "Upcoming Run", prop: "upcomingRun" },
    // { name: "Upcoming Status", prop: "upcomingStatus" },
    // { name: "Last Run", prop: "lastRun" },
    // { name: "Last Status", prop: "lastStatus" },
    { name: "Date & Time", prop: "createdDate" },
    ]

    this.getFtpData()
    this.event.subscribe('Schedule Stock Export', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Id", prop: "id" },
      { name: "Export Template", prop: "templateName" },
      { name: "Search Criteria", prop: "searchTitle" },
      { name: "Party Name", prop: "partyName" },
      { name: "Export Via", prop: "exportVia" },
      { name: "File Name", prop: "fileName" },
      { name: "isActive", prop: "isActive" },
      // { name: "Upcoming Run", prop: "upcomingRun" },
      // { name: "Upcoming Status", prop: "upcomingStatus" },
      // { name: "Last Run", prop: "lastRun" },
      // { name: "Last Status", prop: "lastStatus" },
      { name: "Date & Time", prop: "createdDate" },
      ]
      this.getFtpData()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Schedule Stock Export')
  }

  async manageFtp(item, flag) {
    this.refreshGrid = false
    if (flag == 'update') {
      let modal = await this.modalCtrl.create({
        component: ManageFtpMasterPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    } if (flag == 'add') {
      let modal = await this.modalCtrl.create({
        component: ManageFtpMasterPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async confirmchangeFTPState(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: `Are you sure you want to ${item} all Exports?`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.changeFTPState(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async changeFTPState(state) {
    await this.configService.showLoader();
    if (!!this.selectedIds && this.selectedIds.length > 0) {
      if (state == 'disable') {
        let res: any;
        let payload =
        {
          "taskInputJsonIds": this.selectedIds,
          "taskType": "FTP Master"
        }
        res = await this.httpApiService.disableNotificationMaster(payload);
        let data = res.json();
        await this.configService.dismiss();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          // this.selectedIds = []
          this.refreshGrid = true
          await this.configService.showToast("success", 'Schedule Stock Export disabled successfully');
        }
      } else {
        let res: any;
        let payload = {
          "listOfIds": this.selectedIds,
        }
        res = await this.httpApiService.enableFTPNotification(payload);
        let data = res.json();
        await this.configService.dismiss();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          this.refreshGrid = true
          // this.selectedIds = []
          await this.configService.showToast("success", 'Schedule Stock Export enabled successfully');
        }
      }
    } else {
      await this.configService.showToast("error", 'Please select records');
    }

  }

  async addInstantFTP() {

    if (!!this.selectedIds && this.selectedIds.length > 0) {
      await this.configService.showLoader();
      let res: any;
      let payload = {
        "listOfIds": this.selectedIds
      }
      res = await this.httpApiService.enableInstantFTPNotification(payload);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.refreshGrid = true
        // this.selectedIds = []
        await this.configService.showToast("success", 'Exports enabled successfully');
      }
    } else {
      await this.configService.showToast("error", 'Please select records');
    }

  }

  editItem(event) {
    console.log(event.row.data)
    this.manageFtp(event.row.data, 'update')
  }

  async onselect(event) {
    console.log(event)
    if (event.selectedRowsData.length > 0) {
      this.selectedIds = event.selectedRowsData.map(el => el.id)
    } else {
      this.selectedIds = []
    }
  }

  async handleModalDismiss(d) {
    await this.getFtpData();
    this.refreshGrid = true

  }

  async getFtpData() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getFtpData();
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.ftpData = data.data;
    }
  }

  async deleteItem(ev) {
    this.confirmDeleteData(ev.row.data);
  }

  async confirmDeleteData(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteData(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteData(id) {
    await this.configService.showLoader();
    let res = await this.httpApiService.deleteFtp(
      id
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.message);
    } else if (!data.isSuccess) {
      await this.configService.showToast(
        "error",
        data.data
      );

    } else {
      await this.configService.showToast(
        "success",
        data.data
      );
    }
    await this.getFtpData()
  }


}
