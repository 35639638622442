export default {
  parameters: [
    {
      masterName: 'Barcode',
      id: 0,
      resultObject: { name: 'Barcode', prop: 'Barcode' },
    },
    {
      masterName: 'Our Shape',
      id: 0,
      resultObject: { name: 'Our Shape', prop: 'OurShape' },
    },
    {
      masterName: 'Our Color',
      id: 0,
      resultObject: { name: 'Our Color', prop: 'OurColor' },
    },
    {
      masterName: 'Our Clarity',
      id: 0,
      resultObject: { name: 'Our Clarity', prop: 'OurClarity' },
    },
    {
      masterName: 'Basket Status',
      id: 1,
      resultObject: { name: '', prop: '' },
    },
    {
      masterName: 'BDMM',
      id: 2,
      resultObject: { name: 'BDMM', prop: 'BDMMCode' },
    },
    {
      masterName: 'Better',
      id: 3,
      resultObject: { name: 'Better', prop: '' },
    },
    {
      masterName: 'Black Center',
      id: 4,
      resultObject: { name: 'Black Center', prop: 'BlackCenterCode' },
    },
    {
      masterName: 'Black Inclusion',
      id: 5,
      resultObject: { name: 'Black Inclusion', prop: 'BlackInclusionCode' },
    },
    {
      masterName: 'Black Side',
      id: 6,
      resultObject: { name: 'Black Side', prop: 'NBlackSideCode' },
    },
    {
      masterName: 'Bom program rate',
      id: 7,
      resultObject: { name: 'Bom program rate', prop: 'Bomprogramrate' },
    },
    {
      masterName: 'C1C',
      id: 8,
      resultObject: { name: 'C1C', prop: '' },
    },
    {
      masterName: 'C1S',
      id: 9,
      resultObject: { name: 'C1S', prop: '' },
    },
    {
      masterName: 'C2C',
      id: 10,
      resultObject: { name: 'C2C', prop: '' },
    },
    {
      masterName: 'C2S',
      id: 11,
      resultObject: { name: 'C2S', prop: '' },
    },
    {
      masterName: 'C3C',
      id: 12,
      resultObject: { name: 'C3C', prop: '' },
    },
    {
      masterName: 'C3S',
      id: 13,
      resultObject: { name: 'C3S', prop: '' },
    },
    {
      masterName: 'Center',
      id: 14,
      resultObject: { name: 'Center', prop: '' },
    },
    {
      masterName: 'Center Duccha',
      id: 15,
      resultObject: { name: 'Center Duccha', prop: 'CenterDucchaCode' },
    },
    {
      masterName: 'Center Feather/Glades',
      id: 16,
      resultObject: { name: 'Center Feather/Glades', prop: '' },
    },
    {
      masterName: 'Center Opens',
      id: 17,
      resultObject: { name: 'Center Opens', prop: '' },
    },
    {
      masterName: 'Center Spot',
      id: 18,
      resultObject: { name: 'Center Spot', prop: 'CenterSpotCode' },
    },
    {
      masterName: 'Center Minor',
      id: 19,
      resultObject: { name: 'Center Minor', prop: 'CenterMinorCode' },
    },
    {
      masterName: 'Center Pk',
      id: 20,
      resultObject: { name: 'Center Pk', prop: '' },
    },
    {
      masterName: 'Cert Date',
      id: 21,
      resultObject: { name: 'Cert Date', prop: 'CertDate' },
    },
    {
      masterName: 'Certificate filename',
      id: 22,
      resultObject: {
        name: 'Certificate filename',
        prop: 'Certificatefilename',
      },
    },
    {
      masterName: 'Chips',
      id: 23,
      resultObject: { name: 'Chips', prop: '' },
    },
    {
      masterName: 'Clarity',
      id: 24,
      resultObject: { name: 'Clarity', prop: 'ClarityCode' },
    },
    {
      masterName: 'Clarity Status',
      id: 25,
      resultObject: { name: 'Clarity Status', prop: 'ClarityStatus' },
    },
    {
      masterName: 'Clarity Tole Type',
      id: 26,
      resultObject: { name: 'Clarity Tole Type', prop: 'ClarityToleTypeCode' },
    },
    {
      masterName: 'Color',
      id: 27,
      resultObject: { name: 'Color', prop: 'ColorCode' },
    },
    {
      masterName: 'Color Descriptions',
      id: 28,
      resultObject: { name: 'Color Descriptions', prop: 'ColorDescriptions' },
    },
    {
      masterName: 'Color Shade',
      id: 29,
      resultObject: { name: 'Color Shade', prop: 'ColorShadeCode' },
    },
    {
      masterName: 'Color Tinge',
      id: 30,
      resultObject: { name: 'Color Tinge', prop: 'ColorTingeCode' },
    },
    {
      masterName: 'Color Tole Type',
      id: 31,
      resultObject: { name: 'Color Tole Type', prop: 'ColorToleTypeCode' },
    },
    {
      masterName: 'Compro FLG',
      id: 32,
      resultObject: { name: 'Compro FLG', prop: 'ComproFLG' },
    },
    {
      masterName: 'Compro Remark',
      id: 33,
      resultObject: { name: 'Compro Remark', prop: 'ComproRemark' },
    },
    {
      masterName: 'Crown Extra Facet',
      id: 34,
      resultObject: { name: 'Crown Extra Face', prop: 'CrownExtraFacetCode' },
    },
    {
      masterName: 'Crown Open Inclusion',
      id: 35,
      resultObject: {
        name: 'Crown Open Inclusion',
        prop: 'CrownOpenInclusionCode',
      },
    },
    {
      masterName: 'Cr Angle',
      id: 36,
      resultObject: { name: 'Cr Angle', prop: 'CrAngle' },
    },
    {
      masterName: 'Cr Hgt',
      id: 37,
      resultObject: { name: 'Cr Hgt', prop: 'CrHgt' },
    },
    {
      masterName: 'Cssp',
      id: 38,
      resultObject: { name: 'CSSP', prop: 'Cssp' },
    },
    {
      masterName: 'cts',
      id: 39,
      resultObject: { name: 'Carat', prop: 'cts', summaryType: 'sum' },
    },
    {
      masterName: 'Culets',
      id: 40,
      resultObject: { name: 'Culets', prop: 'Culets' },
    },
    {
      masterName: 'Culet Size',
      id: 41,
      resultObject: { name: 'Culet Size', prop: 'CuletSizeCode' },
    },
    {
      masterName: 'Cut',
      id: 42,
      resultObject: { name: 'Cut', prop: 'CutCode' },
    },
    {
      masterName: 'Diamond Sure',
      id: 43,
      resultObject: { name: 'Diamond Sure', prop: 'DiamondSureCode' },
    },
    {
      masterName: 'Diameter',
      id: 44,
      resultObject: { name: 'Diameter', prop: 'Diameter' },
    },
    {
      masterName: 'Dibox',
      id: 45,
      resultObject: { name: 'Dibox', prop: 'Dibox' },
    },
    {
      masterName: 'Dibox Status',
      id: 46,
      resultObject: { name: 'Dibox Status', prop: 'DiboxStatus' },
    },
    {
      masterName: 'Eye Clean',
      id: 47,
      resultObject: { name: 'Eye Clean', prop: 'EyeCleanCode' },
    },
    {
      masterName: 'Extra Facet',
      id: 48,
      resultObject: { name: 'Extra Facet', prop: 'ExtraFacetCode' },
    },
    {
      masterName: 'Exp Rate',
      id: 49,
      resultObject: { name: 'Exp Rate', prop: 'ExpRate' },
    },
    {
      masterName: 'F1C',
      id: 50,
      resultObject: { name: 'F1C', prop: '' },
    },
    {
      masterName: 'F1S',
      id: 51,
      resultObject: { name: 'F1S', prop: '' },
    },
    {
      masterName: 'F2C',
      id: 52,
      resultObject: { name: 'F2C', prop: '' },
    },
    {
      masterName: 'F2S',
      id: 53,
      resultObject: { name: 'F2S', prop: '' },
    },
    {
      masterName: 'F3C',
      id: 54,
      resultObject: { name: 'F3C', prop: '' },
    },
    {
      masterName: 'F3S',
      id: 55,
      resultObject: { name: 'F3S', prop: '' },
    },
    {
      masterName: 'Faceted',
      id: 56,
      resultObject: { name: 'Faceted', prop: '' },
    },
    {
      masterName: 'Factory Outw No',
      id: 57,
      resultObject: { name: 'Factory Outw No', prop: 'factoryOutWNo' },
    },
    {
      masterName: 'Factory Outw Date',
      id: 58,
      resultObject: { name: 'Factory Outw Date', prop: 'factoryOutWDate' },
    },
    {
      masterName: 'Fact Io Date',
      id: 59,
      resultObject: { name: 'Fact Io Date', prop: 'factIODate' },
    },
    {
      masterName: 'Fact Seqno',
      id: 60,
      resultObject: { name: 'Fact Seqno', prop: 'factSeqNo' },
    },
    {
      masterName: 'Fact Sub Seqno',
      id: 61,
      resultObject: { name: 'Fact Sub Seqno', prop: 'factSubSeqNo' },
    },
    {
      masterName: 'Factory Seqno',
      id: 62,
      resultObject: { name: 'Factory Seqno', prop: 'factorySeqNo' },
    },
    {
      masterName: 'Fancy Color Intensity',
      id: 63,
      resultObject: {
        name: 'Fancy Color Intensity',
        prop: 'FancyColorIntensityCode',
      },
    },
    {
      masterName: 'Fancy Color Overtone',
      id: 64,
      resultObject: {
        name: 'Fancy Color Overtone',
        prop: 'FancyColorOvertoneCode',
      },
    },
    {
      masterName: 'Fancy Color Origin',
      id: 65,
      resultObject: {
        name: 'Fancy Color Origin',
        prop: 'FancyColorOriginCode',
      },
    },
    {
      masterName: 'Fancy Color',
      id: 66,
      resultObject: { name: 'Fancy Color', prop: 'FancyColorCode' },
    },
    {
      masterName: 'Fct final remarks',
      id: 67,
      resultObject: { name: 'Fct final remarks', prop: 'Fctfinalremarks' },
    },
    {
      masterName: 'Fct plan remarks',
      id: 68,
      resultObject: { name: 'Fct plan remarks', prop: 'Fctplanremarks' },
    },
    {
      masterName: 'Feather',
      id: 69,
      resultObject: { name: 'Feather', prop: '' },
    },
    {
      masterName: 'Fluorescence',
      id: 70,
      resultObject: { name: 'Fluorescence', prop: 'FluorescenceCode' },
    },
    {
      masterName: 'Fluorescence Color',
      id: 71,
      resultObject: {
        name: 'Fluorescence Color',
        prop: 'FluorescenceColorCode',
      },
    },
    {
      masterName: 'Flour Tole Type',
      id: 72,
      resultObject: { name: 'Flour Tole Type', prop: 'FlourToleTypeCode' },
    },
    {
      masterName: 'FM Barcode',
      id: 73,
      resultObject: { name: 'FM Barcode', prop: 'FMBarcode' },
    },
    {
      masterName: 'Fm/Nfm',
      id: 74,
      resultObject: { name: 'Fm/Nfm', prop: '' },
    },
    {
      masterName: 'FM Inscription',
      id: 75,
      resultObject: { name: 'FM Inscription', prop: 'FMInscription' },
    },
    {
      masterName: 'FM Location',
      id: 76,
      resultObject: { name: 'FM Location', prop: 'FMLocation' },
    },
    {
      masterName: 'FM Reason',
      id: 77,
      resultObject: { name: 'FM Reason', prop: 'FMReason' },
    },
    {
      masterName: 'FM Service',
      id: 78,
      resultObject: { name: 'FM Service', prop: '' },
    },
    {
      masterName: 'Fm Origin',
      id: 79,
      resultObject: { name: 'Fm Origin', prop: 'FmOrigin' },
    },
    {
      masterName: 'Fm Str',
      id: 80,
      resultObject: { name: 'Fm Str', prop: 'FmStr' },
    },
    {
      masterName: 'Frm',
      id: 81,
      resultObject: { name: 'Frm', prop: '' },
    },
    {
      masterName: 'F Pktno',
      id: 82,
      resultObject: { name: 'F Pktno', prop: 'FPktno' },
    },
    {
      masterName: 'Girdle',
      id: 83,
      resultObject: { name: 'Girdle', prop: 'GirdleCode' },
    },
    {
      masterName: 'Girdle Condition',
      id: 84,
      resultObject: { name: 'Girdle Condition', prop: 'GirdleCondition' },
    },
    {
      masterName: 'Girdle Open Inclusion',
      id: 85,
      resultObject: {
        name: 'Girdle Open Inclusion',
        prop: 'GirdleOpenInclusionCode',
      },
    },
    {
      masterName: 'Girdle Per',
      id: 86,
      resultObject: { name: 'Girdle Per', prop: 'GirdlePer' },
    },
    {
      masterName: 'Graining',
      id: 87,
      resultObject: { name: 'Graining', prop: 'GrainingCode' },
    },
    {
      masterName: 'Grn',
      id: 88,
      resultObject: { name: 'Grn', prop: '' },
    },
    {
      masterName: 'G Bzl',
      id: 89,
      resultObject: { name: 'G Bzl', prop: 'GBzl' },
    },
    {
      masterName: 'HaQc',
      id: 90,
      resultObject: { name: 'HaQc', prop: 'HaQcCode' },
    },
    {
      masterName: 'Heart and Arrow',
      id: 91,
      resultObject: { name: 'Heart and Arrow', prop: 'HeartandArrowCode' },
    },
    {
      masterName: 'Heavy',
      id: 92,
      resultObject: { name: 'Heavy', prop: '' },
    },
    {
      masterName: 'Height',
      id: 93,
      resultObject: { name: 'Height', prop: 'Height' },
    },
    {
      masterName: 'Image Url',
      id: 94,
      resultObject: { name: 'Image Url', prop: 'ImageUrl' },
    },
    {
      masterName: 'Inout Type',
      id: 95,
      resultObject: { name: 'Inout Type', prop: '' },
    },
    {
      masterName: 'Inscription',
      id: 96,
      resultObject: { name: 'Inscription', prop: 'Inscription' },
    },
    {
      masterName: 'inw cts',
      id: 97,
      resultObject: { name: 'inw cts', prop: 'inwcts' },
    },
    {
      masterName: 'Inw Rate',
      id: 98,
      resultObject: { name: 'Inw Rate', prop: 'InwRate' },
    },
    {
      masterName: 'Is Smarti',
      id: 99,
      resultObject: { name: 'Is Smarti', prop: 'IsSmarti' },
    },
    {
      masterName: 'Is Fancy Color',
      id: 100,
      resultObject: { name: 'Is Fancy Color', prop: 'IsFancyColor' },
    },
    {
      masterName: 'Is Average',
      id: 101,
      resultObject: { name: 'Is Average', prop: 'IsAverage' },
    },
    {
      masterName: 'Is Repair',
      id: 102,
      resultObject: { name: 'Is Repair', prop: 'IsRepair' },
    },
    {
      masterName: 'Invoice No',
      id: 103,
      resultObject: { name: 'Invoice No', prop: 'InvoiceNo' },
    },
    {
      masterName: 'Job No',
      id: 104,
      resultObject: { name: 'Job No', prop: 'JobNo' },
    },
    {
      masterName: 'Key to Symbols',
      id: 105,
      resultObject: { name: 'Key to Symbols', prop: 'KeytoSymbolsCode' },
    },
    {
      masterName: 'Lab',
      id: 106,
      resultObject: { name: 'Lab', prop: 'lab' },
    },
    {
      masterName: 'Lab Control No',
      id: 107,
      resultObject: { name: 'Lab Control No', prop: 'LabControlNo' },
    },
    {
      masterName: 'labPktSeriesNo',
      id: 108,
      resultObject: { name: 'Lab PktNo', prop: 'labPktSeriesNo' },
    },
    {
      masterName: 'Lab Remark',
      id: 109,
      resultObject: { name: 'Lab Remark', prop: 'LabRemark' },
    },
    {
      masterName: 'LbrwLgrY',
      id: 110,
      resultObject: { name: 'LbrwLgrY', prop: '' },
    },
    {
      masterName: 'Length',
      id: 111,
      resultObject: { name: 'Length', prop: 'Length' },
    },
    {
      masterName: 'Length mm',
      id: 112,
      resultObject: { name: 'Length mm', prop: '' },
    },
    {
      masterName: 'Lot Country',
      id: 113,
      resultObject: { name: 'Lot Country', prop: 'Lotcountry' },
    },
    {
      masterName: 'LotNo',
      id: 114,
      resultObject: { name: 'LotNo', prop: 'lotNo' },
    },
    {
      masterName: 'Lower Halves',
      id: 115,
      resultObject: { name: 'Lower Halves', prop: 'LrHalf' },
    },
    {
      masterName: 'Luster',
      id: 116,
      resultObject: { name: 'Luster', prop: 'LusterCode' },
    },
    {
      masterName: 'L Per',
      id: 117,
      resultObject: { name: 'L Per', prop: 'LPer' },
    },
    {
      masterName: 'L_W',
      id: 118,
      resultObject: { name: 'L_W', prop: 'L_W' },
    },
    {
      masterName: 'Lr Half',
      id: 119,
      resultObject: { name: 'Lr Half', prop: 'LrHalf' },
    },
    {
      masterName: 'M1',
      id: 120,
      resultObject: { name: 'M1', prop: '' },
    },
    {
      masterName: 'M2',
      id: 121,
      resultObject: { name: 'M2', prop: 'M2' },
    },
    {
      masterName: 'M3',
      id: 122,
      resultObject: { name: 'M3', prop: '' },
    },
    {
      masterName: 'Memo No',
      id: 123,
      resultObject: { name: 'Memo No', prop: '' },
    },
    {
      masterName: 'Milkyness',
      id: 124,
      resultObject: { name: 'Milkyness', prop: 'MilkynessCode' },
    },
    {
      masterName: 'N1',
      id: 125,
      resultObject: { name: 'N1', prop: '' },
    },
    {
      masterName: 'N1C',
      id: 126,
      resultObject: { name: 'N1C', prop: '' },
    },
    {
      masterName: 'N1S',
      id: 127,
      resultObject: { name: 'N1S', prop: '' },
    },
    {
      masterName: 'N2',
      id: 128,
      resultObject: { name: 'N2', prop: '' },
    },
    {
      masterName: 'N2C',
      id: 129,
      resultObject: { name: 'N2C', prop: '' },
    },
    {
      masterName: 'N2S',
      id: 130,
      resultObject: { name: 'N2S', prop: '' },
    },
    {
      masterName: 'N3',
      id: 131,
      resultObject: { name: 'N3', prop: '' },
    },
    {
      masterName: 'N3C',
      id: 132,
      resultObject: { name: 'N3C', prop: '' },
    },
    {
      masterName: 'N3S',
      id: 133,
      resultObject: { name: 'N3S', prop: '' },
    },
    {
      masterName: 'N Black Center',
      id: 134,
      resultObject: { name: 'N Black Center', prop: 'NBlackCenterCode' },
    },
    {
      masterName: 'N Black Side',
      id: 135,
      resultObject: { name: 'N Black Side', prop: 'NBlackSideCode' },
    },
    {
      masterName: 'N Black Inclusion',
      id: 136,
      resultObject: { name: 'N Black Inclusion', prop: 'NBlackInclusionCode' },
    },
    {
      masterName: 'Natts',
      id: 137,
      resultObject: { name: 'Natts', prop: '' },
    },
    {
      masterName: 'Natural',
      id: 138,
      resultObject: { name: 'Natural', prop: 'NaturalCode' },
    },
    {
      masterName: 'Net Pur Rate',
      id: 139,
      resultObject: { name: 'Net Pur Rate', prop: 'NetPurRate' },
    },
    {
      masterName: 'New Cut',
      id: 140,
      resultObject: { name: 'New Cut', prop: 'NewCutCode' },
    },
    {
      masterName: 'ORD',
      id: 141,
      resultObject: { name: 'ORD', prop: 'ORD' },
    },
    {
      masterName: 'Paint Comm',
      id: 142,
      resultObject: { name: 'Paint Comm', prop: '' },
    },
    {
      masterName: 'Painting',
      id: 143,
      resultObject: { name: 'Painting', prop: 'Painting' },
    },
    {
      masterName: 'Pav Angle',
      id: 144,
      resultObject: { name: 'Pav Angle', prop: 'PavAngle' },
    },
    {
      masterName: 'Pav Dp',
      id: 145,
      resultObject: { name: 'Pav Dp', prop: 'PavDp' },
    },
    {
      masterName: 'Pav Hgt',
      id: 146,
      resultObject: { name: 'Pav Hgt', prop: 'PavHgt' },
    },
    {
      masterName: 'Pavilion Extra Facet',
      id: 147,
      resultObject: {
        name: 'Pavilion Extra Facet',
        prop: 'PavilionExtraFacetCode',
      },
    },
    {
      masterName: 'Pavilion Open Inclusion',
      id: 148,
      resultObject: {
        name: 'Pavilion Open Inclusion',
        prop: 'PavilionOpenInclusionCode',
      },
    },
    {
      masterName: 'Pcs',
      id: 149,
      resultObject: { name: 'Pcs', prop: 'Pcs' },
    },
    {
      masterName: 'Plan Cts',
      id: 150,
      resultObject: { name: 'Plan Cts', prop: 'PlanCts' },
    },
    {
      masterName: 'Polish',
      id: 151,
      resultObject: { name: 'Polish', prop: 'PolishCode' },
    },
    {
      masterName: 'Polish Features',
      id: 152,
      resultObject: { name: 'Polish Features', prop: 'PolishFeatures' },
    },
    {
      masterName: 'Prog Name',
      id: 153,
      resultObject: { name: 'Prog Name', prop: 'progName' },
    },
    {
      masterName: 'Proportion',
      id: 154,
      resultObject: { name: 'Proportion', prop: 'Proportion' },
    },
    {
      masterName: 'Rate Per Cts',
      id: 155,
      resultObject: { name: 'Rate Per Cts', prop: '' },
    },
    {
      masterName: 'Remarks',
      id: 156,
      resultObject: { name: 'Remarks', prop: '' },
    },
    {
      masterName: 'Remark1',
      id: 157,
      resultObject: { name: 'Remark1', prop: 'Remark1' },
    },
    {
      masterName: 'Remark2',
      id: 158,
      resultObject: { name: 'Remark2', prop: 'Remark2' },
    },
    {
      masterName: 'Remark3',
      id: 159,
      resultObject: { name: 'Remark3', prop: 'Remark3' },
    },
    {
      masterName: 'Remark4',
      id: 160,
      resultObject: { name: 'Remark4', prop: 'Remark4' },
    },
    {
      masterName: 'Report Comments',
      id: 161,
      resultObject: { name: 'Report Comments', prop: 'ReportComments' },
    },
    {
      masterName: 'Report Dt',
      id: 162,
      resultObject: { name: 'Report Dt', prop: 'ReportDt' },
    },
    {
      masterName: 'Report No',
      id: 163,
      resultObject: { name: 'Report No', prop: 'ReportNo' },
    },
    {
      masterName: 'Report Type',
      id: 164,
      resultObject: { name: 'Report Type', prop: 'ReportType' },
    },
    {
      masterName: 'Report Action',
      id: 165,
      resultObject: { name: 'Report Action', prop: '' },
    },
    {
      masterName: 'Service Comment',
      id: 166,
      resultObject: { name: 'Service Comment', prop: '' },
    },
    {
      masterName: 'Inscription Text',
      id: 167,
      resultObject: { name: 'Inscription Text', prop: 'Inscription' },
    },
    {
      masterName: 'rfid',
      id: 168,
      resultObject: { name: 'rfid', prop: 'rfid' },
    },
    {
      masterName: 'Rough',
      id: 169,
      resultObject: { name: 'Rough', prop: 'rough' },
    },
    {
      masterName: 'Rough Category',
      id: 170,
      resultObject: { name: 'Rough Category', prop: 'RoughCategory' },
    },
    {
      masterName: 'Sale Person',
      id: 171,
      resultObject: { name: 'Sale Person', prop: 'SalePerson' },
    },
    {
      masterName: 'Service',
      id: 172,
      resultObject: { name: 'Service', prop: 'Service' },
    },
    {
      masterName: 'Shape',
      id: 173,
      resultObject: { name: 'Shape', prop: 'ShapeCode' },
    },
    {
      masterName: 'Shape Description',
      id: 174,
      resultObject: { name: 'Shape Description', prop: 'ShapeDescription' },
    },
    {
      masterName: 'Shades',
      id: 175,
      resultObject: { name: 'Shades', prop: 'ShadesCode' },
    },
    {
      masterName: 'Side',
      id: 176,
      resultObject: { name: 'Side', prop: '' },
    },
    {
      masterName: 'Side Black Inclusion',
      id: 177,
      resultObject: {
        name: 'Side Black Inclusion',
        prop: 'SideBlackInclusionCode',
      },
    },
    {
      masterName: 'Side Ducha',
      id: 178,
      resultObject: { name: 'Side Ducha', prop: '' },
    },
    {
      masterName: 'Side Feat/Glades',
      id: 179,
      resultObject: { name: 'Side Feat/Glades', prop: 'SideFeat_GladesCode' },
    },
    {
      masterName: 'Side Spot Inclusion',
      id: 180,
      resultObject: {
        name: 'Side Spot Inclusion',
        prop: 'SideSpotInclusionCode',
      },
    },
    {
      masterName: 'Side White Inclusion',
      id: 181,
      resultObject: {
        name: 'Side White Inclusion',
        prop: 'SideWhiteInclusionCode',
      },
    },
    {
      masterName: 'Size',
      id: 182,
      resultObject: { name: 'Size', prop: 'Size' },
    },
    {
      masterName: 'Sorting',
      id: 183,
      resultObject: { name: 'Sorting', prop: '' },
    },
    {
      masterName: 'Star Length',
      id: 184,
      resultObject: { name: 'Star Length', prop: 'StarLength' },
    },
    {
      masterName: 'Stone Id',
      id: 185,
      resultObject: {
        name: 'Stone Name',
        prop: 'stoneName',
        summaryType: 'stoneId',
      },
    },
    {
      masterName: 'Str Ln',
      id: 186,
      resultObject: { name: 'Str Ln', prop: '' },
    },
    {
      masterName: 'Symmetry',
      id: 187,
      resultObject: { name: 'Symmetry', prop: 'SymmetryCode' },
    },
    {
      masterName: 'Symmetry Features',
      id: 188,
      resultObject: { name: 'Symmetry Features', prop: 'SymmetryFeatures' },
    },
    {
      masterName: 'Synthetic Indicator',
      id: 189,
      resultObject: { name: 'Synthetic Indicator', prop: 'SyntheticIndicator' },
    },
    {
      masterName: 'Table Black Inclusion',
      id: 190,
      resultObject: {
        name: 'Table Black Inclusion',
        prop: 'TableBlackInclusionCode',
      },
    },
    {
      masterName: 'Table Extra Facet',
      id: 191,
      resultObject: { name: 'Table Extra Facet', prop: 'TableExtraFacetCode' },
    },
    {
      masterName: 'Table Open Inclusion',
      id: 192,
      resultObject: {
        name: 'Table Open Inclusion',
        prop: 'TableOpenInclusionCode',
      },
    },
    {
      masterName: 'Table Per',
      id: 193,
      resultObject: { name: 'Table Per', prop: 'TablePer' },
    },
    {
      masterName: 'Table Spot Inclusion',
      id: 194,
      resultObject: {
        name: 'Table Spot Inclusion',
        prop: 'TableSpotInclusionCode',
      },
    },
    {
      masterName: 'Table White Inclusion',
      id: 195,
      resultObject: {
        name: 'Table White Inclusion',
        prop: 'TableWhiteInclusionCode',
      },
    },
    {
      masterName: 'Table Mm',
      id: 196,
      resultObject: { name: 'Table Mm', prop: 'TableMm' },
    },
    {
      masterName: 'Tot Depth',
      id: 197,
      resultObject: { name: 'Tot Depth', prop: 'TotDepth' },
    },
    {
      masterName: 'Tot Depth Mm',
      id: 198,
      resultObject: { name: 'Depth', prop: 'TotDepthMm' },
    },
    {
      masterName: 'Trans mode',
      id: 199,
      resultObject: { name: 'Trans mode', prop: 'Transmode' },
    },
    {
      masterName: 'Trans Type',
      id: 200,
      resultObject: { name: 'Trans Type', prop: 'transType' },
    },
    {
      masterName: 'Transfer Flg',
      id: 201,
      resultObject: { name: 'Transfer Flg', prop: '' },
    },
    {
      masterName: 'Trans Remark',
      id: 202,
      resultObject: { name: 'Trans Remark', prop: 'stoneRemarks' },
    },
    {
      masterName: 'Mode of Transport',
      id: 203,
      resultObject: { name: 'Mode of Transport', prop: 'ModeofTransport' },
    },
    {
      masterName: 'Vibox',
      id: 204,
      resultObject: { name: 'Vibox', prop: 'Vibox' },
    },
    {
      masterName: 'V360',
      id: 205,
      resultObject: { name: 'V360', prop: 'V360' },
    },
    {
      masterName: 'Weight',
      id: 206,
      resultObject: { name: 'Weight', prop: '' },
    },
    {
      masterName: 'Total Wgt diff',
      id: 207,
      resultObject: { name: 'Total Wgt diff', prop: 'TotalWgtdiff' },
    },
    {
      masterName: 'Width',
      id: 208,
      resultObject: { name: 'Width', prop: 'Width' },
    },
    {
      masterName: 'YearMonth',
      id: 209,
      resultObject: { name: 'YearMonth', prop: 'yearMonth' },
    },
    {
      masterName: 'GIAExpense',
      id: 210,
      resultObject: { name: 'GIAExpense', prop: '' },
    },
    {
      masterName: 'LabExpense',
      id: 211,
      resultObject: { name: 'LabExpense', prop: 'LabExpense' },
    },
    {
      masterName: 'FMExpense',
      id: 212,
      resultObject: { name: 'FMExpense', prop: '' },
    },
    {
      masterName: 'AssortmentPrice',
      id: 213,
      resultObject: { name: 'AssortmentPrice', prop: '' },
    },
    {
      masterName: 'TotalLabExpense',
      id: 214,
      resultObject: { name: 'TotalLabExpense', prop: 'TotalLabExpense' },
    },
    {
      masterName: 'Ref StoneId',
      id: 215,
      resultObject: { name: 'Ref StoneId', prop: 'RefStoneId' },
    },
    {
      masterName: 'Bulk Color',
      id: 216,
      resultObject: { name: 'Bulk Color', prop: '' },
    },
    {
      masterName: 'Bulk Purity',
      id: 217,
      resultObject: { name: 'Bulk Purity', prop: '' },
    },
    {
      masterName: 'Total Loss',
      id: 218,
      resultObject: { name: ' Total Loss', prop: 'TotalLoss' },
    },
    {
      masterName: 'Gain',
      id: 219,
      resultObject: { name: 'Gain', prop: 'TotalGain' },
    },
    {
      masterName: 'gia_remark',
      id: 220,
      resultObject: { name: 'gia_remark', prop: '' },
    },
    {
      masterName: 'skip_assortment',
      id: 221,
      resultObject: { name: 'skip_assortment', prop: '' },
    },
    {
      masterName: 'requestFor',
      id: 222,
      resultObject: { name: 'requestFor', prop: '' },
    },
    // {
    //     "masterName": "labPktSeriesNo",
    //     "id": 223,
    //     "resultObject": { name: "labPktSeriesNo", prop: "" }
    // },
    {
      masterName: 'segomaId',
      id: 224,
      resultObject: { name: 'segomaId', prop: 'segomaId' },
    },
    {
      masterName: 'User Name',
      id: 225,
      resultObject: { name: 'User Name', prop: 'username' },
    },
    {
      masterName: 'Fct Planre Marks',
      id: 226,
      resultObject: { name: 'Fct Planre Marks', prop: 'Fctplanremarks' },
    },
    {
      masterName: 'Fact Sub Seqno',
      id: 227,
      resultObject: { name: 'Fact Sub Seqno', prop: 'factSubSeqNo' },
    },
    {
      masterName: 'AgingDays',
      id: 228,
      resultObject: { name: 'AgingDays', prop: 'AgingDays' },
    },
    {
      masterName: 'Available For Sale',
      id: 229,
      resultObject: { name: 'Available For Sale', prop: 'availableForSale' },
    },
    {
      masterName: 'AgingMonths',
      id: 230,
      resultObject: { name: 'Aging Months', prop: 'AgingMonths' },
    },
    {
      masterName: 'Location',
      id: 231,
      resultObject: { name: 'Location', prop: 'location' },
    },
    {
      masterName: 'Prog Name',
      id: 232,
      resultObject: { name: 'Prog Name', prop: 'progName' },
    },
    {
      masterName: 'Ready For Sales',
      id: 233,
      resultObject: { name: 'Ready For Sales', prop: 'readyForSales' },
    },
    {
      masterName: 'Loss',
      id: 234,
      resultObject: { name: 'Loss', prop: 'LossCode' },
    },
    {
      masterName: 'GBzl',
      id: 235,
      resultObject: { name: 'GBzl', prop: 'GBzl' },
    },
    {
      masterName: 'Assortment',
      id: 236,
      resultObject: { name: 'Assortment', prop: 'assortment' },
    },
    {
      masterName: 'Box',
      id: 237,
      resultObject: { name: 'Box', prop: 'Box' },
    },
    {
      masterName: 'Internal Status',
      id: 238,
      resultObject: { name: 'Internal Status', prop: 'internalStatus' },
    },
    {
      masterName: 'External Status',
      id: 239,
      resultObject: { name: 'External Status', prop: 'externalStatus' },
    },
    {
      masterName: 'Department',
      id: 240,
      resultObject: { name: 'Department', prop: 'department' },
    },
    {
      masterName: 'partyName',
      id: 241,
      resultObject: { name: 'Party Name', prop: 'partyName' },
    },
    {
      masterName: 'Is Certi',
      id: 242,
      resultObject: { name: 'Is Certi', prop: '' },
    },
    {
      masterName: 'Flip Stone',
      id: 243,
      resultObject: { name: 'Flip Stone', prop: 'FlipStone' },
    },
    {
      masterName: 'Loss',
      id: 244,
      resultObject: { name: 'Loss', prop: 'LossCode' },
    },
    {
      masterName: 'Wgt diff',
      id: 245,
      resultObject: { name: 'Wgt diff', prop: 'Wgtdiff' },
    },
    {
      masterName: 'inwardDate',
      id: 98,
      resultObject: { name: 'Inward Date', prop: 'inwardDate' },
    },
    {
      masterName: 'requestPrice',
      id: 246,
      resultObject: { name: 'Memo Price', prop: 'requestPrice' },
    },
    {
      masterName: 'Segoma Id',
      id: 247,
      resultObject: { name: 'Segoma Id', prop: 'SegomaId' },
    },
    {
      masterName: 'Pd Cat',
      id: 248,
      resultObject: { name: 'Pd Cat', prop: 'PdCatCode' },
    },
    {
      masterName: 'PPrice Disc',
      id: 249,
      resultObject: { name: 'PPrice Disc', prop: 'PPriceDisc' },
    },
    {
      masterName: 'PPrice per cts',
      id: 250,
      resultObject: { name: 'PPrice per cts', prop: 'PPricepercts' },
    },
    {
      masterName: 'PPrice Amt',
      id: 251,
      resultObject: { name: 'PPrice Amt', prop: 'PPriceAmt' },
    },
    {
      masterName: 'requestDiscount',
      id: 252,
      resultObject: { name: 'Memo Discount', prop: 'requestDiscount' },
    },
    {
      masterName: 'requestAmount',
      id: 253,
      resultObject: { name: 'Memo Amount', prop: 'requestPrice' },
    },
    {
      masterName: 'isFixed',
      id: 254,
      resultObject: { name: 'Memo Price', prop: 'isFixed' },
    },
    {
      masterName: 'Mumbai Mfg Amt',
      id: 255,
      resultObject: { name: 'Mumbai Mfg Amt', prop: 'MumbaiMfgAmt' },
    },
    {
      masterName: 'Mumbai Mfg Amt2',
      id: 256,
      resultObject: { name: 'Mumbai Mfg Amt 2', prop: 'MumbaiMfgAmt2' },
    },
    {
      masterName: 'requestUser',
      id: 257,
      resultObject: { name: 'Requested User', prop: 'requestUser' },
    },
    {
      masterName: 'sales_person',
      id: 258,
      resultObject: { name: 'Sales Person', prop: 'sales_person' },
    },
    {
      masterName: 'sales_party',
      id: 259,
      resultObject: { name: 'Sales Party', prop: 'sales_party' },
    },
    {
      masterName: 'Girdle Thin',
      id: 260,
      resultObject: { name: 'Girdle Thin', prop: 'GirdleThinCode' },
    },
    {
      masterName: 'Girdle Thick',
      id: 261,
      resultObject: { name: 'Girdle Thick', prop: 'GirdleThickCode' },
    },
    {
      masterName: 'Request Party Disc',
      id: 262,
      resultObject: { name: 'Request Party Disc', prop: 'RequestPartyDisc' },
    },
    {
      masterName: 'Request Party Price',
      id: 263,
      resultObject: { name: 'Request Party Price', prop: 'RequestPartyPrice' },
    },
    {
      masterName: 'Request Party Amt',
      id: 264,
      resultObject: { name: 'Request Party Amt', prop: 'RequestPartyAmt' },
    },
    {
      masterName: 'Purchase Party Disc',
      id: 265,
      resultObject: { name: 'Purchase Party Disc', prop: 'PurchasePartyDisc' },
    },
    {
      masterName: 'Purchase Party Price',
      id: 266,
      resultObject: {
        name: 'Purchase Party Price',
        prop: 'PurchasePartyPrice',
      },
    },
    {
      masterName: 'Purchase Party Amt',
      id: 267,
      resultObject: { name: 'Purchase Party Amt', prop: 'PurchasePartyAmt' },
    },
    {
      masterName: 'other Office Price Discount',
      id: 268,
      resultObject: {
        name: 'Other office Party Disc',
        prop: 'otherOfficePriceDiscount',
      },
    },
    {
      masterName: 'other Office Price percarat',
      id: 269,
      resultObject: {
        name: 'Other office Party Price',
        prop: 'otherOfficePricepercarat',
      },
    },
    {
      masterName: 'other Office Price Amt',
      id: 270,
      resultObject: {
        name: 'Other office Party Amt',
        prop: 'otherOfficePriceAmt',
      },
    },
    {
      masterName: 'CertiAgingDays',
      id: 270,
      resultObject: { name: 'Certi Aging Days', prop: 'CertiAgingDays' },
    },
    {
      masterName: 'kgStoneId',
      id: 270,
      resultObject: { name: 'Kg Stone Id', prop: 'kgStoneId' },
    },
    {
      masterName: 'stoneRemarks',
      id: 271,
      resultObject: { name: 'Stone Remarks', prop: 'stoneRemarks' },
    },
    {
      masterName: 'labShape',
      id: 272,
      resultObject: { name: 'Lab Shape', prop: 'labShapeCode' },
    },
    {
      masterName: 'Shenil Make',
      id: 273,
      resultObject: { name: 'Shenil Make', prop: 'ShenilMakeCode' },
    },
    {
      masterName: 'Shenil QC',
      id: 274,
      resultObject: { name: 'Shenil QC', prop: 'ShenilQCCode' },
    },
    {
      masterName: 'Sales Person Suggestions',
      id: 275,
      resultObject: {
        name: 'Sales Person Suggestions',
        prop: 'SalesPersonSuggestions',
      },
    },
    {
      masterName: 'Disclosed Source',
      id: 276,
      resultObject: { name: 'Disclosed Source', prop: 'DisclosedSource' },
    },
    {
      masterName: 'Digital Certi',
      id: 277,
      resultObject: { name: 'Digital Certi', prop: 'DigitalCerti' },
    },
    {
      masterName: 'transDate',
      id: 278,
      resultObject: { name: 'Trans Date', prop: 'transDate' },
    },
    {
      masterName: 'tracrId',
      id: 279,
      resultObject: { name: 'tracrId', prop: 'tracrId' },
    },
    {
      masterName: 'tracerEligible',
      id: 280,
      resultObject: { name: 'Tracer Eligible', prop: 'tracerEligible' },
    },
    {
      masterName: 'trackingNo',
      id: 281,
      resultObject: { name: 'Invoice No/Consignment No', prop: 'trackingNo' },
    },
    {
      masterName: 'gemexid',
      id: 282,
      resultObject: { name: 'gemexId', prop: 'gemexid' },
    },
    {
      masterName: 'tradeShow',
      id: 283,
      resultObject: { name: 'tradeShow', prop: 'tradeShow' },
    },
    {
      masterName: 'PPrice Date',
      id: 284,
      resultObject: { name: 'PPrice Date', prop: 'PPriceDate' },
    },
    {
      masterName: 'Gauge_Length',
      id: 285,
      resultObject: { name: 'Gauge_Length', prop: 'Gauge_Length' },
    },
    {
      masterName: 'Gauge_Width',
      id: 286,
      resultObject: { name: 'Gauge_Width', prop: 'Gauge_Width' },
    },
    {
      masterName: 'Gauge_Depth',
      id: 287,
      resultObject: { name: 'Gauge_Depth', prop: 'Gauge_Depth' },
    },
    {
      masterName: 'FM_REF',
      id: 288,
      resultObject: { name: 'FM_REF Date', prop: 'FM_REF' },
    },
    {
      masterName: 'Is Fixed',
      id: 290,
      resultObject: { name: 'Is Fixed', prop: 'IsFixed' },
    },
    {
      masterName: 'MineGroup',
      id: 291,
      resultObject: { name: 'Mine Group', prop: 'MineGroup' },
    },
    {
      masterName: 'Featured Stone',
      id: 292,
      resultObject: { name: 'Featured Stone', prop: 'featuredStone' },
    },
    {
      masterName: 'MineCompany',
      id: 400,
      resultObject: { name: 'Mine Company', prop: 'MineCompany' },
    },
    {
      masterName: 'CM_STR',
      id: 293,
      resultObject: { name: 'CM_STR', prop: 'CM_STR' },
    },
    {
      masterName: 'accInvoiceDate',
      id: 294,
      resultObject: { name: 'accInvoiceDate', prop: 'accInvoiceDate' },
    },
    {
      masterName: 'Projected Value',
      id: 295,
      resultObject: { name: 'Projected Value', prop: 'projectedValue' },
    },
    {
      masterName: 'Cutwise_id',
      id: 296,
      resultObject: { name: 'Cutwise Id', prop: 'Cutwise_id' },
    },
    {
      masterName: 'standardShape',
      id: 295,
      resultObject: { name: 'Standard Shape', prop: 'standardShapeCode' },
    },
    {
      masterName: 'eliteShape',
      id: 296,
      resultObject: { name: 'Elite Shape', prop: 'eliteShapeCode' },
    },
    {
      masterName: 'TweezerVideo',
      id: 297,
      resultObject: { name: 'Tweezer Video', prop: 'TweezerVideo' },
    },
    {
      masterName: 'HandVideo',
      id: 298,
      resultObject: { name: 'Hand Video', prop: 'HandVideo' },
    },
    {
      masterName: 'KPCertiOrigin',
      id: 299,
      resultObject: { name: 'KP Certi Origin', prop: 'KPCertiOrigin' },
    },
    {
      masterName: 'ManufacturingLocation',
      id: 300,
      resultObject: {
        name: 'ManufacturingLocation',
        prop: 'ManufacturingLocation',
      },
    },
    {
      masterName: 'NCode',
      id: 301,
      resultObject: { name: 'NCode', prop: 'NCode' },
    },
    {
      masterName: 'RapnetModifiedDate',
      id: 302,
      resultObject: {
        name: 'Rapnet Modified Date',
        prop: 'RapnetModifiedDate',
      },
    },
    {
      masterName: 'rapnetAging',
      id: 303,
      resultObject: { name: 'Rapnet Aging Days ', prop: 'rapnetAging' },
    },
    {
      masterName: 'Country Of Origin',
      id: 304,
      resultObject: { name: 'Country Of Origin', prop: 'CountryOfOriginCode' },
    },
    {
      masterName: 'KP_NO',
      id: 304,
      resultObject: { name: 'KP_NO', prop: 'KPNO' },
    },
    {
      masterName: 'Gemexid',
      id: 305,
      resultObject: { name: 'Gemexid', prop: 'Gemexid' },
    },
    {
      masterName: 'Diamond Type',
      id: 306,
      resultObject: { name: 'Diamond Type', prop: 'DiamondType' },
    },
    {
      masterName: 'Code of origin',
      id: 307,
      resultObject: { name: 'Code of origin', prop: 'Codeoforigin' },
    },
    {
      masterName: 'Member comment',
      id: 308,
      resultObject: {
        name: 'Member comment',
        prop: 'Membercomment',
      },
    },
    {
      masterName: 'KP Certi Origin',
      id: 309,
      resultObject: {
        name: 'KP Certi Origin',
        prop: 'KPCertiOrigin',
      },
    },
    {
      masterName: 'Brand',
      id: 310,
      resultObject: {
        name: 'Brand',
        prop: 'Brand',
      },
    },
    {
      masterName: 'DOR rough no',
      id: 311,
      resultObject: {
        name: 'DOR rough no',
        prop: 'DORRoughNo',
      },
    }
  ],
};
