import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Events, IonToggle } from "@ionic/angular";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import { UploadService } from "src/app/services/upload_service";

@Component({
  selector: "app-website-config",
  templateUrl: "./website-config.component.html",
  styleUrls: ["./website-config.component.scss"],
})
export class WebsiteConfigComponent implements OnInit {
  // @ViewChild("toggle") toggle: IonToggle | undefined;

  public bannerForm = {
    enableBanner: false,
    title: "",
    queryFilter: "",
    showPopup: false,
    scrollImage: "",
    popupImage: "",
    appScrollImage: "",
    appPopupImage: "",
  };
  public fileName = "";
  public segmentChange = "banner";
  public underMaintenance = false;
  public uploadedImg: File;
  public companyConfig: any;
  constructor(
    private uploadService: UploadService,
    public configService: ConfigService,
    public event: Events,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private s3ImageService: UploadService
  ) {}

  async getCompanyInfo() {
    let res = await this.httpApiService.getCompanyInfo();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", res.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", res.error);
    } else {
      let companyData = JSON.parse(res._body).data;
      this.companyConfig = JSON.parse(companyData.config);
      this.underMaintenance = this.companyConfig.underMaintenance;
      this.bannerForm = this.companyConfig.showDetails;
    }
  }

  ngOnInit() {
    this.getCompanyInfo();
    // api call to update bannerForm
  }

  onToggle(ev: CustomEvent) {
    const isChecked = ev.detail.checked;
    if (!isChecked) {
      this.bannerForm = {
        enableBanner: false,
        title: "",
        queryFilter: "",
        showPopup: false,
        popupImage: "",
        scrollImage: "",
        appScrollImage: "",
        appPopupImage: "",
      };
      //this.fileName = "";
      this.uploadedImg = null;
    }
  }

  async onFileSelected(ev, uploadFor, deviceType) {
    let img = ev.target.files[0];
    if (uploadFor == "") {
    }

    //this.fileName = this.img.name;
    await this.uploadService.uploadFile(img, "banner").then((file: string) => {
      if (uploadFor == "banner" && deviceType == "desktop") {
        this.bannerForm.scrollImage = file;
      } else if (uploadFor == "popup" && deviceType == "desktop") {
        this.bannerForm.popupImage = file;
      } else if (uploadFor == "banner" && deviceType == "mobile") {
        this.bannerForm.appScrollImage = file;
      } else if (uploadFor == "popup" && deviceType == "mobile") {
        this.bannerForm.appPopupImage = file;
      } else {
        console.log("--->>");
      }
    });

    console.log(this.bannerForm);
  }

  disableSubmit() {
    if (this.segmentChange === "underMaintenance") {
      return false;
    } else if (!this.bannerForm.enableBanner) {
      return false;
    } else if (
      this.bannerForm.enableBanner &&
      this.bannerForm.title &&
      this.bannerForm.queryFilter
    ) {
      return false;
    } else {
      return true;
    }
  }

  async submit() {
    console.log(this.segmentChange);
    let configJson;
    let postURL;
    if (this.segmentChange == "banner") {
      postURL = "updateConfig";
      configJson = {
        id: 37,
        configJsonFields: JSON.stringify({
          showDetails: this.bannerForm,
        }),
      };
    } else if (this.segmentChange == "underMaintenance") {
      postURL = "systemMaintenanceUpdate";
      configJson = {
        id: 37,
        underMaintenance: this.underMaintenance,
      };
    } else {
      console.log("-->>>");
    }

    console.log(configJson);
    console.log(postURL);
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.updateCompanySettings(configJson, postURL);
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast(
        "success",
        "Data Updated Successfully"
      );
    }
  }
}
