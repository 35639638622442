import { Component, OnInit, Input, HostListener } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ManageVoucherBookPage } from "../manage-voucher-book/manage-voucher-book.page";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { StickerPrintingOptionsPage } from '../../actions/sticker-printing-options/sticker-printing-options.page';
import { GIASticker, pktNoSticker, nonCertSticker, incExpoSticker, stickerWithItemCodeForNy } from './stick-printing-format'
import { SearchService } from "src/app/services/search/search.service";
//import writeToFile from 'write';
import { environment } from '../../../../environments/environment';
import { StickerPrintNewPage } from "../sticker-print-new/sticker-print-new.page";

@Component({
  selector: 'app-sticker-printing',
  templateUrl: './sticker-printing.component.html',
  styleUrls: ['./sticker-printing.component.scss']
})
export class StickerPrintingComponent implements ProcessActionComponent {
  @Input() data: any;
  public policies = [];
  public count: Number = 0;
  public allPriceNameList = []
  public pageNumber = 0;
  public noOfRecords = 500;
  public nameId: any;
  public totalPages: Number = 0;
  public policyId: any;
  public page = 'StickerPrinting';
  public priceDate: any;
  public policyIdList = [];
  public nameIdList = [];
  public toPriceListNameMasterId: any;
  public toPriceNameList = [];
  public historyData: any;
  public historyDataDataSource: any = {};
  public pageSize = 500;
  public stonesList = [];
  public selected: any = [];
  public refreshGrid: boolean = false;
  public inputList: any;
  public type: any = 'stoneId';
  public stickerType: any = 'GIASticker'
  public columns: any = [];
  public copyValueList = null;
  public stoneWithItemCode = [];

  constructor(
    private es: SearchService,
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events


  ) {

    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, {
      name: 'Stone Id', prop: 'stoneName'
    },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'Lab', prop: 'lab' }

    ]

  }

  @HostListener('window:keyup', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
      let x = document.activeElement.tagName;
      if (x != 'ION-TEXTAREA') {
        if (navigator['clipboard']) {
          navigator['clipboard'].readText().then(data => {
            if (this.stickerType == 'stickerWithItemCodeForNy') {
              this.copyValueList = data;
              this.getArray();
            }

          })
        }
      }
    }
  }

  getArray() {
    this.inputList = ''
    let records = this.copyValueList.split('\n').filter(el => !!el);
    this.stoneWithItemCode = []
    for (let i = 0; i < records.length; i++) {
      if (records[i]) {
        let rowArray = records[i].trim().split('\t')
        this.stoneWithItemCode.push({ stoneId: rowArray[0], itemCode: rowArray[1] })
        this.inputList = !this.inputList ? rowArray[0] : this.inputList + '\n' + rowArray[0];
      }
    }
    this.fetch()
  }

  ngOnInit() {
  }

  changeType(type) {
    console.log(type)
    this.type = type;
  }

  changeStickerType(type) {
    this.stickerType = type;
  }

  async addStones() {
    // this.refreshGrid = true;
    // this.stonesList = [];
    // this.selected = [];
    // const modal = await this.modalCtrl.create({
    //   component: StickerPrintingOptionsPage,
    //   backdropDismiss: false,
    //   cssClass: "extraLargePage",
    //   componentProps: {}
    // });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    // return await modal.present();
  }

  async fetch() {
    console.log("DD")
    console.log(this.type)
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }
    let tmp = this.inputList.split("\n");
    console.log(tmp)
    if (this.type == 'stoneId') {

      let boolShouldMultiple = {
        "bool": {
          "should": []
        }
      };
      let location = {
        "terms": { location: ["mumbai"] }
      }
      qry.query.bool.should[1].bool.must.push(location);

      let sData = {
        terms: {
          "stoneName": tmp.filter(el => !!el).map(el => el.trim())
        }
      }
      // for (let i = 0; i < tmp.length; i++) {
      //   if (tmp[i] != '') {
      //     let prefixObj: any = {};
      //     prefixObj =
      //     {
      //       "terms": {
      //         "stoneName": tmp[i].trim()
      //       }
      //     }

      //   }
      // }
      boolShouldMultiple.bool.should.push(sData);
      qry.query.bool.should[1].bool.must.push(boolShouldMultiple);
    }
    if (this.type == 'barcode') {
      tmp = tmp.map(el => {
        console.log(el)
        if (!!el) {
          el = el.trim()
          return el
        }
      }).filter(el => !!el)
      let barcodeData = {
        terms: {
          "Barcode": tmp
        }
      }
      qry.query.bool.should[1].bool.must.push(barcodeData);
    }
    if (this.type == 'rfid') {
      tmp = tmp.map(el => {
        console.log(el)
        if (!!el) {
          el = el.trim()
          return el
        }
      }).filter(el => !!el)
      let rfidData = {
        terms: {
          "rfid": tmp
        }
      }
      qry.query.bool.should[1].bool.must.push(rfidData);
    }
    if (this.type == 'reportNo') {
      tmp = tmp.map(el => {
        console.log(el)
        if (!!el) {
          el = el.trim()
          return el
        }
      }).filter(el => !!el)
      let reportNos = {
        terms: {
          "ReportNo": tmp
        }
      }
      qry.query.bool.should[1].bool.must.push(reportNos);
    }


    let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX, '', 1000)
    data = data.hits.hits.map(d => d._source).map(res => {
      let stoneId = res.stoneName;
      if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
        let index = stoneId.split('/', 3).join('/').length;
        res['stoneName'] = String(stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))));
      } else {
        res['stoneName'] = String(res['stoneName']);
      }
      return res;
    })
    let d = await this.sortResult(data, tmp)
    if (this.stickerType == 'coupleSticker') {
      //  || this.stickerType == 'GIASticker'
      d = d.filter((item, index, self) =>
        index === self.findIndex((t) => t.ReportNo === item.ReportNo)
      );
    }

    this.stonesList = d;
    // let dObj ={
    //   'stoneData':data,
    //   'stickerType':this.stickerType
    // }
    // this.modalCtrl.dismiss(dObj);
  }


  sortResult(resultArr, tmp) {
    if (this.type == 'stoneId') {
      return [...resultArr].sort(
        (a, b) => tmp.indexOf(a.stoneName) - tmp.indexOf(b.stoneName)
      )
    } else if (this.type == 'reportNo') {
      return [...resultArr].sort(
        (a, b) => tmp.indexOf(a.ReportNo) - tmp.indexOf(b.ReportNo)
      )
    } else if (this.type == 'barcode') {
      return [...resultArr].sort(
        (a, b) => tmp.indexOf(a.Barcode) - tmp.indexOf(b.Barcode)
      )
    } else if (this.type == 'rfid') {
      return [...resultArr].sort(
        (a, b) => tmp.indexOf(a.rfid) - tmp.indexOf(b.rfid)
      )
    }
  }

  async handleModalDismiss(d) {
    if (d.data) {
      if (d.data.stoneData) {
        this.stonesList = d.data.stoneData;
      }

      if (d.data.stickerType) {
        this.stickerType = d.data.stickerType;
      }

      console.log(d.data)
    }
    //this.refreshGrid = true
  }

  async OnSelectRows(event) {
    this.selected = event.selectedRowsData
  }


  async generateFileFormat() {
    console.log(this.stoneWithItemCode)
    let content = "";
    for (let i = 0; i < this.selected.length; i++) {
      let stoneData = this.selected[i];
      console.log(stoneData)
      let data = await this.replaceFileData(stoneData)//this.replaceAll(fileI, '$stoneId', 'KEVAL');
      content = content + '\n' + data;
    }

    var a = document.createElement('a');
    let file = new Blob([content], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download = this.stickerType + ' File' + '.txt';
    a.click();
  }

  async replaceAll(str, find, replace) {
    while (str.indexOf(find) > -1) {
      str = str.replace(find, replace);
    }
    return str;
  }


  async replaceFileData(stoneData) {
    console.log(stoneData, this.stoneWithItemCode)
    let stoneId = stoneData['stoneName'] || ''
    let CertDate = !!stoneData['CertDate'] ? moment(stoneData['CertDate']).format("MMM DD, YYYY") : ''
    let ReportNoVal = stoneData['ReportNo'] || '';
    let Inscription = stoneData['Inscription'] || '';
    let cts = stoneData['cts'] || '';
    let color = stoneData['ColorCode'] || '';
    let clarity = stoneData['ClarityCode'] || '';
    let cut = !!stoneData['ShapeCode'].includes('ROUND') ? (!!stoneData['CutCode'] ? stoneData['CutCode'] : '') : '';
    let table = stoneData['TablePer'] || '';
    let tabelD = stoneData['TotDepth'] || '';
    let culet = stoneData['CuletSizeCode'] || '';
    let polish = stoneData['PolishCode'] || '';
    let girdle = stoneData['GirdleCode'] || '';
    let symmetry = stoneData['SymmetryCode'] || '';
    let fluorescence = stoneData['FluorescenceCode'] || '';
    let itemCode = (!!this.stoneWithItemCode && this.stoneWithItemCode.length > 0) ? this.stoneWithItemCode.filter(el => el.stoneId == stoneData['stoneName'])[0].itemCode : '';
    let key = stoneData['KeytoSymbolsCode'] || '';
    let labPktSeriesNo = stoneData['labPktSeriesNo'] || '';
    let lab = stoneData['lab'] || '';
    let barcodeNo = stoneData['Barcode'] || '';
    let shape = stoneData['ShapeCode'] || '';
    let size = stoneData['cts'] || '';
    let Length = stoneData['Length'] || '';
    let Width = stoneData['Width'] || '';
    let TotDepthMm = stoneData['TotDepthMm'] || '';
    let d = "";
    if (this.stickerType == 'GIASticker') {
      d = await this.replaceAll(GIASticker, '$stoneId', stoneId.toString());
    }
    if (this.stickerType == 'pktNoSticker') {
      d = await this.replaceAll(pktNoSticker, '$stoneId', stoneId.toString());
    }
    if (this.stickerType == 'nonCertSticker') {
      d = await this.replaceAll(nonCertSticker, '$stoneId', stoneId.toString());
    }
    if (this.stickerType == 'incExpoSticker') {
      d = await this.replaceAll(incExpoSticker, '$stoneId', stoneId.toString());
    }
    if (this.stickerType == 'stickerWithItemCodeForNy') {
      shape = !!stoneData['labShape'] ? stoneData['labShape'] : stoneData['ShapeCode'];
      d = await this.replaceAll(stickerWithItemCodeForNy, '$stoneId', stoneId.toString());
    }
    console.log(key)
    d = await this.replaceAll(d, '$giaDate', CertDate);
    d = await this.replaceAll(d, '$report', ReportNoVal.toString());
    d = await this.replaceAll(d, '$giaInscription', Inscription);
    d = await this.replaceAll(d, '$Length', Length.toString());
    d = await this.replaceAll(d, '$Width', Width.toString());
    d = await this.replaceAll(d, '$TotDepthMm', TotDepthMm.toString() + 'mm');
    d = await this.replaceAll(d, '$cts', cts.toFixed(2));
    d = await this.replaceAll(d, '$color', color.toString());
    d = await this.replaceAll(d, '$clarity', clarity.toString());
    d = await this.replaceAll(d, '$cut', cut.toString());
    d = await this.replaceAll(d, "$tabelD", tabelD.toString());
    d = await this.replaceAll(d, '$girdle', girdle.toString());
    d = await this.replaceAll(d, '$culet', culet.toString());
    d = await this.replaceAll(d, '$polish', polish.toString());
    d = await this.replaceAll(d, '$symmetry', symmetry.toString());
    d = await this.replaceAll(d, '$fluorescence', fluorescence.toString());
    d = await this.replaceAll(d, '$itemCode', itemCode.toString());
    d = await this.replaceAll(d, "$table", table.toString());
    d = await this.replaceAll(d, '$labPktSeriesNo', labPktSeriesNo.toString());
    d = await this.replaceAll(d, '$lab', lab.toString());
    d = await this.replaceAll(d, "$barcode", barcodeNo.toString());
    d = await this.replaceAll(d, '$shape', shape.toString());
    d = await this.replaceAll(d, '$size', cts.toFixed(2));
    if (this.stickerType == "GIASticker") {
      if (key.toString().length > 16) {
        let first = key.toString().substring(0, 16);
        let second = key.toString().substring(16, 35);
        d = await this.replaceAll(d, '$firstkey', first.toString());
        d = await this.replaceAll(d, '$secondkey', second.toString());

      } else {
        d = await this.replaceAll(d, '$firstkey', key.toString());
        d = await this.replaceAll(d, '$secondkey', '');
      }
      return d;
    }
    if (this.stickerType == "pktNoSticker") {
      return d;
    }
    if (this.stickerType == "nonCertSticker") {
      return d;
    }
    if (this.stickerType == "incExpoSticker") {
      return d;
    }
    if (this.stickerType == "stickerWithItemCodeForNy") {
      return d;
    }
  }

  async printSticker(type) {
    const modal = await this.modalCtrl.create({
      component: StickerPrintNewPage,
      cssClass: "extraLargePage",
      backdropDismiss: false,
      componentProps: {
        stoneList: this.stonesList,
        type: type
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss1(d));
    return await modal.present();
  }

  async handleModalDismiss1(d) {
    if (d.data != 'doNotRefresh') {
    }
  }


}
