import { Component, OnInit } from "@angular/core";
import {
  AlertController,
  Events,
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from "@ionic/angular";

import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ConfigService } from "src/app/services/config/config.service";
import { ExportAsExcelService } from "src/app/services/export-as-excel.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import * as moment from "moment";

@Component({
  selector: "app-stone-price-tracking",
  templateUrl: "./stone-price-tracking.component.html",
  styleUrls: ["./stone-price-tracking.component.scss"],
})
export class StonePriceTrackingComponent implements OnInit {
  stoneId = "";
  fromDate: any;
  toDate: any;
  columns = [];
  listOfPrices = [];
  page = "stonePricingTracking";
  refreshGrid = false;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService
  ) {}

  ngOnInit() {
    this.columns = [
      {
        name: "Sr No",
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        },
      },
      { name: "Created Date", prop: "createdDate" },
      { name: "Created By", prop: "createdBy" },
      { name: "Inward Date", prop: "inwardDate" },
      { name: "Available For Sale", prop: "availableForSale" },
      { name: "Rapaport Price", prop: "rapaportPrice" },
      { name: "SPM Disc", prop: "spmDisc" },
      { name: "SPM Price", prop: "spmPrice" },
      { name: "SPM With PD Disc", prop: "spmWithPdDisc" },
      { name: "SPM With PD Price", prop: "spmWithPdPrice" },
      { name: "Base PD Disc", prop: "basePdDisc" },
      { name: "Base PD Price", prop: "basePdPrice" },
      { name: "Pprice", prop: "pprice" },
      { name: "Pprice Amt", prop: "pprice_Amount" },
      { name: "Mumbai Mfg Amt", prop: "mumbai_Mfg_Amt" },
      { name: "Mumbai Mfg Amt 2", prop: "mumbai_Mfg_Amt_2" },
      { name: "Rapnet_plus Disc", prop: "rapnetPlusDisc" },
      { name: "Rapnet_plus Price", prop: "rapnetPlusPrice" },
      { name: "Rapnet Org Disc", prop: "rapnetOrgDisc" },
      { name: "Rapnet Org Price", prop: "rapnetOrgPrice" },
      { name: "Sales Mix Price", prop: "salesMixPrice" },
    ];
  }

  async fetch() {
    let formData = {
      stoneId: this.stoneId,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.trackStonePricing(formData);
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfPrices = data.data.map((obj) => {
        obj.createdDate = moment
          .utc(obj.createdDate)
          .local()
          .format("DD-MM-YYYY hh:mm a");
        return obj;
      });
    }
  }
}
