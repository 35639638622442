import { Component, Input, HostListener, ViewChild } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import * as moment from "moment";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { PdAssortDetailsPage } from "../../actions/pd-assort-details/pd-assort-details.page";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
@Component({
  selector: "app-transfer-to-manufacture",
  templateUrl: "./transfer-to-manufacture.component.html",
  styleUrls: ["./transfer-to-manufacture.component.scss"]
})
export class TransferToManufactureComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;
  public page = 'assortment error stone'
  public listOfLocations = []
  public actionId: any;
  public process = 'issue';
  public issueWithTransType = 'NO';
  public toLocation: any;
  public toUser: any;
  public stoneIndex: any = [];
  public toLocationIdList = [];
  public usersList = [];
  public selectedStoneList = []
  public listOfActions = [];
  public listOfStones = []
  public actionName: any;
  public viewType = "ErrorBox";
  public selected = []
  public isSelect: boolean = false;
  public fxRate: any;
  public customerDetails: any;
  public status = "External";
  public deliveryChallanNo: any;
  public transType = 'SS'
  public nameOfPersonReceivingGood: any;
  public companyDetails: any;
  public requestJson = {};
  public voucherId: any;
  public remarks = ""
  public stoneList = ""
  loadGrid = false;
  public RFidList: any = "";
  public type: any;
  public userId: any;
  public toRole: any;
  public listOfUsers: any = [];
  public filtered: any = [];
  public count: number = 0;
  public voucherBookId: any;
  public timer = 10;
  public arr1: any;
  public arr2: any;
  public errorMsg = null;
  public columns = []
  public listOfErrorBox = []
  public errorMsgList = [];
  public successMsg = null;
  public fromDepartment: any;
  public msgStatus = false;
  public setOfStoneIds: any = [];
  public listOfStone: any;
  public totalRfidCount: any;
  public boardBaseUrl: any;
  public transporterDetails: any;
  public transferBy: any;
  public ledOn: boolean = false;
  public boxId: any;
  public currencyList = []
  public modeOfTransport: string = ''

  public flagOpenDoc: boolean = false;

  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public exportAsExcelService: ExportAsExcelService,
    public configService: ConfigService,
    public event: Events) { }


  async ngOnInit() {
    this.columns = [
      {
        name: "Sr No", cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Stone Id", prop: "stoneId" },
      { name: "Ct.", prop: "stoneCts" },
      { name: "Shape", prop: "shape" },
      { name: "Color", prop: "color" },
      { name: "Clarity", prop: "clarity" },
      { name: "Assort Price", prop: "assortPrice" },
    ];
    await this.getUserData()
    await this.getAllBoxesByDepartmentID()
    this.getTransporterDetail('Transport');
    this.getAllCurrency()
    this.getAllCompany()
    this.getAllActions()
    this.getCompanyDetail()

    this.event.subscribe('Return Assortment Error Stones', (item) => {
      this.getUserData()
      this.getAllBoxesByDepartmentID()
      this.getTransporterDetail('Transport');
      this.getAllCurrency()
      this.getAllCompany()
      this.getAllActions()
      this.getCompanyDetail()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Return Assortment Error Stones');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "locationForErrorStones") {
      if (idList.length > 0) {
        console.log(obj)
        this.toLocation = idList[0];
        this.getAllToUser(this.toLocation);
        this.toCompanyDetailByName(obj[0].companyName)
      } else {
        this.toLocation = null;
        this.usersList = [];
      }
    } else if (property == 'selectedUserList') {
      if (idList.length > 0) {
        this.toUser = idList[0];
        this.nameOfPersonReceivingGood = obj[0].username
      } else {
        this.toLocation = null;
        this.toUser = null;
      }
    } else if (property == 'selectedActionList') {
      if (idList.length > 0) {
        this.actionId = idList[0];
        this.actionName = obj[0].actionName;
        this.getDefaultSetting();
        this.getToLocationDefaultSetting()
      } else {
        this.actionId = null;
      }
    }
  }



  async getCompanyDetail() {
    let companyName = await this.configService.getFromLocation()
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.companyDetails = data.data;
    }
  }

  //To company detail for recut and observation 

  async toCompanyDetailByName(companyName) {
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }


  //currency detail

  async getAllCurrency() {
    let res: any;
    res = await this.httpApiService.getCurrencyList();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.currencyList = data.data;
      if (this.currencyList.length > 0) {
        this.currencyList.filter(el => {
          if (el.isDefault) {
            this.getLatestRateByCurrencyName(el.name)
          }
        })
      }
    }
  }

  async getLatestRateByCurrencyName(currency) {
    let res: any;
    res = await this.httpApiService.getLatestRateByCurrencyName(currency);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let currencyDetail = data.data;
      this.fxRate = currencyDetail.rate;
    }
  }

  // transporter detail 

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.transporterDetails = data.data[0];
      }
      console.log("transporterDetails", this.transporterDetails);
    }
  }

  async getAllBoxesByDepartmentID() {
    this.listOfErrorBox = []
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findByDepartmentIdAndUser(
      this.fromDepartment,
      'true'
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      dataList.forEach(el => {
        if (el.type == 'Assortment-Error') {
          this.listOfErrorBox.push(el)
        }
      })
      console.log("data", data, this.listOfErrorBox);
    }
  }

  async getUserData() {
    let userData = await this.authService.userData();
    console.log(userData)
    this.transferBy = userData.fullName;
    this.userId = userData.userId;
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
    if (userData.departmentId) {
      this.fromDepartment = userData.departmentId;
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
      }
      console.log(this.voucherBookId)
    }
  }

  async getToLocationDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);

      if (dataValue.map) {
        if (!!dataValue.map.toLocation) {
          this.toLocation = dataValue.map.toLocation;
          this.toLocationIdList = []
          this.listOfLocations.filter(el => {
            if (el.id == this.toLocation) {
              this.toLocationIdList.push({ id: el.id, companyName: el.companyName })
              this.toCompanyDetailByName(el.companyName)
              this.getAllToUser(this.toLocation);
            }
          })
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
      }
    }
  }

  async getAllCompany() {
    let res: any;
    // let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
    }
  }

  async issueStones() {
    // this.flagOpenDoc = true;
    // return;
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    if (!!this.voucherBookId) {
      let validate = await this.validateControls();
      if (validate) {
        await this.configService.showLoader();
        let jsonObj;
        let res: any;
        console.log(this.voucherBookId);
        this.requestJson = {}
        this.selected.forEach((el, i) => {
          this.requestJson[el.id] = {
            "requestUserId": this.userId.toString(),
            "requestPrice": el.price ? el.price.toString() : (!!el.assortPrice ? el.assortPrice : null),
          };
        });
        jsonObj = {
          requestDate: moment().format("YYYY-MM-DD"),
          voucherBookId: parseInt(this.voucherBookId),
          stoneRequestParams: this.requestJson,
          requestAction: this.actionName,
          remarks: this.remarks,
          userId: this.toUser,
          roleId: parseInt(this.toRole) || null,
          isMerge: true,
        };
        console.log("dataObj", jsonObj);
        res = await this.httpApiService.certificateOrConsignmentRequest(jsonObj);
        let data = res.json();
        await this.configService.dismiss();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          if (!!data.data && data.data.length > 0) {
            await this.configService.showToast("error", data.message);
            await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
          } else {
            await this.configService.showToast("error", 'Error occoured while receiving stones.');
          }
        } else {
          if (!!data.data && data.data.length == 1) {
            await this.configService.showToast("success", data.message);
            if (data.data[0].shortCode && data.data[0].bookSerialNumber) {
              this.voucherId = data.data[0].id;
              this.deliveryChallanNo = data.data[0].shortCode + '-' + data.data[0].bookSerialNumber
            }
            // if (!!data.data[0].url) {
            //   this.url = data.data[0].url;
            // }
            if (!!data.data && !!data.data[0].exgRate) {
              this.fxRate = data.data[0].exgRate;
            }
            this.modeOfTransport = 'Hand Delivery'

          }
          await this.configService.showToast("success", `stones transferred successfully`);
          // this.flagOpenDoc = true;
          this.viewType = "ErrorBox";
          this.RFidList = "";
          this.filtered = [];
          // this.findStonesByBoxID(this.boxId)
          this.listOfErrorBox = []
        }
      }
    } else {
      await this.configService.showToast('error', 'Voucherbook not found')
    }
  }

  async goToBox(viewType) {
    this.flagOpenDoc = false;
    this.viewType = viewType;
    await this.getAllBoxesByDepartmentID()
  }


  async getAllToUser(toLocation) {
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllUsersByCompany(appName, toLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.usersList = dataValue;
    }
  }

  async getAllActions() {
    let res: any;
    res = await this.httpApiService.getAllActions();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let dataList = []
      dataValue.filter(el => {
        if (el.actionName == 'Observation' || el.actionName == 'Recut') {
          dataList.push(el)
        }
      })
      this.listOfActions = dataList;
      console.log(this.listOfActions)
    }
  }

  async changeViewType(viewType, item) {
    this.selected = []
    this.viewType = viewType;
    if (item != 0) {
      this.boxId = item.id;
      this.findStonesByBoxID(item.id)
    }
  }

  async findStonesByBoxID(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findStonesByBoxID(
      id
    );
    console.log("box stone", res, res.json());

    let data = res.json();
    console.log("data", data);
    this.count = data.data.length;
    console.log("count", this.count);
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfStones = data.data;
      this.count = data.data.length;
    }
  }

  // Assortment Result Open Modal

  async showResultOfAssortPD(data) {
    const modal = await this.modalCtrl.create({
      component: PdAssortDetailsPage,
      backdropDismiss: false,
      cssClass: 'largePage',
      componentProps: {
        rowData: data.row.data
      },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalResultDismiss(d));
    return await modal.present();
  }

  handleModalResultDismiss(d) {
  }

  // On Select

  onSelect(ev, itemList) {
    this.selected = ev.selectedRowsData;
    this.selectedStoneList = []
    this.selectedStoneList = this.selected.map(el => el.stoneId);
  }


  fetch() {
    this.ledOn = true;
    this.selectedStoneList = []
    this.filtered = this.stoneList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    this.listOfStones.filter((el, i) => {
      this.filtered.filter(item => {
        if (item == el.stoneId) {
          this.selectedStoneList.push(el.stoneId)
          this.stoneIndex.push(i);
        }
      })
    })
    setTimeout(() => {
      this.ledOn = false;
    }, 1000)
  }

  async validateControls() {
    if (!!this.toUser) {
      return true;
    } else {
      await this.configService.showToast('error', 'Please select User')
      return false;
    }
  }

  async printDocument() {
    await this.configService.print('')
  }

}
