export default {
  shapes: [
    {
      label: 'Round',
      value: 'ROUND',
      href: '/assets/search-page-images/shape/ROUND.jpg',
    },
    {
      label: 'Princess',
      value: 'PRINCESS',
      href: '/assets/search-page-images/shape/PRINCESS.jpg',
    },
    {
      label: 'Cushion',
      value: 'CUSHION',
      href: '/assets/search-page-images/shape/CUSHION.jpg',
    },
    {
      label: 'Cushion Brilliant',
      value: 'CUSHION',
      href: '/assets/search-page-images/shape/CUSHION.jpg',
    },
    {
      label: 'Oval',
      value: 'OVAL',
      href: '/assets/search-page-images/shape/OVAL.jpg',
    },
    {
      label: 'Emerald',
      value: 'EMERALD',
      href: '/assets/search-page-images/shape/EMERALD.jpg',
    },
    {
      label: 'Pear',
      value: 'PEAR',
      href: '/assets/search-page-images/shape/PEAR.jpg',
    },
    {
      label: 'Radiant',
      value: 'RADIANT',
      href: '/assets/search-page-images/shape/RADIANT.jpg',
    },
    {
      label: 'Asscher',
      value: 'ASSCHER',
      href: '/assets/search-page-images/shape/ASSCHER.jpg',
    },
    {
      label: 'Marquise',
      value: 'MARQUISE',
      href: '/assets/search-page-images/shape/MARQUISE.jpg',
    },
    {
      label: 'Heart',
      value: 'HEART',
      href: '/assets/search-page-images/shape/HEART.jpg',
    },
  ],
  colors: [
    {
      label: 'D',
      value: 'D',
      href: '/assets/search-page-images/color/D.jpg',
    },
    {
      label: 'E',
      value: 'E',
      href: '/assets/search-page-images/color/E.jpg',
    },
    {
      label: 'F',
      value: 'F',
      href: '/assets/search-page-images/color/F.jpg',
    },
    {
      label: 'G',
      value: 'G',
      href: '/assets/search-page-images/color/G.jpg',
    },
    {
      label: 'H',
      value: 'H',
      href: '/assets/search-page-images/color/H.jpg',
    },
    {
      label: 'I',
      value: 'I',
      href: '/assets/search-page-images/color/I.jpg',
    },
    {
      label: 'J',
      value: 'J',
      href: '/assets/search-page-images/color/J.jpg',
    },
    {
      label: 'K',
      value: 'K',
      href: '/assets/search-page-images/color/K.jpg',
    },
    {
      label: 'L',
      value: 'L',
      href: '/assets/search-page-images/color/L.jpg',
    },
    {
      label: 'M',
      value: 'M',
      href: '/assets/search-page-images/color/M.jpg',
    },
    {
      label: 'N',
      value: 'N',
      href: '/assets/search-page-images/color/N.jpg',
    },
    {
      label: 'N-Z',
      value: [
        'O',
        'P',
        'N-O',
        'O-P',
        'P-R',
        'Q-R',
        'S-T',
        'U-V',
        'W-X',
        'Y-Z',
        'S-Z',
      ],
      href: '/assets/search-page-images/color/N-Z.jpg',
    },
  ],
  clarities: [
    {
      label: 'FL',
      value: 'FL',
      href: '/assets/search-page-images/clarity/FL.jpg',
    },
    {
      label: 'IF',
      value: 'IF',
      href: '/assets/search-page-images/clarity/IF.jpg',
    },
    {
      label: 'VVS1',
      value: 'VVS1',
      href: '/assets/search-page-images/clarity/VVS1.jpg',
    },
    {
      label: 'VVS2',
      value: 'VVS2',
      href: '/assets/search-page-images/clarity/VVS2.jpg',
    },
    {
      label: 'VS1',
      value: 'VS1',
      href: '/assets/search-page-images/clarity/VS1.jpg',
    },
    {
      label: 'VS2',
      value: 'VS2',
      href: '/assets/search-page-images/clarity/VS2.jpg',
    },
    {
      label: 'SI1',
      value: 'SI1',
      href: '/assets/search-page-images/clarity/SI1.jpg',
    },
    {
      label: 'SI2',
      value: 'SI2',
      href: '/assets/search-page-images/clarity/SI2.jpg',
    },
    {
      label: 'I1',
      value: 'I1',
      href: '/assets/search-page-images/clarity/I1.jpg',
    },
    {
      label: 'I2',
      value: 'I2',
      href: '/assets/search-page-images/clarity/I2.jpg',
    },
    {
      label: 'I3',
      value: 'I3',
      href: '/assets/search-page-images/clarity/I3.jpg',
    },
  ],
  advancedClarities: [
    {
      label: 'SI3+',
      value: 'SI3+',
    },
    {
      label: 'SI3',
      value: 'SI3',
    },
    {
      label: 'SI3-',
      value: 'SI3-',
    },
    {
      label: 'I1+',
      value: 'I1+',
    },
    {
      label: 'I1',
      value: 'I1',
    },
    {
      label: 'I1-',
      value: 'I1-',
    },
    {
      label: 'I2+',
      value: 'I2+',
    },
    {
      label: 'I2',
      value: 'I2',
    },
    {
      label: 'I2-',
      value: 'I2-',
    },
    {
      label: 'I3+',
      value: 'I3+',
    },
    {
      label: 'I3',
      value: 'I3',
    },
    {
      label: 'I3-',
      value: 'I3-',
    },
    {
      label: '2A+',
      value: '2A+',
    },
    {
      label: '2A',
      value: '2A',
    },
    {
      label: '2A-',
      value: '2A-',
    },
    {
      label: '3A+',
      value: '3A+',
    },
    {
      label: '3A',
      value: '3A',
    },
    {
      label: '3A-',
      value: '3A-',
    },
    {
      label: '3B+',
      value: '3B+',
    },
    {
      label: '3B',
      value: '3B',
    },
    {
      label: '3B-',
      value: '3B-',
    },
    {
      label: '4A+',
      value: '4A+',
    },
    {
      label: '4A',
      value: '4A',
    },
    {
      label: '4A-',
      value: '4A-',
    },
    {
      label: '4B+',
      value: '4B+',
    },
    {
      label: '4B',
      value: '4B',
    },
    {
      label: '4B-',
      value: '4B-',
    },
  ],
  descriptions: [
    {
      label: 'No BGM',
      value: 'No BGM',
      href: '/assets/search-page-images/extra/NO-BGM-NEW.jpg',
      para: [
        {
          label: 'ShadesCode',
          value: 'NONE',
        },
        {
          label: 'LusterCode',
          value: 'VG',
        },
        {
          label: 'LusterCode',
          value: 'GD',
        },
        {
          label: 'LusterCode',
          value: 'EX',
        },
        {
          label: 'LusterCode',
          value: 'FR',
        },
      ],
    },
    {
      label: 'EYE Clean 100',
      value: 'EYE Clean',
      secondaryText: '100%',
      para: [
        {
          label: 'EyeCleanCode',
          value: '100%',
        },
      ],
      href: '/assets/search-page-images/extra/EYE-CLEAN-100.jpg',
    },
    {
      label: 'Eye Clean 80',
      value: 'Eye Clean',
      secondaryText: '80',
      para: [
        {
          label: 'EyeCleanCode',
          value: '80%',
        },
      ],
      href: '/assets/search-page-images/extra/EYE-CLEAN-80.jpg',
    },
  ],
  cuts: [
    // {
    //     "label": "Ideal",
    //     "value": "IDEAL"
    // },
    {
      label: 'EX',
      value: 'EXCL',
    },
    {
      label: 'VG',
      value: 'VGOOD',
    },
    {
      label: 'GD',
      value: 'GOOD',
    },
    {
      label: 'FR',
      value: 'FAIR',
    },
  ],
  polishes: [
    // {
    //     "label": "Ideal",
    //     "value": "IDEAL"
    // },
    {
      label: 'EX',
      value: 'EXCL',
    },
    {
      label: 'VG',
      value: 'VGOOD',
    },
    {
      label: 'GD',
      value: 'GOOD',
    },
    {
      label: 'FR',
      value: 'FAIR',
    },
  ],
  symmetries: [
    // {
    //     "label": "Ideal",
    //     "value": "IDEAL"
    // },
    {
      label: 'EX',
      value: 'EXCL',
    },
    {
      label: 'VG',
      value: 'VGOOD',
    },
    {
      label: 'GD',
      value: 'GOOD',
    },
    {
      label: 'FR',
      value: 'FAIR',
    },
  ],
  finishings: [
    {
      label: 'IDEAL',
      value: 'IDEAL',
      query: [
        // {
        //     "bool": {
        //         "should": [
        //             {
        //                 "terms": {
        //                     "ShapeCode": ["ROUND-HA"]
        //                 }
        //             },
        //             {
        //                 "terms": {
        //                     "lab": ["AGS"]
        //                 }
        //             }
        //         ]
        //     }
        // },
        {
          terms: {
            CutCode: ['EXCL'],
          },
        },
        {
          terms: {
            PolishCode: ['EXCL'],
          },
        },
        {
          terms: {
            SymmetryCode: ['EXCL'],
          },
        },
      ],
    },
    {
      label: '3EX',
      value: '3EX',
      para: [
        {
          label: 'CutCode',
          value: 'EXCL',
        },
        {
          label: 'PolishCode',
          value: 'EXCL',
        },
        {
          label: 'SymmetryCode',
          value: 'EXCL',
        },
      ],
    },
    {
      label: 'EX-',
      value: 'EX-',
      query: [
        {
          bool: {
            should: [
              {
                bool: {
                  must: [
                    {
                      terms: {
                        CutCode: ['EXCL'],
                      },
                    },
                    {
                      terms: {
                        PolishCode: ['VGOOD'],
                      },
                    },
                    {
                      terms: {
                        SymmetryCode: ['EXCL'],
                      },
                    },
                  ],
                },
              },
              {
                bool: {
                  must: [
                    {
                      terms: {
                        CutCode: ['EXCL'],
                      },
                    },
                    {
                      terms: {
                        PolishCode: ['EXCL'],
                      },
                    },
                    {
                      terms: {
                        SymmetryCode: ['VGOOD'],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
    {
      label: 'VG+',
      value: 'VG+',
      para: [
        {
          label: 'CutCode',
          value: 'VGOOD',
        },
        {
          label: 'CutCode',
          value: 'EXCL',
        },
        {
          label: 'PolishCode',
          value: 'VGOOD',
        },
        {
          label: 'PolishCode',
          value: 'EXCL',
        },
        {
          label: 'SymmetryCode',
          value: 'VGOOD',
        },
        {
          label: 'SymmetryCode',
          value: 'EXCL',
        },
      ],
    },
    {
      label: 'VG-',
      value: 'VG-',
      para: [
        {
          label: 'CutCode',
          value: 'VGOOD',
        },
        {
          label: 'PolishCode',
          value: 'VGOOD',
        },
        {
          label: 'PolishCode',
          value: 'GOOD',
        },
        {
          label: 'SymmetryCode',
          value: 'VGOOD',
        },
        {
          label: 'SymmetryCode',
          value: 'GOOD',
        },
      ],
    },
    {
      label: 'GD+',
      value: 'GD+',
      para: [
        {
          label: 'CutCode',
          value: 'GOOD',
        },
        {
          label: 'PolishCode',
          value: 'EXCL',
        },
        {
          label: 'PolishCode',
          value: 'VGOOD',
        },
        {
          label: 'PolishCode',
          value: 'GOOD',
        },
        {
          label: 'SymmetryCode',
          value: 'EXCL',
        },
        {
          label: 'SymmetryCode',
          value: 'VGOOD',
        },
        {
          label: 'SymmetryCode',
          value: 'GOOD',
        },
      ],
    },
  ],
  fluorescences: [
    {
      label: 'NONE',
      value: 'NONE',
    },
    {
      label: 'FAINT',
      value: ['VERY SLIGHT', 'SLIGHT', 'FAINT'],
    },
    {
      label: 'MEDIUM',
      value: 'MEDIUM',
    },
    {
      label: 'STRONG',
      value: 'STRONG',
    },
    {
      label: 'VERY STRONG',
      value: 'VSTRONG',
    },
  ],
  labs: [
    {
      label: 'GIA',
      value: 'GIA',
    },
    {
      label: 'HRD',
      value: 'HRD',
    },
    {
      label: 'FM',
      value: ['FM01', 'FM02', 'BLFM'],
    },
    {
      label: 'IGI',
      value: 'IGI',
    },
    {
      label: 'GSI',
      value: 'GSI',
    },
    {
      label: 'KG LAB',
      value: 'KGLAB',
    },
    {
      label: 'DBO',
      value: 'DBO',
    },
    {
      label: 'NONE CERT',
      value: 'NONE CERT',
      hideCount: true,
    },
  ],
  fancyColorIntensities: [
    {
      id: 'ALL',
      label: 'ALL',
      value: 'ALL',
    },
    {
      id: 'FAINT',
      label: 'FAINT',
      value: 'FAINT',
    },
    {
      id: 'VERY LIGHT',
      label: 'VERY LIGHT',
      value: 'VERY LIGHT',
    },
    {
      id: 'LIGHT',
      label: 'LIGHT',
      value: 'LIGHT',
    },
    {
      id: 'FANCY LIGHT',
      label: 'FANCY LIGHT',
      value: 'FANCY LIGHT',
    },
    {
      id: 'FANCY',
      label: 'FANCY',
      value: 'FANCY',
    },
    {
      id: 'FANCY DARK',
      label: 'FANCY DARK',
      value: 'FANCY DARK',
    },
    {
      id: 'FANCY INTENSE',
      label: 'FANCY INTENSE',
      value: 'FANCY INTENSE',
    },
    {
      id: 'FANCY VIVID',
      label: 'FANCY VIVID',
      value: 'FANCY VIVID',
    },
    {
      id: 'FANCY DEEP',
      label: 'FANCY DEEP',
      value: 'FANCY DEEP',
    },
  ],
  selectedFancyColorIntensity: '',
  fancyColorOvertones: [
    {
      id: 'ALL',
      label: 'ALL',
      value: 'ALL',
    },
    {
      id: 'NONE',
      label: 'NONE',
      value: 'NONE',
    },
    {
      id: 'YELLOWISH',
      label: 'YELLOWISH',
      value: 'YELLOWISH',
    },
    {
      id: 'PINKISH',
      label: 'PINKISH',
      value: 'PINKISH',
    },
    {
      id: 'BLUISH',
      label: 'BLUISH',
      value: 'BLUISH',
    },
    {
      id: 'GREENISH',
      label: 'GREENISH',
      value: 'GREENISH',
    },
    {
      id: 'PURPLISH',
      label: 'PURPLISH',
      value: 'PURPLISH',
    },
    {
      id: 'ORANGY',
      label: 'ORANGY',
      value: 'ORANGY',
    },
    {
      id: 'GRAYISH',
      label: 'GRAYISH',
      value: 'GRAYISH',
    },
    {
      id: 'BROWNISH',
      label: 'BROWNISH',
      value: 'BROWNISH',
    },
    {
      id: 'BROWNISH GREENISH',
      label: 'BROWNISH GREENISH',
      value: 'BROWNISH GREENISH',
    },
    {
      id: 'BROWNISH YELLOWISH',
      label: 'BROWNISH YELLOWISH',
      value: 'BROWNISH YELLOWISH',
    },
    {
      id: 'GRAYISH GREENISH',
      label: 'GRAYISH GREENISH',
      value: 'GRAYISH GREENISH',
    },
    {
      id: 'GRAYISH YELLOWISH',
      label: 'GRAYISH YELLOWISH',
      value: 'GRAYISH YELLOWISH',
    },
    {
      id: 'BROWN-GREENISH',
      label: 'BROWN-GREENISH',
      value: 'BROWN-GREENISH',
    },
    {
      id: 'BROWNISH ORANGY',
      label: 'BROWNISH ORANGY',
      value: 'BROWNISH ORANGY',
    },
    {
      id: 'GRAY-GREENISH',
      label: 'GRAY-GREENISH',
      value: 'GRAY-GREENISH',
    },
    {
      id: 'GRAY-YELLOWISH',
      label: 'GRAY-YELLOWISH',
      value: 'GRAY-YELLOWISH',
    },
  ],
  selectedFancyColorOvertone: '',
  fancyColors: [
    {
      id: 'YELLOW',
      label: 'YELLOW',
      value: 'YELLOW',
    },
    {
      id: 'PINK',
      label: 'PINK',
      value: 'PINK',
    },
    {
      id: 'BLUE',
      label: 'BLUE',
      value: 'BLUE',
    },
    {
      id: 'GREEN',
      label: 'GREEN',
      value: 'GREEN',
    },
    {
      id: 'PURPLE',
      label: 'PURPLE',
      value: 'PURPLE',
    },
    {
      id: 'ORANGE',
      label: 'ORANGE',
      value: 'ORANGE',
    },
    {
      id: 'GRAY',
      label: 'GRAY',
      value: 'GRAY',
    },
    {
      id: 'BROWN',
      label: 'BROWN',
      value: 'BROWN',
    },
    {
      id: 'BROWN-ORANGE',
      label: 'BROWN-ORANGE',
      value: 'BROWN-ORANGE',
    },
    {
      id: 'BROWN-PINK',
      label: 'BROWN-PINK',
      value: 'BROWN-PINK',
    },
    {
      id: 'GREEN-YELLOW',
      label: 'GREEN-YELLOW',
      value: 'GREEN-YELLOW',
    },
    {
      id: 'ORANGE-YELLOW',
      label: 'ORANGE-YELLOW',
      value: 'ORANGE-YELLOW',
    },
    {
      id: 'PINK-PURPLE',
      label: 'PINK-PURPLE',
      value: 'PINK-PURPLE',
    },
    {
      id: 'PURPLE-PINK',
      label: 'PURPLE-PINK',
      value: 'PURPLE-PINK',
    },
    {
      id: 'YELLOW-GREEN',
      label: 'YELLOW-GREEN',
      value: 'YELLOW-GREEN',
    },
    {
      id: 'YELLOW-ORANGE',
      label: 'YELLOW-ORANGE',
      value: 'YELLOW-ORANGE',
    },
    {
      id: 'BROWN-YELLOW',
      label: 'BROWN-YELLOW',
      value: 'BROWN-YELLOW',
    },
    {
      id: 'YELLOW-GRAY',
      label: 'YELLOW-GRAY',
      value: 'YELLOW-GRAY',
    },
    {
      id: 'BLUE-GREEN',
      label: 'BLUE-GREEN',
      value: 'BLUE-GREEN',
    },
    {
      id: 'GREEN-BLUE',
      label: 'GREEN-BLUE',
      value: 'GREEN-BLUE',
    },
    {
      id: 'GRAY-BLUE',
      label: 'GRAY-BLUE',
      value: 'GRAY-BLUE',
    },
    {
      id: 'PINK-BROWN',
      label: 'PINK-BROWN',
      value: 'PINK-BROWN',
    },
    {
      id: 'YELLOW-BROWN',
      label: 'YELLOW-BROWN',
      value: 'YELLOW-BROWN',
    },
    {
      id: 'ORANGE-BROWN',
      label: 'ORANGE-BROWN',
      value: 'ORANGE-BROWN',
    },
  ],
  selectedFancyColor: '',
  sizeGroups: [
    {
      label: '0.30 - 0.39',
      from: '0.30',
      to: '0.39',
      value: '0.30-0.39',
    },
    {
      label: '0.40 - 0.49',
      from: '0.40',
      to: '0.49',
      value: '0.40-0.49',
    },
    {
      label: '0.50 - 0.69',
      from: '0.50',
      to: '0.69',
      value: '0.50-0.69',
    },
    {
      label: '0.70 - 0.89',
      from: '0.70',
      to: '0.89',
      value: '0.70-0.89',
    },
    {
      label: '0.90 - 0.99',
      from: '0.90',
      to: '0.99',
      value: '0.90-0.99',
    },
    {
      label: '1.00 - 1.49',
      from: '1.00',
      to: '1.49',
      value: '1.00-1.49',
    },
    {
      label: '1.50 - 1.99',
      from: '1.50',
      to: '1.99',
      value: '1.50-1.99',
    },
    {
      label: '2.00 - 2.99',
      from: '2.00',
      to: '2.99',
      value: '2.00-2.99',
    },
    {
      label: '3.00 - 4.99',
      from: '3.00',
      to: '4.99',
      value: '3.00-4.99',
    },
    {
      label: '5.00+',
      from: '5.00',
      value: '5.00+',
    },
  ],
  selectedSizeGroup: '',
  saveSearchName: '',
  fromCarat: '',
  toCarat: '',
  stoneId: '',
  certNo: '',
  stoneIdORCertSelected: 'stoneId',
  colorType: 'white',
  caratType: 'specific',
  clarityType: 'basic',
  finishingType: 'general',
  shapeType: 'standard',
  tablePerFrom: '',
  tablePerTo: '',
  depthPerFrom: '',
  depthPerTo: '',
  lengthFrom: '',
  lengthTo: '',
  widthFrom: '',
  widthTo: '',
  depthFrom: '',
  depthTo: '',
  ratioFrom: '',
  ratioTo: '',
  crownAngleFrom: '',
  crownAngleTo: '',
  crownHeightFrom: '',
  crownHeightTo: '',
  girdles: [
    {
      label: 'ETN',
      value: 'ETN',
    },
    {
      label: 'ETN TO VTN',
      value: 'ETN TO VTN',
    },
    {
      label: 'ETN TO THN',
      value: 'ETN TO THN',
    },
    {
      label: 'ETN TO STN',
      value: 'ETN TO STN',
    },
    {
      label: 'ETN TO MED',
      value: 'ETN TO MED',
    },
    {
      label: 'ETN TO STK',
      value: 'ETN TO STK',
    },
    {
      label: 'ETN TO THK',
      value: 'ETN TO THK',
    },
    {
      label: 'ETN TO VTK',
      value: 'ETN TO VTK',
    },
    {
      label: 'ETN TO ETK',
      value: 'ETN TO ETK',
    },
    {
      label: 'VTN',
      value: 'VTN',
    },
    {
      label: 'VTN TO THN',
      value: 'VTN TO THN',
    },
    {
      label: 'VTN TO STN',
      value: 'VTN TO STN',
    },
    {
      label: 'VTN TO MED',
      value: 'VTN TO MED',
    },
    {
      label: 'VTN TO STK',
      value: 'VTN TO STK',
    },
    {
      label: 'VTN TO THK',
      value: 'VTN TO THK',
    },
    {
      label: 'VTN TO VTK',
      value: 'VTN TO VTK',
    },
    {
      label: 'VTN TO ETK',
      value: 'VTN TO ETK',
    },
    {
      label: 'THN',
      value: 'THN',
    },
    {
      label: 'THN TO STN',
      value: 'THN TO STN',
    },
    {
      label: 'THN TO MED',
      value: 'THN TO MED',
    },
    {
      label: 'THN TO STK',
      value: 'THN TO STK',
    },
    {
      label: 'THN TO THK',
      value: 'THN TO THK',
    },
    {
      label: 'THN TO VTK',
      value: 'THN TO VTK',
    },
    {
      label: 'THN TO ETK',
      value: 'THN TO ETK',
    },
    {
      label: 'STN',
      value: 'STN',
    },
    {
      label: 'STN TO MED',
      value: 'STN TO MED',
    },
    {
      label: 'STN TO STK',
      value: 'STN TO STK',
    },
    {
      label: 'STN TO THK',
      value: 'STN TO THK',
    },
    {
      label: 'STN TO VTK',
      value: 'STN TO VTK',
    },
    {
      label: 'STN TO ETK',
      value: 'STN TO ETK',
    },
    {
      label: 'MED',
      value: 'MED',
    },
    {
      label: 'MED TO STK',
      value: 'MED TO STK',
    },
    {
      label: 'MED TO THK',
      value: 'MED TO THK',
    },
    {
      label: 'MED TO VTK',
      value: 'MED TO VTK',
    },
    {
      label: 'MED TO ETK',
      value: 'MED TO ETK',
    },
    {
      label: 'STK',
      value: 'STK',
    },
    {
      label: 'STK TO THK',
      value: 'STK TO THK',
    },
    {
      label: 'STK TO VTK',
      value: 'STK TO VTK',
    },
    {
      label: 'STK TO ETK',
      value: 'STK TO ETK',
    },
    {
      label: 'THK',
      value: 'THK',
    },
    {
      label: 'THK TO VTK',
      value: 'THK TO VTK',
    },
    {
      label: 'THK TO ETK',
      value: 'THK TO ETK',
    },
    {
      label: 'VTK',
      value: 'VTK',
    },
    {
      label: 'VTK TO ETK',
      value: 'VTK TO ETK',
    },
    {
      label: 'ETK',
      value: 'ETK',
    },
  ],
  selectedGirdle: '',
  pavilionAngleFrom: '',
  pavilionAngleTo: '',
  pavilionDepthFrom: '',
  pavilionDepthTo: '',
  fm: [
    {
      label: 'FM Eligible',
      value: 'FM Eligible',
      query: [
        {
          bool: {
            must: {
              exists: {
                field: 'FmStr',
              },
            },
          },
        },
      ],
      hideCount: true,
    },
    {
      label: 'FM INSCRIPTION',
      value: 'FM INSCRIPTION',
      query: [
        {
          bool: {
            must: {
              exists: {
                field: 'FMInscription',
              },
            },
          },
        },
      ],
      hideCount: true,
    },
    {
      label: 'NO FM INSCRIPTION',
      value: 'NO FM INSCRIPTION',
      query: [
        {
          bool: {
            must_not: {
              exists: {
                field: 'FMInscription',
              },
            },
          },
        },
      ],
      hideCount: true,
    },
  ],
};
