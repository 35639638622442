import { CommonModule, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { IonicModule } from "@ionic/angular";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AngularDateTimePickerModule } from "angular2-datetimepicker";
import { DxSelectBoxModule } from "devextreme-angular";
import { DxDataGridModule } from "devextreme-angular/ui/data-grid";
import { DxPivotGridModule } from "devextreme-angular/ui/pivot-grid";
import { IonicSelectableModule } from "ionic-selectable";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { AccordionModule } from "ngx-accordion";
import { NgxBarcodeModule } from "ngx-barcode";
import { QRCodeModule } from "angularx-qrcode";

import { MomentPipe } from "../pipes/moment/moment.pipe";
import { ApplyPolicyComponent } from "./actions/apply-policy/apply-policy.component";
import { ApprovalMemoComponent } from "./actions/approval-memo/approval-memo.component";
import { AssortParameterConfigComponent } from "./actions/assort-parameter-config/assort-parameter-config.component";
import { AssortmentSearchComponent } from "./actions/assortment-search/assortment-search.component";
import { AssortmentComponent } from "./actions/assortment/assortment.component";
import { AuditCSSPComponent } from "./actions/audit-cssp/audit-cssp.component";
import { BoxMasterComponent } from "./actions/box-master/box-master.component";
import { BoxTransferComponent } from "./actions/box-transfer/box-transfer.component";
import { CertificationComponent } from "./actions/certification/certification.component";
import { ClearAssortmentStockComponent } from "./actions/clear-assortment-stock/clear-assortment-stock.component";
import { CompanyComponent } from "./actions/company/company.component";
import { ConsignmentConfirmComponent } from "./actions/consignment-confirm/consignment-confirm.component";
import { ConsignmentInStockComponent } from "./actions/consignment-in-stock/consignment-in-stock.component";
import { ConsignmentComponent } from "./actions/consignment/consignment.component";
import { ConversionRateMasterComponent } from "./actions/conversion-rate-master/conversion-rate-master.component";
import { CsspTransferMappingComponent } from "./actions/cssp-transfer-mapping/cssp-transfer-mapping.component";
import { CsvDownloadHistoryPage } from "./actions/csv-download-history/csv-download-history.page";
import { CustomerConfirmComponent } from "./actions/customer-confirm/customer-confirm.component";
import { CustomerHoldComponent } from "./actions/customer-hold/customer-hold.component";
import { CustomerComponent } from "./actions/customer/customer.component";
import { CvdCheckComponent } from "./actions/cvd-check/cvd-check.component";
import { DefaultTemplatesComponent } from "./actions/default-templates/default-templates.component";
import { DevExtremeDataGridComponent } from "./actions/dev-extreme-data-grid/dev-extreme-data-grid.component";
import { DiamondSecurityCheckComponent } from "./actions/diamond-security-check/diamond-security-check.component";
import { ElasticSearchComponent } from "./actions/elastic-search/elastic-search.component";
import { EmailToCustomerPage } from "./actions/email-to-customer/email-to-customer.page";
import { EnquiryMasterComponent } from "./actions/enquiry-master/enquiry-master.component";
import { ExportTemplatesComponent } from "./actions/export-templates/export-templates.component";
import { ExternalInventoryComponent } from "./actions/external-inventory/external-inventory.component";
import { FinalizePPriceComponent } from "./actions/finalize-pprice/finalize-pprice.component";
import { FixedPolicyMatrixPage } from "./actions/fixed-policy-matrix/fixed-policy-matrix.page";
import { FtpMasterComponent } from "./actions/ftp-master/ftp-master.component";
import { GeneratePOComponent } from "./actions/generate-po/generate-po.component";
import { InterBoxTransferPage } from "./actions/inter-box-transfer/inter-box-transfer.page";
import { InterCSSPTransferComponent } from "./actions/inter-cssptransfer/inter-cssptransfer.component";
import { InventoryBoxComponent } from "./actions/inventory-box/inventory-box.component";
import { InventoryErrorComponent } from "./actions/inventory-error/inventory-error.component";
import { InventoryManagerMIXComponent } from "./actions/inventory-manager-mix/inventory-manager-mix.component";
import { InventoryManagerSSComponent } from "./actions/inventory-manager-ss/inventory-manager-ss.component";
import { InventoryManagerComponent } from "./actions/inventory-manager/inventory-manager.component";
import { InventoryComponent } from "./actions/inventory/inventory.component";
import { IssueReceivedComponent } from "./actions/issue-received/issue-received.component";
import { LabConversionRateMasterComponent } from "./actions/lab-conversion-rate-master/lab-conversion-rate-master.component";
import { LabExpensesComponent } from "./actions/lab-expenses/lab-expenses.component";
import { LabPacketServiceMasterComponent } from "./actions/lab-packet-service-master/lab-packet-service-master.component";
import { LabServicesComponent } from "./actions/lab-services/lab-services.component";
import { ManageActionComponent } from "./actions/manage-action/manage-action.component";
import { ManageAssortmentPage } from "./actions/manage-assortment/manage-assortment.page";
import { ManageBoxMasterPage } from "./actions/manage-box-master/manage-box-master.page";
import { ManageCertificationPage } from "./actions/manage-certification/manage-certification.page";
import { ManageCompanyPage } from "./actions/manage-company/manage-company.page";
import { ManageConversionRateMasterPage } from "./actions/manage-conversion-rate-master/manage-conversion-rate-master.page";
import { ManageCsspTransferMappingPage } from "./actions/manage-cssp-transfer-mapping/manage-cssp-transfer-mapping.page";
import { ManageCustomerPage } from "./actions/manage-customer/manage-customer.page";
import { ManageEnquiryMasterComponent } from "./actions/manage-enquiry-master/manage-enquiry-master.component";
import { ManageExternalInventoryStonesPage } from "./actions/manage-external-inventory-stones/manage-external-inventory-stones.page";
import { ManageFilterComponent } from "./actions/manage-filter/manage-filter.component";
import { ManageFtpMasterPage } from "./actions/manage-ftp-master/manage-ftp-master.page";
import { ManageInterCsspTransferPage } from "./actions/manage-inter-cssp-transfer/manage-inter-cssp-transfer.page";
import { ManageInventoryManagerPage } from "./actions/manage-inventory-manager/manage-inventory-manager.page";
import { ManageLabPacketServiceMasterPage } from "./actions/manage-lab-packet-service-master/manage-lab-packet-service-master.page";
import { ManageLabServicesPage } from "./actions/manage-lab-services/manage-lab-services.page";
import { ManageMasterPage } from "./actions/manage-master/manage-master.page";
import { ManageOfferDetailsPage } from "./actions/manage-offer-details/manage-offer-details.page";
import { ManageParameterMappingMasterPage } from "./actions/manage-parameter-mapping-master/manage-parameter-mapping-master.page";
import { ManagePendingCustomerApprovalPage } from "./actions/manage-pending-customer-approval/manage-pending-customer-approval.page";
import { ManagePolicyChainPage } from "./actions/manage-policy-chain/manage-policy-chain.page";
import { ManagePolicyGeneratorPage } from "./actions/manage-policy-generator/manage-policy-generator.page";
import { ManagePricingSizeMasterPage } from "./actions/manage-pricing-size-master/manage-pricing-size-master.page";
import { ManageProgrammePage } from "./actions/manage-programme/manage-programme.page";
import { ManagePurchaseInwardPage } from "./actions/manage-purchase-inward/manage-purchase-inward.page";
import { ManageRequestApprovalPage } from "./actions/manage-request-approval/manage-request-approval.page";
import { ManageRequestsPage } from "./actions/manage-requests/manage-requests.page";
import { ManageRolesPage } from "./actions/manage-roles/manage-roles.page";
import { ManageRulesPage } from "./actions/manage-rules/manage-rules.page";
import { ManageSearchPage } from "./actions/manage-search/manage-search.page";
import { ManageSkipAssortmentPage } from "./actions/manage-skip-assortment/manage-skip-assortment.page";
import { ManageSsParameterPage } from "./actions/manage-ss-parameter/manage-ss-parameter.page";
import { ManageStatusPage } from "./actions/manage-status/manage-status.page";
import { ManageStoneInwardPage } from "./actions/manage-stone-inward/manage-stone-inward.page";
import { ManageTemplatesPage } from "./actions/manage-templates/manage-templates.page";
import { ManageTermMasterPage } from "./actions/manage-term-master/manage-term-master.page";
import { ManageUploadStonePage } from "./actions/manage-upload-stone/manage-upload-stone.page";
import { ManageUserPage } from "./actions/manage-user/manage-user.page";
import { ManageVoucherBookPage } from "./actions/manage-voucher-book/manage-voucher-book.page";
import { MarkAsSoldComponent } from "./actions/mark-as-sold/mark-as-sold.component";
import { MasterComponent } from "./actions/master/master.component";
import { MemoClientComponent } from "./actions/memo-client/memo-client.component";
import { MemoServiceComponent } from "./actions/memo-service/memo-service.component";
import { MixPricingCalcComponent } from "./actions/mix-pricing-calc/mix-pricing-calc.component";
import { MixToSingleTransferComponent } from "./actions/mix-to-single-transfer/mix-to-single-transfer.component";
import { MixToSingleComponent } from "./actions/mix-to-single/mix-to-single.component";
import { ObservationComponent } from "./actions/observation/observation.component";
import { ParameterMappingMasterComponent } from "./actions/parameter-mapping-master/parameter-mapping-master.component";
import { PartyParameterMappingComponent } from "./actions/party-parameter-mapping/party-parameter-mapping.component";
import { PdAssortDetailsPage } from "./actions/pd-assort-details/pd-assort-details.page";
import { PdFiltersComponent } from "./actions/pd-filters/pd-filters.component";
import { PDComponent } from "./actions/pd/pd.component";
import { PendingApprovalComponent } from "./actions/pending-approval/pending-approval.component";
import { PolicyChainComponent } from "./actions/policy-chain/policy-chain.component";
import { PolicyTrackerComponent } from "./actions/policy-tracker/policy-tracker.component";
import { PriceListUploaderComponent } from "./actions/price-list-uploader/price-list-uploader.component";
import { PriceNameMasterPage } from "./actions/price-name-master/price-name-master.page";
import { PricingCalcComponent } from "./actions/pricing-calc/pricing-calc.component";
import { PricingHistoryComponent } from "./actions/pricing-history/pricing-history.component";
import { PricingPolicy } from "./actions/pricing-policy/pricing-policy.component";
import { PricingSizeMasterComponent } from "./actions/pricing-size-master/pricing-size-master.component";
import { ProgramManagementPage } from "./actions/program-management/program-management.page";
import { ProgrammeComponent } from "./actions/programme/programme.component";
import { PurchaseBulkUploaderComponent } from "./actions/purchase-bulk-uploader/purchase-bulk-uploader.component";
import { PurchaseConsolidationIncomingstonesComponent } from "./actions/purchase-consolidation-incomingstones/purchase-consolidation-incomingstones.component";
import { PurchaseHistoryComponent } from "./actions/purchase-history/purchase-history.component";
import { PurchaseInwardComponent } from "./actions/purchase-inward/purchase-inward.component";
import { PurchaseSearchComponent } from "./actions/purchase-search/purchase-search.component";
import { PurchaseToMixComponent } from "./actions/purchase-to-mix/purchase-to-mix.component";
import { PurchaseToSingleComponent } from "./actions/purchase-to-single/purchase-to-single.component";
import { PurchaseUpdatetermsandbankratesComponent } from "./actions/purchase-updatetermsandbankrates/purchase-updatetermsandbankrates.component";
import { PurchaseconsolidationReturnStonesComponent } from "./actions/purchaseconsolidation-return-stones/purchaseconsolidation-return-stones.component";
import { PurchaseconsolidationUpdateparameterviewComponent } from "./actions/purchaseconsolidation-updateparameterview/purchaseconsolidation-updateparameterview.component";
import { RapaportMappingComponent } from "./actions/rapaport-mapping/rapaport-mapping.component";
import { RapaportParameterMappingMasterPage } from "./actions/rapaport-parameter-mapping-master/rapaport-parameter-mapping-master.page";
import { RapaportPriceListUploaderComponent } from "./actions/rapaport-price-list-uploader/rapaport-price-list-uploader.component";
import { ReceiveincomingstonesComponent } from "./actions/receiveincomingstones/receiveincomingstones.component";
import { RecutInwardComponent } from "./actions/recut-inward/recut-inward.component";
import { RecutComponent } from "./actions/recut/recut.component";
import { ReportsComponent } from "./actions/reports/reports.component";
import { RequestApprovalOfOutPlatformComponent } from "./actions/request-approval-of-out-platform/request-approval-of-out-platform.component";
import { RequestFormComponent } from "./actions/request-form/request-form.component";
import { RolesComponent } from "./actions/roles/roles.component";
import { SalesInventoryComponent } from "./actions/sales-inventory/sales-inventory.component";
import { ScanBoxErrorManagementComponent } from "./actions/scan-box-error-management/scan-box-error-management.component";
import { ScanRFIDPage } from "./actions/scan-rfid/scan-rfid.page";
import { SearchMixStoneComponent } from "./actions/search-mix-stone/search-mix-stone.component";
import { SearchSingleStoneComponent } from "./actions/search-single-stone/search-single-stone.component";
import { SearchComponent } from "./actions/search/search.component";
import { SelectSchedulerTimePage } from "./actions/select-scheduler-time/select-scheduler-time.page";
import { SelectionOnSortPage } from "./actions/selection-on-sort/selection-on-sort.page";
import { SingleMixParamMappingComponent } from "./actions/single-mix-param-mapping/single-mix-param-mapping.component";
import { SingleMultiDropdownComponent } from "./actions/single-multi-dropdown/single-multi-dropdown.component";
import { SingleToMixTransferComponent } from "./actions/single-to-mix-transfer/single-to-mix-transfer.component";
import { SingleToMixComponent } from "./actions/single-to-mix/single-to-mix.component";
import { SkipAssortmentCriteriaComponent } from "./actions/skip-assortment-criteria/skip-assortment-criteria.component";
import { SoldSearchComponent } from "./actions/sold-search/sold-search.component";
import { SoldComponent } from "./actions/sold/sold.component";
import { SortComponent } from "./actions/sort/sort.component";
import { SSParameterComponent } from "./actions/ss-parameter/ss-parameter.component";
import { StatusMasterComponent } from "./actions/status-master/status-master.component";
import { StickerPrintingOptionsPage } from "./actions/sticker-printing-options/sticker-printing-options.page";
import { StickerPrintingComponent } from "./actions/sticker-printing/sticker-printing.component";
import { StockUploadComponent } from "./actions/stock-upload/stock-upload.component";
import { StoneFinalizationComponent } from "./actions/stone-finalization/stone-finalization.component";
import { StoneInwardComponent } from "./actions/stone-inward/stone-inward.component";
import { StoneTrackingComponent } from "./actions/stone-tracking/stone-tracking.component";
import { StoneWithMeMixComponent } from "./actions/stone-with-me-mix/stone-with-me-mix.component";
import { StoneWithMeSingleComponent } from "./actions/stone-with-me-single/stone-with-me-single.component";
import { StonesWithMeComponent } from "./actions/stones-with-me/stones-with-me.component";
import { TermMasterComponent } from "./actions/term-master/term-master.component";
import { TransferStoneComponent } from "./actions/transfer-stone/transfer-stone.component";
import { TransferToManufactureComponent } from "./actions/transfer-to-manufacture/transfer-to-manufacture.component";
import { TransferToSalesMixComponent } from "./actions/transfer-to-sales-mix/transfer-to-sales-mix.component";
import { TransferToSalesSingleComponent } from "./actions/transfer-to-sales-single/transfer-to-sales-single.component";
import { UpdateAvailableForSalesComponent } from "./actions/update-available-for-sales/update-available-for-sales.component";
import { UpdateBarcodeComponent } from "./actions/update-barcode/update-barcode.component";
import { UpdateKYCApprovalComponent } from "./actions/update-kycapproval/update-kycapproval.component";
import { UpdateParameterComponent } from "./actions/update-parameter/update-parameter.component";
import { UpdateRfidComponent } from "./actions/update-rfid/update-rfid.component";
import { UpdateStoneIdComponent } from "./actions/update-stone-id/update-stone-id.component";
import { UploadAssortmentPage } from "./actions/upload-assortment/upload-assortment.page";
import { UploadBulkToSinglePage } from "./actions/upload-bulk-to-single/upload-bulk-to-single.page";
import { UploadInternalLabResultsComponent } from "./actions/upload-internal-lab-results/upload-internal-lab-results.component";
import { UploadRecutRulesComponent } from "./actions/upload-recut-rules/upload-recut-rules.component";
import { UploadSingleToMixPage } from "./actions/upload-single-to-mix/upload-single-to-mix.page";
import { UploadStoneComponent } from "./actions/upload-stone/upload-stone.component";
import { UserComponent } from "./actions/user/user.component";
import { ViewScanResultPage } from "./actions/view-scan-result/view-scan-result.page";
import { ViewComponent } from "./actions/view/view.component";
import { VoucherBookComponent } from "./actions/voucher-book/voucher-book.component";
import { WebsiteOfferComponent } from "./actions/website-offer/website-offer.component";
import { DeliveryChallanComponent } from "./delivery-challan/delivery-challan.component";
import { DevExtremeWithPaginationComponent } from "./dev-extreme-with-pagination/dev-extreme-with-pagination.component";
import { FileUploadComponent } from "./fileUpload/file-upload.component";
import { StoneInfoScrollComponent } from "./stone-info-scroll/stone-info-scroll.component";
import { StoneInfoComponent } from "./stone-info/stone-info.component";
import { UpdateMyScannerBoardComponent } from "./update-my-scanner-board/update-my-scanner-board.component";
import { UpdatePDFileUploadComponent } from "./update-pdfile-upload/update-pdfile-upload.component";
import { AccordionItemComponent } from "./actions/accordion-item/accordion-item.component";
import { AccordionComponent } from "./actions/accordion/accordion.component";
import { DesignbybudgetComponent } from "./actions/designbybudget/designbybudget.component";
import { PdPurchaseStonesComponent } from "./actions/pd-purchase-stones/pd-purchase-stones.component";
import { PurchaseUpdateInvoiceNoAndSeqNoComponent } from "./actions/purchase-update-invoice-no-and-seq-no/purchase-update-invoice-no-and-seq-no.component";
import { ReturningpurchasestonemodalComponent } from "./actions/returningpurchasestonemodal/returningpurchasestonemodal.component";
import { UpdateElasticDataComponent } from "./actions/update-elastic-data/update-elastic-data.component";
import { PricingLogsComponent } from "./actions/pricing-logs/pricing-logs.component";
import { InspectionViewingComponent } from "./actions/inspection-viewing/inspection-viewing.component";
import { LastTrayComponent } from "./actions/last-tray/last-tray.component";
import { ManageProcessRuleConfigComponent } from "./actions/manage-process-rule-config/manage-process-rule-config.component";
import { ManufacturingSearchComponent } from "./actions/manufacturing-search/manufacturing-search.component";
import { ProcessRuleConfigComponent } from "./actions/process-rule-config/process-rule-config.component";
import { RapnetStockUploadComponent } from "./actions/rapnet-stock-upload/rapnet-stock-upload.component";
import { StoneGenerationComponent } from "./actions/stone-generation/stone-generation.component";
import { StoneGenerationbuyinglistComponent } from "./actions/stone-generationbuyinglist/stone-generationbuyinglist.component";
import { StonegenerationbuyingpurposeComponent } from "./actions/stonegenerationbuyingpurpose/stonegenerationbuyingpurpose.component";
import { WebsiteReportsComponent } from "./actions/website-reports/website-reports.component";
import { OtherOfficeStockProcessComponent } from "./other-office-stock-process/other-office-stock-process.component";
import { SvcActionsPage } from "./svc-actions/svc-actions.page";
import { SvcRequestComponent } from "./svc-request/svc-request.component";
import { SvcSearchComponent } from "./svc-search/svc-search.component";
import { BlockForSaleComponent } from "./actions/block-for-sale/block-for-sale.component";
import { BlockForSaleRequestFormPage } from "./actions/block-for-sale-request-form/block-for-sale-request-form.page";
import { StickerPrintNewPage } from "./actions/sticker-print-new/sticker-print-new.page";
import { LiveInventoryComponent } from "./actions/live-inventory/live-inventory.component";
import { WebsiteUsersComponent } from "./actions/website-users/website-users.component";
import { SalesComparisonReportComponent } from "./actions/sales-comparison-report/sales-comparison-report.component";
import { WebsiteLastSigninDetailComponent } from "./actions/website-last-signin-detail/website-last-signin-detail.component";
import { BatchpartystockuploaderComponent } from "./batchpartystockuploader/batchpartystockuploader.component";
import { SalesDashboardComponent } from "./actions/sales-dashboard/sales-dashboard.component";
import { SyncOtherOfficeStockComponent } from "./actions/sync-other-office-stock/sync-other-office-stock.component";
import { SvcHoldSearchComponent } from "./actions/svc-hold-search/svc-hold-search.component";
import { LabResultUploadForOtherOfficeComponent } from "./actions/lab-result-upload-for-other-office/lab-result-upload-for-other-office.component";
import { AshokaTextFileComponent } from "./actions/ashoka-text-file/ashoka-text-file.component";
import { HoldReportComponent } from "./actions/hold-report/hold-report.component";
import { PolishPurchaseInwardReportComponent } from "./actions/polish-purchase-inward-report/polish-purchase-inward-report.component";
import { CreateWebsiteUserComponent } from "./actions/create-website-user/create-website-user.component";
import { IonIntlTelInputModule } from "./actions/ion-intl-tel-input/ion-intl-tel-input.module";
import { PositionReportComponent } from "./actions/position-report/position-report.component";
import { ManualSalesUploadComponent } from "./actions/manual-sales-upload/manual-sales-upload.component";
import { EsRefinementListComponent } from "./actions/es-refinement-list/es-refinement-list.component";
import { PlanandsalesstonesComponent } from "./actions/planandsalesstones/planandsalesstones.component";
import { LayoutsComponent } from "./actions/layouts/layouts.component";
import { ManageLayoutsComponent } from "./actions/manage-layouts/manage-layouts.component";
import { LogisticsApprovalComponent } from "./actions/logistics-approval/logistics-approval.component";
import { CurrentParameterSearchComponent } from "./actions/current-parameter-search/current-parameter-search.component";
import { InternationalCodesComponent } from "./actions/international-codes/international-codes.component";
import { ProgramRejectionComponent } from "./actions/program-rejection/program-rejection.component";
import { QueuedActionComponent } from "./actions/queued-action/queued-action.component";
import { PotentialBuyersComponent } from "./actions/potential-buyers/potential-buyers.component";
import { StoneOfferCaptureComponent } from "./actions/stone-offer-capture/stone-offer-capture.component";
import { RfidHistoryComponent } from "./actions/rfid-history/rfid-history.component";
import { StoneSelectionRejectionComponent } from "./actions/stone-selection-rejection/stone-selection-rejection.component";
import { StonePriceTrackingComponent } from "./actions/stone-price-tracking/stone-price-tracking.component";
import { ScanViaRFIDComponent } from "./actions/scan-via-rfid/scan-via-rfid.component";
import { GlobalSVCSearchComponent } from "./actions/global-svcsearch/global-svcsearch.component";
import { CapitalCasePipe } from "../pipes/capitalCase/capitalCase.pipe";
import { UploadManualPricingComponent } from "./actions/upload-manual-pricing/upload-manual-pricing.component";
import { PotentialBuyersSelectionSectionComponent } from "./actions/potential-buyers/potential-buyers-selection-section/potential-buyers-selection-section.component";
import { PotentialBuyersDataSectionComponent } from "./actions/potential-buyers/potential-buyers-data-section/potential-buyers-data-section.component";
import { BtnGroupHorizontalComponent } from "../shared/btn-group-horizontal/btn-group-horizontal.component";
import { PotentialBuyersDataCardComponent } from "./actions/potential-buyers/potential-buyers-data-section/potential-buyers-data-card/potential-buyers-data-card.component";
import { PotentialBuyersStoneSelectionPopupComponent } from "./actions/potential-buyers/potential-buyers-stone-selection-popup/potential-buyers-stone-selection-popup.component";
import { WebsiteConfigComponent } from "./actions/website-config/website-config.component";
import { BulletinComponent } from './actions/bulletin/bulletin.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DragulaModule,
    NgxDatatableModule,
    NgMultiSelectDropDownModule,
    AccordionModule,
    NgxBarcodeModule,
    AngularDateTimePickerModule,
    IonicSelectableModule,
    DxDataGridModule,
    DxPivotGridModule,
    DxSelectBoxModule,
    QRCodeModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonIntlTelInputModule,
    // NgSelectModule
  ],
  declarations: [
    RolesComponent,
    SingleMultiDropdownComponent,
    SearchComponent,
    ManageRolesPage,
    StoneInwardComponent,
    ManageActionComponent,
    UserComponent,
    ManageUserPage,
    UploadStoneComponent,
    ManageUploadStonePage,
    MasterComponent,
    ManageMasterPage,
    FileUploadComponent,
    MomentPipe,
    ManageStoneInwardPage,
    AssortmentComponent,
    ManageAssortmentPage,
    InventoryManagerComponent,
    ManageInventoryManagerPage,
    BoxMasterComponent,
    ManageBoxMasterPage,
    ScanRFIDPage,
    TransferToManufactureComponent,
    InventoryComponent,
    InventoryBoxComponent,
    InventoryErrorComponent,
    SortComponent,
    ManageSearchPage,
    SelectionOnSortPage,
    SSParameterComponent,
    CertificationComponent,
    ManageCertificationPage,
    ManageSsParameterPage,
    VoucherBookComponent,
    ManageVoucherBookPage,
    StoneInfoComponent,
    ManageRequestsPage,
    CustomerComponent,
    ManageCustomerPage,
    ConsignmentComponent,
    IssueReceivedComponent,
    StonesWithMeComponent,
    PriceListUploaderComponent,
    TransferStoneComponent,
    TransferToSalesSingleComponent,
    TransferToSalesMixComponent,
    PricingPolicy,
    ManagePolicyGeneratorPage,
    AssortParameterConfigComponent,
    SearchSingleStoneComponent,
    SearchMixStoneComponent,
    PdAssortDetailsPage,
    StoneWithMeSingleComponent,
    StoneWithMeMixComponent,
    PriceNameMasterPage,
    ApplyPolicyComponent,
    ManageRulesPage,
    AssortmentSearchComponent,
    ClearAssortmentStockComponent,
    ExportTemplatesComponent,
    ManageTemplatesPage,
    ProgrammeComponent,
    ManageProgrammePage,
    DefaultTemplatesComponent,
    PolicyTrackerComponent,
    PDComponent,
    FixedPolicyMatrixPage,
    StatusMasterComponent,
    ManageStatusPage,
    ParameterMappingMasterComponent,
    ManageParameterMappingMasterPage,
    UpdateParameterComponent,
    CustomerConfirmComponent,
    MemoServiceComponent,
    ConsignmentConfirmComponent,
    ConsignmentInStockComponent,
    MemoClientComponent,
    LabExpensesComponent,
    ExternalInventoryComponent,
    RecutComponent,
    ObservationComponent,
    PricingSizeMasterComponent,
    ManagePricingSizeMasterPage,
    ConversionRateMasterComponent,
    ManageConversionRateMasterPage,
    LabConversionRateMasterComponent,
    ViewComponent,
    CompanyComponent,
    ManageCompanyPage,
    UpdateRfidComponent,
    DeliveryChallanComponent,
    ManageExternalInventoryStonesPage,
    GeneratePOComponent,
    InterCSSPTransferComponent,
    AuditCSSPComponent,
    ApprovalMemoComponent,
    InterBoxTransferPage,
    BoxTransferComponent,
    SingleToMixComponent,
    SingleToMixTransferComponent,
    UploadBulkToSinglePage,
    MixToSingleTransferComponent,
    PurchaseHistoryComponent,
    RapaportMappingComponent,
    RapaportParameterMappingMasterPage,
    ManageFilterComponent,
    TermMasterComponent,
    ManageTermMasterPage,
    RapaportPriceListUploaderComponent,
    PolicyChainComponent,
    ManagePolicyChainPage,
    MixToSingleComponent,
    PurchaseToSingleComponent,
    PurchaseInwardComponent,
    ManagePurchaseInwardPage,
    SalesInventoryComponent,
    SalesInventoryComponent,
    PartyParameterMappingComponent,
    PurchaseToMixComponent,
    UpdateStoneIdComponent,
    InventoryManagerSSComponent,
    InventoryManagerMIXComponent,
    UpdateBarcodeComponent,
    CustomerHoldComponent,
    CsvDownloadHistoryPage,
    LabServicesComponent,
    ManageLabServicesPage,
    CsspTransferMappingComponent,
    ManageCsspTransferMappingPage,
    ManageInterCsspTransferPage,
    StoneInfoScrollComponent,
    SingleMixParamMappingComponent,
    PendingApprovalComponent,
    RequestApprovalOfOutPlatformComponent,
    ManageRequestApprovalPage,
    UpdateKYCApprovalComponent,
    ManagePendingCustomerApprovalPage,
    WebsiteOfferComponent,
    ManageOfferDetailsPage,
    RequestFormComponent,
    ReportsComponent,
    RecutInwardComponent,
    StoneFinalizationComponent,
    ElasticSearchComponent,
    StoneTrackingComponent,
    PricingCalcComponent,
    MixPricingCalcComponent,
    DevExtremeDataGridComponent,
    SkipAssortmentCriteriaComponent,
    ManageSkipAssortmentPage,
    EmailToCustomerPage,
    PdFiltersComponent,
    SoldComponent,
    StockUploadComponent,
    DevExtremeWithPaginationComponent,
    ScanBoxErrorManagementComponent,
    UpdatePDFileUploadComponent,
    UpdateMyScannerBoardComponent,
    LabPacketServiceMasterComponent,
    ManageLabPacketServiceMasterPage,
    FtpMasterComponent,
    ManageFtpMasterPage,
    SelectSchedulerTimePage,
    StickerPrintingComponent,
    UploadSingleToMixPage,
    StickerPrintingOptionsPage,
    SoldSearchComponent,
    MarkAsSoldComponent,
    ViewScanResultPage,
    UploadRecutRulesComponent,
    ProgramManagementPage,
    FinalizePPriceComponent,
    FinalizePPriceComponent,
    DiamondSecurityCheckComponent,
    PurchaseBulkUploaderComponent,
    UploadInternalLabResultsComponent,
    PricingHistoryComponent,
    UploadAssortmentPage,
    PurchaseSearchComponent,
    EnquiryMasterComponent,
    ManageEnquiryMasterComponent,
    CvdCheckComponent,
    UpdateAvailableForSalesComponent,
    PurchaseConsolidationIncomingstonesComponent,
    ReceiveincomingstonesComponent,
    PurchaseconsolidationReturnStonesComponent,
    PurchaseUpdatetermsandbankratesComponent,
    AccordionComponent,
    AccordionItemComponent,
    PurchaseUpdateInvoiceNoAndSeqNoComponent,
    PurchaseconsolidationUpdateparameterviewComponent,
    ReturningpurchasestonemodalComponent,
    UpdateElasticDataComponent,
    DesignbybudgetComponent,
    PdPurchaseStonesComponent,
    PricingLogsComponent,
    SvcActionsPage,
    StoneGenerationComponent,
    StoneGenerationbuyinglistComponent,
    StonegenerationbuyingpurposeComponent,
    StonegenerationbuyingpurposeComponent,
    RapnetStockUploadComponent,
    ProcessRuleConfigComponent,
    ManageProcessRuleConfigComponent,
    SvcSearchComponent,
    OtherOfficeStockProcessComponent,
    SvcRequestComponent,
    LastTrayComponent,
    WebsiteReportsComponent,
    ManufacturingSearchComponent,
    InspectionViewingComponent,
    BlockForSaleComponent,
    BlockForSaleRequestFormPage,
    StickerPrintNewPage,
    LiveInventoryComponent,
    WebsiteUsersComponent,
    SalesComparisonReportComponent,
    WebsiteLastSigninDetailComponent,
    BatchpartystockuploaderComponent,
    SalesDashboardComponent,
    SyncOtherOfficeStockComponent,
    SvcHoldSearchComponent,
    LabResultUploadForOtherOfficeComponent,
    AshokaTextFileComponent,
    HoldReportComponent,
    PolishPurchaseInwardReportComponent,
    CreateWebsiteUserComponent,
    PositionReportComponent,
    ManualSalesUploadComponent,
    EsRefinementListComponent,
    PlanandsalesstonesComponent,
    LayoutsComponent,
    ManageLayoutsComponent,
    LogisticsApprovalComponent,
    CurrentParameterSearchComponent,
    InternationalCodesComponent,
    ProgramRejectionComponent,
    QueuedActionComponent,
    PotentialBuyersComponent,
    StoneOfferCaptureComponent,
    RfidHistoryComponent,
    StoneSelectionRejectionComponent,
    StonePriceTrackingComponent,
    ScanViaRFIDComponent,
    GlobalSVCSearchComponent,
    CapitalCasePipe,
    UploadManualPricingComponent,
    PotentialBuyersSelectionSectionComponent,
    PotentialBuyersDataSectionComponent,
    BtnGroupHorizontalComponent,
    PotentialBuyersDataCardComponent,
    PotentialBuyersStoneSelectionPopupComponent,
    WebsiteConfigComponent,
    BulletinComponent,
  ],
  entryComponents: [
    RolesComponent,
    SearchComponent,
    ManageRolesPage,
    StoneInwardComponent,
    ManageActionComponent,
    UserComponent,
    ManageUserPage,
    UploadStoneComponent,
    ManageUploadStonePage,
    MasterComponent,
    ManageMasterPage,
    ManageStoneInwardPage,
    AssortmentComponent,
    ManageAssortmentPage,
    InventoryManagerComponent,
    ManageInventoryManagerPage,
    BoxMasterComponent,
    ManageBoxMasterPage,
    ScanRFIDPage,
    TransferToManufactureComponent,
    InventoryComponent,
    InventoryBoxComponent,
    SortComponent,
    ManageSearchPage,
    SelectionOnSortPage,
    SSParameterComponent,
    CertificationComponent,
    ManageCertificationPage,
    ManageSsParameterPage,
    VoucherBookComponent,
    ManageVoucherBookPage,
    ManageRequestsPage,
    CustomerComponent,
    ManageCustomerPage,
    ConsignmentComponent,
    IssueReceivedComponent,
    StonesWithMeComponent,
    PriceListUploaderComponent,
    TransferStoneComponent,
    TransferToSalesSingleComponent,
    TransferToSalesMixComponent,
    PricingPolicy,
    ManagePolicyGeneratorPage,
    AssortParameterConfigComponent,
    SearchSingleStoneComponent,
    SearchMixStoneComponent,
    PdAssortDetailsPage,
    StoneWithMeSingleComponent,
    StoneWithMeMixComponent,
    PriceNameMasterPage,
    ApplyPolicyComponent,
    ManageRulesPage,
    AssortmentSearchComponent,
    ClearAssortmentStockComponent,
    ExportTemplatesComponent,
    ManageTemplatesPage,
    ProgrammeComponent,
    ManageProgrammePage,
    DefaultTemplatesComponent,
    PolicyTrackerComponent,
    PDComponent,
    FixedPolicyMatrixPage,
    StatusMasterComponent,
    ManageStatusPage,
    ParameterMappingMasterComponent,
    ManageParameterMappingMasterPage,
    UpdateParameterComponent,
    CustomerConfirmComponent,
    MemoServiceComponent,
    ConsignmentConfirmComponent,
    ConsignmentInStockComponent,
    MemoClientComponent,
    LabExpensesComponent,
    ExternalInventoryComponent,
    RecutComponent,
    ObservationComponent,
    PricingSizeMasterComponent,
    ManagePricingSizeMasterPage,
    ConversionRateMasterComponent,
    ManageConversionRateMasterPage,
    LabConversionRateMasterComponent,
    ViewComponent,
    CompanyComponent,
    ManageCompanyPage,
    UpdateRfidComponent,
    DeliveryChallanComponent,
    ManageExternalInventoryStonesPage,
    GeneratePOComponent,
    InterCSSPTransferComponent,
    AuditCSSPComponent,
    ApprovalMemoComponent,
    InterBoxTransferPage,
    BoxTransferComponent,
    SingleToMixComponent,
    SingleToMixTransferComponent,
    UploadBulkToSinglePage,
    MixToSingleTransferComponent,
    PurchaseHistoryComponent,
    RapaportMappingComponent,
    RapaportParameterMappingMasterPage,
    ManageFilterComponent,
    TermMasterComponent,
    ManageTermMasterPage,
    RapaportPriceListUploaderComponent,
    PolicyChainComponent,
    ManagePolicyChainPage,
    MixToSingleComponent,
    PurchaseToSingleComponent,
    PurchaseInwardComponent,
    ManagePurchaseInwardPage,
    SalesInventoryComponent,
    PurchaseToMixComponent,
    UpdateStoneIdComponent,
    InventoryManagerSSComponent,
    InventoryManagerMIXComponent,
    UpdateBarcodeComponent,
    CustomerHoldComponent,
    CsvDownloadHistoryPage,
    LabServicesComponent,
    ManageLabServicesPage,
    CsspTransferMappingComponent,
    ManageCsspTransferMappingPage,
    ManageInterCsspTransferPage,
    SingleMixParamMappingComponent,
    PendingApprovalComponent,
    RequestApprovalOfOutPlatformComponent,
    ManageRequestApprovalPage,
    UpdateKYCApprovalComponent,
    ManagePendingCustomerApprovalPage,
    WebsiteOfferComponent,
    ManageOfferDetailsPage,
    RequestFormComponent,
    ReportsComponent,
    RecutInwardComponent,
    StoneFinalizationComponent,
    ElasticSearchComponent,
    StoneTrackingComponent,
    PricingCalcComponent,
    MixPricingCalcComponent,
    DevExtremeDataGridComponent,
    SkipAssortmentCriteriaComponent,
    ManageSkipAssortmentPage,
    EmailToCustomerPage,
    PdFiltersComponent,
    SoldComponent,
    StockUploadComponent,
    ScanBoxErrorManagementComponent,
    UpdatePDFileUploadComponent,
    UpdateMyScannerBoardComponent,
    LabPacketServiceMasterComponent,
    ManageLabPacketServiceMasterPage,
    FtpMasterComponent,
    ManageFtpMasterPage,
    SelectSchedulerTimePage,
    StickerPrintingComponent,
    UploadSingleToMixPage,
    StickerPrintingOptionsPage,
    SoldSearchComponent,
    MarkAsSoldComponent,
    ViewScanResultPage,
    UploadRecutRulesComponent,
    ProgramManagementPage,
    FinalizePPriceComponent,
    DiamondSecurityCheckComponent,
    PurchaseBulkUploaderComponent,
    UploadInternalLabResultsComponent,
    PricingHistoryComponent,
    UploadAssortmentPage,
    PurchaseSearchComponent,
    EnquiryMasterComponent,
    ManageEnquiryMasterComponent,
    CvdCheckComponent,
    UpdateAvailableForSalesComponent,
    PurchaseConsolidationIncomingstonesComponent,
    ReceiveincomingstonesComponent,
    PurchaseconsolidationReturnStonesComponent,
    PurchaseUpdatetermsandbankratesComponent,
    AccordionComponent,
    AccordionItemComponent,
    PurchaseUpdateInvoiceNoAndSeqNoComponent,
    PurchaseconsolidationUpdateparameterviewComponent,
    ReturningpurchasestonemodalComponent,
    UpdateElasticDataComponent,
    DesignbybudgetComponent,
    PdPurchaseStonesComponent,
    PricingLogsComponent,
    StoneGenerationComponent,
    StoneGenerationbuyinglistComponent,
    StonegenerationbuyingpurposeComponent,
    RapnetStockUploadComponent,
    ProcessRuleConfigComponent,
    ManageProcessRuleConfigComponent,
    SvcSearchComponent,
    OtherOfficeStockProcessComponent,
    SvcActionsPage,
    SvcRequestComponent,
    LastTrayComponent,
    WebsiteReportsComponent,
    ManufacturingSearchComponent,
    InspectionViewingComponent,
    BlockForSaleComponent,
    BlockForSaleRequestFormPage,
    StickerPrintNewPage,
    LiveInventoryComponent,
    WebsiteUsersComponent,
    SalesComparisonReportComponent,
    WebsiteLastSigninDetailComponent,
    BatchpartystockuploaderComponent,
    SalesDashboardComponent,
    SyncOtherOfficeStockComponent,
    SvcHoldSearchComponent,
    LabResultUploadForOtherOfficeComponent,
    AshokaTextFileComponent,
    HoldReportComponent,
    PolishPurchaseInwardReportComponent,
    CreateWebsiteUserComponent,
    PositionReportComponent,
    ManualSalesUploadComponent,
    EsRefinementListComponent,
    PlanandsalesstonesComponent,
    LayoutsComponent,
    ManageLayoutsComponent,
    LogisticsApprovalComponent,
    CurrentParameterSearchComponent,
    InternationalCodesComponent,
    ProgramRejectionComponent,
    QueuedActionComponent,
    PotentialBuyersComponent,
    StoneOfferCaptureComponent,
    RfidHistoryComponent,
    StoneSelectionRejectionComponent,
    StonePriceTrackingComponent,
    ScanViaRFIDComponent,
    GlobalSVCSearchComponent,
    UploadManualPricingComponent,
    PotentialBuyersStoneSelectionPopupComponent,
    WebsiteConfigComponent,
    BulletinComponent
  ],
  providers: [MomentPipe, DragulaService, DecimalPipe, CapitalCasePipe],
})
export class ComponentsModule {}
