import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Events } from "@ionic/angular";
import { DxPivotGridComponent } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import { exportDataGrid } from "devextreme/excel_exporter";
import {
  default as DataGrid,
  default as dxDataGrid,
} from "devextreme/ui/data_grid";
import * as ExcelJS from "exceljs/dist/exceljs.js";
import saveAs from "file-saver";
import * as moment from "moment";

declare const ExcelJS: any;

interface PivotData {
  stoneId: string;
  shape: string;
  sizeName: string;
  price: number;
  shapeId: number;
  totalCts: number;
  colorPriority: number;
  clarityPriority: number;
  amount: number;
  stockCts: number;
  status: string;
  index: number;
}

interface TableData {
  stoneId: string;
  shape: string;
  sizeName: string;
  price: number;
  shapeId: number;
  totalCts: number;
  colorPriority: number;
  clarityPriority: number;
  amount: number;
  stockCtses: number[];
  statuses: string[];
  index: number;
}

@Component({
  selector: "app-stone-info-scroll",
  templateUrl: "./stone-info-scroll.component.html",
  styleUrls: ["./stone-info-scroll.component.scss"],
})
export class StoneInfoScrollComponent implements OnInit {
  @Input() remoteOperations = true;
  dataGridInstance: DataGrid;
  dataGridInstanceDesktop: dxDataGrid;
  @Input() tableMobileId = "gridContainer-mobile";
  @Input() columns: any;
  @Input() mode: string = "single";
  @Input() rowData: any;
  @Input() tab: any;
  @Input() pageNumber: any;
  @Input() mobileView = false;
  @Input() noOfRecords: any;
  @Input() count: any;
  @Input() page: any;
  @Input() priceListName: any;
  @Input() RepnetPlusval: any;
  @Input() noPagination: any;
  @Input() fromDepartment: any;
  @Input() flag: any;
  @Input() boxName: any;
  @Input() availableSpace: any;
  @Input() boxNo: any;
  @Input() totalSummary: any;
  @Input() fileName: string;
  @Input() showTotalSummary: boolean = true;
  @Input() groupTotalSummary: any;
  @Input() showPivotGrid: any;
  @Input() pivotGridDataSource: any;
  pivotGridOptions: any = {
    allowSortingBySummary: false,
    allowSorting: false,
    showBorders: true,
    fieldChooser: {
      enabled: true,
      height: 400,
      layout: "list",
    },
  };
  @Input() viewDetail: any;
  @Input() detailColumns: any;
  @Input() detailRowData: any;
  @Input() detailGroupTotalSummary: any;
  @Input() detailTotalSummary: any;
  @Input() searchPanelEnable: any;
  @Input() exportEnable: any;
  @Input() selected: any = [];
  @Input() noRemoteOperations: any;
  @Input() showIconColumn: any;
  @Input() pageSize: any = 50;
  @Input() showPrice: boolean = false;
  @Input() tableId: string = "gridContainer";
  @Input() refreshMobileGrid = false;
  @Input() showPager: boolean = true;
  @Input() refreshGrid: boolean = false;
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  @Output() pageFunction: EventEmitter<any> = new EventEmitter();
  @Output() openBarcodeFunction: EventEmitter<any> = new EventEmitter();
  @Output() deleteFunction: EventEmitter<any> = new EventEmitter();
  @Output() showData: EventEmitter<any> = new EventEmitter();
  @Output() downloadFileData: EventEmitter<any> = new EventEmitter();
  @Output() showResultOfAssortPD: EventEmitter<any> = new EventEmitter();
  @Output() manageTemplates: EventEmitter<any> = new EventEmitter();
  @Output() manageTemplateFunction: EventEmitter<any> = new EventEmitter();
  @Output() downloadComparisonFileData: EventEmitter<any> = new EventEmitter();
  @Output() scrollFunction: EventEmitter<any> = new EventEmitter();
  @Output() onFocusedCellChanged1: EventEmitter<any> = new EventEmitter();

  @Output() onClickToOpenTag: EventEmitter<any> = new EventEmitter();
  @Output() onClickToOpenVideo: EventEmitter<any> = new EventEmitter();
  @Output() inspectionView = new EventEmitter<boolean>();
  @Output() inspectiondata = new EventEmitter<string>();
  @Output() onClickToOpenMoreParameters: EventEmitter<any> = new EventEmitter();
  @Output() selectStone: EventEmitter<any> = new EventEmitter();
  @Output() selectedStonesForInspection: EventEmitter<any> = new EventEmitter();
  @ViewChild(DxPivotGridComponent) pivotGrid: DxPivotGridComponent;
  public myMesssages = {
    emptyMessage: "No data to display",
    totalMessage: "records",
  };
  public headerHeight = 50;
  public rowHeight = 30;
  public hotSettings;
  public id = "hotInstance";
  public hooks;
  public evaldata;
  public columnName: any;
  public selectedRows = 0;
  public innerWidth: any;
  public val = true;
  public selectedRowKeys = [];
  isSelectionRetriggered: boolean = false;
  preventSelection: boolean = false;
  initialSelectedKeys: number[] = [];
  public totalSelectedSummary: any = {
    cts: 0,
    pcs: 0,
    amount: 0,
  };
  onSelectionChanged_preventSelection: any;
  statuses = ["In Stock"];
  tableData: TableData[] = [];
  tableDataSummery = {
    totalCts: 0,
    amount: 0,
    "In Stock": 0,
  };

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 500) {
      this.mobileView = true;
    } else if (this.innerWidth > 500) {
      this.mobileView = false;
    }
  }

  constructor(public event: Events, private el: ElementRef) {
    this.inspectionIconClick = this.inspectionIconClick.bind(this);
    this.tagIconClick = this.tagIconClick.bind(this);
    this.parameterIconClick = this.parameterIconClick.bind(this);
  }

  ngOnChanges(changes) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 500) {
      this.mobileView = true;
    } else if (this.innerWidth > 500) {
      this.mobileView = false;
    }

    if (!!changes.refreshGrid && changes.refreshGrid.currentValue) {
      this.refresh();
    }
  }

  saveGridInstance(e) {
    this.dataGridInstance = e.component;
  }

  refresh() {
    this.dataGridInstance.clearSelection();
  }

  changePivotGridDataSource(dataSource: PivotData[]) {
    const statusObj = {
      "In Stock": "In Stock",
    };
    const stoneIdObj = {};
    dataSource.forEach((data: PivotData) => {
      if (!statusObj.hasOwnProperty(data.status)) {
        statusObj[data.status] = data.status;
        this.statuses.push(data.status);
        this.tableDataSummery[data.status] = 0;
      }
    });

    dataSource.forEach((data: PivotData) => {
      this.tableDataSummery.amount += data.amount;
      this.tableDataSummery.totalCts += data.totalCts;
      this.tableDataSummery[data.status] += data.stockCts;

      const obj: TableData = {
        stoneId: data.stoneId,
        shape: data.shape,
        sizeName: data.sizeName,
        price: data.price,
        shapeId: data.shapeId,
        totalCts: data.totalCts,
        colorPriority: data.colorPriority,
        clarityPriority: data.clarityPriority,
        amount: data.amount,
        stockCtses: new Array(this.statuses.length).fill(0),
        statuses: new Array(this.statuses.length).fill("-"),
        index: data.index,
      };

      if (!stoneIdObj.hasOwnProperty(data.stoneId)) {
        stoneIdObj[data.stoneId] = data.stoneId;
        const idx = this.statuses.indexOf(data.status);
        obj.stockCtses[idx] = data.stockCts;
        obj.statuses[idx] = data.status;
        obj.index = this.tableData.length;

        this.tableData.push(obj);
      } else {
        const index = this.tableData.findIndex(
          (tData: TableData) => tData.stoneId === data.stoneId
        );
        const idx = this.statuses.indexOf(data.status);
        this.tableData[index].stockCtses[idx] += data.stockCts;
        this.tableData[index].statuses[idx] = data.status;
        this.tableData[index].amount += data.amount;
        this.tableData[index].totalCts += data.totalCts;
      }
    });
  }

  ngOnInit() {
    console.log("pivotGridDataSource", this.pivotGridDataSource);
    if (!!this.pivotGridDataSource) {
      this.changePivotGridDataSource(this.pivotGridDataSource.store);
    }

    // let modifiedColumns = [];
    // this.columns.filter(col=>{
    //   let cols : any ={};
    //   cols.headerName = col.name;
    //   cols.field = col.prop;
    //   cols.filter= 'agSetColumnFilter';
    //   modifiedColumns.push(cols);
    // });
    // this.columns = modifiedColumns;

    if (this.page == "inManagerPage") {
      this.event.subscribe("availableSpace", (params) => {
        this.availableSpace = params.availableSpace;
        this.boxNo = params.boxNo;
      });
    }
    this.hotSettings = {
      startRows: 5,
      colHeaders: true,
      rowHeaders: true,
      colWidths: 200,
      columnSorting: true,
      manualColumnResize: true,
      renderAllRows: true,
      filters: true,
      dropdownMenu: true,
      // dropdownMenu: ['filter_by_condition', 'filter_action_bar'],
      selectionMode: "single",
      outsideClickDeselects: false,
      stretchH: "all",
      width: "100%",
      height: "100%",
    };
    console.log("*************", this.rowData, this.page, this.flag, this.tab);
    // let instance = this.hotTableRegisterer.getInstance(this.id);
    // console.log("instance ",instance);
    // this.hooks = instance.runHooks( "afterSelection");
    // console.log("hooks ", this.hooks);
    // this.hotSettings[this.hooks] = function(r,c) {
    //   this.afterSelectionFunc(r,c);
    // }
  }

  call(event) {
    console.log("event ", event);
    this.selected = event.row;
    this.selectedStonesForInspection.emit(event.selectedRowsData);
    this.event.publish("selectedValue", this.selected);
    console.log("selected", this.selected);
    this.setSelectedRows();

    if (event.type == "click") {
      if (event.column.name != "Barcode") {
        this.callFunction.emit(event);
      }
    } else {
      this.callFunction.emit(event);
    }
  }

  reportCertDate(data) {
    if (!!data["Cert Date"]) {
      return moment(data["Cert Date"]).local().format("D MMM YYYY");
    }
  }

  customDateTime(data) {
    if (!!data.inwardDate) {
      return moment(data.inwardDate).local().format("DD-MMM-YYYY hh:mm a");
    }

    if (!!data.invoiceDate) {
      return moment(data.invoiceDate).local().format("DD-MMM-YYYY hh:mm a");
    }

    if (!!data.issueDate) {
      return moment(data.invoiceDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.assortDate) {
      return moment(data.assortDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data["Cert Date"]) {
      return moment(data["Cert Date"]).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.factoryOutWDate) {
      return moment(data.factoryOutWDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.inwDate) {
      return moment(data.inwDate).local().format("DD-MMM-YYYY");
    }
  }

  accInvoiceDate(data) {
    if (!!data.invoiceDate) {
      return moment(data.invoiceDate).local().format("DD-MMM-YYYY");
    } else if (!!data.voucherDate) {
      return moment(data.voucherDate).local().format("DD-MMM-YYYY");
    }
  }

  FACTORY_OUTW_DATE(data) {
    if (!!data.factoryOutWDate) {
      return moment(data.factoryOutWDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  factOutDateTime(data) {
    if (!!data.factoryOutWDate) {
      return moment(data.factoryOutWDate).local().format("DD-MMM-YYYY");
    }
  }

  inwardDateFun(data) {
    if (!!data.inwardDate) {
      return moment(data.inwardDate).local().format("DD-MMM-YYYY");
    }
    if (!!data.inwDate) {
      return moment(data.inwDate).local().format("DD-MMM-YYYY");
    }
  }
  customFctOutWDateTime(data) {
    if (!!data.factoryOutWDate) {
      return moment(data.factoryOutWDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }
  reportDateTime(data) {
    if (!!data.ReportDt) {
      return moment(data.ReportDt).local().format("DD-MMM-YYYY");
    }
  }
  certDateTime(data) {
    if (!!data.CertDate) {
      let d = moment(data.CertDate).local().format("DD-MMM-YYYY");
      if (d == "Invalid date") {
        return data.CertDate;
      } else {
        return d;
      }
    }
  }

  customIssueDateTime(data) {
    if (!!data.issueDate) {
      return moment.utc(data.issueDate).format("DD-MMM-YYYY hh:mm a"); //moment(data.issueDate).local().format('DD-MMM-YYYY hh:mm a');
    }
    if (!!data.transactionDate) {
      return moment(data.transactionDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  afterSelectionFunc = (
    row,
    column,
    row2,
    column2,
    preventScrolling,
    selectionLayerLevel
  ) => {
    console.log(
      "afterSelection ",
      row,
      column,
      row2,
      column2,
      preventScrolling,
      selectionLayerLevel
    );
    let r = this.rowData[row];
    let event: any = {};
    event.row = r;
    this.call(event);
  };
  paginationFunction(event) {
    this.pageFunction.emit(event);
  }
  delete(row) {
    this.deleteFunction.emit(row);
  }
  showStoneId(data) {
    let stoneId = data.value;
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split("/", 3).join("/").length;
      return stoneId
        .split(stoneId.substring(index + 1, stoneId.length))
        .join(
          stoneId
            .substring(index + 1, stoneId.length)
            .slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))
        );
    } else {
      return stoneId;
    }
  }

  showParty(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"));
    } else {
      return stoneId;
    }
  }

  showDate(value) {
    if (!!value) {
      return moment.utc(value).local().format("DD-MM-YYYY");
    }
  }

  beforeOnCellMouseDownFunc = (event, coords, element) => {
    console.log("beforeOnCellMouseDownFunc ", event, coords, element);
    if (coords.row < 0) {
      event.stopImmediatePropagation();
    }
  };

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Employees");

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-xlsx
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "DataGrid.xlsx"
        );
      });
    });
    e.cancel = true;
  }

  customizeSum(data) {
    if (!!data.value && typeof data.value == "number") {
      return data.value.toFixed(3);
    } else {
      return data.value;
    }
  }

  customizeSumOfPcs(data) {
    return data.value;
  }

  // refresh() {

  //   if (!!this.dataGridInstance) {
  //     this.totalSelectedSummary = {
  //       cts: 0,
  //       pcs: 0,
  //       amount: 0,
  //     };
  //     this.dataGridInstance.clearSelection();
  //   }
  // }

  onSelectionChanged(event) {
    event.row = event.selectedRowsData[0];
    event.columnName = this.columnName;
    console.log("onSelectionChanged event ", event);
    this.selectedRows = event.selectedRowsData.length;
    this.call(event);
  }

  onSelectionChanged1(e) {
    this.onSelectionChanged1_preventSelection(e);
  }

  onCellClick_preventSelection = (e) => {
    console.log(e);
    if (e.column) {
      if (
        e.column.dataField === "Req Discount" ||
        e.column.dataField == "Req Price" ||
        e.column.dataField == "Amount"
      ) {
        this.initialSelectedKeys = e.component.getSelectedRowKeys();
        this.preventSelection = true;
      } else {
        this.columnName = e.column.name;
        this.preventSelection = false;
      }
    }
  };

  onSelectionChanged1_preventSelection = (e) => {
    if (this.preventSelection === true && !this.isSelectionRetriggered) {
      // Revert selection state
      this.undoSelectRows(e.currentSelectedRowKeys);

      if (this.initialSelectedKeys.length >= 1) {
        this.undoDeselectRows(e.currentDeselectedRowKeys);
      }
      this.preventSelection = false;
    } else {
      e.row = e.selectedRowsData[0];
      e.columnName = this.columnName;
      console.log("onSelectionChanged event ", e);
      this.selectedRows = e.selectedRowsData.length;
      this.totalSelectedSummary = {
        cts: 0,
        pcs: 0,
        amount: 0,
      };
      for (let i = 0; i < e.selectedRowsData.length; i++) {
        let raw = e.selectedRowsData[i];

        this.totalSelectedSummary["cts"] += raw["cts"];
        this.totalSelectedSummary["pcs"] = e.selectedRowsData.length;
        this.totalSelectedSummary["amount"] += parseFloat(raw["Rapnet_plus"]);

        // this.totalSelectedSummary["amount"] += raw[]

        // if (!!companyJson.externalProduct.kgAppliedAmount) {
        //   let kgPricing = companyJson.externalProduct.kgAppliedAmount;
        //   this.totalSelectedSummary["amount"] += raw[kgPricing];
        // }
      }
      // console.log(this.RepnetPlusval, 'sss')
      // debugger
      // this.columnName = e.column.caption;

      // if (e.rowType != "data")
      //     return;

      // if (e.eventType == "dxclick") {
      //     let selectedCol = this.columns[e.columnIndex - 1];
      //     if (!!selectedCol && !!selectedCol.showPopup && selectedCol.type == 'string') {
      //         // // console.log("onCellHoverChanged ",e);
      //         this.popOverFunction.emit(e);
      //     }
      // }
      this.call(e);
    }
  };

  customVoucher(data) {
    return data.shortCode + "-" + data.bookSerialNumber;
  }

  parameterIconClick(event) {
    this.onClickToOpenMoreParameters.emit(event);
  }

  tagIconClick(event) {
    this.onClickToOpenTag.emit(event);
  }

  inspectionIconClick(event) {
    this.onClickToOpenVideo.emit(event);
  }

  onCellClick(ev) {
    if (!!ev) {
      this.columnName = ev.column.caption;
    }
  }

  calculatePcs(data) {
    return parseInt(data.totalPkts);
  }

  saveMobileGridInstance(e) {
    if (!this.dataGridInstance) {
      this.dataGridInstance = e.component;
    }
  }

  setSelectedRows() {
    //console.log(this.selected)
    if (this.selected && this.selected.length > 0) {
      this.refreshGrid = false;
      this.refreshMobileGrid = false;
      this.selectedRowKeys = this.selected.map((r) => r.stoneName);
    }
  }

  undoSelectRows = (selectedRowKeys) => {
    this.isSelectionRetriggered = true;
    this.dataGridInstanceDesktop.deselectRows(selectedRowKeys);
    this.isSelectionRetriggered = false;
  };

  undoDeselectRows = (deselectedRowKeys) => {
    this.isSelectionRetriggered = true;
    this.dataGridInstanceDesktop.selectRows(deselectedRowKeys, true); // 2nd parameter is set to "true" to preserve the other selected rows
    this.isSelectionRetriggered = false;
  };

  singleProduct(e) {
    console.log(e);
    let columnIndex = 0;
    if (!!e.columnIndex) {
      columnIndex = e.columnIndex;
    } else {
      if (!!e.columns) {
        columnIndex = e.columns[1]["index"];
      }
    }

    let i = columnIndex - 1;
    if (i == -1) {
      i = 0;
    }

    let selectedCol = this.columns[i];
    // console.log(selectedCol, "This is SelectedCol");
    this.evaldata = e.data;
    this.inspectionView.emit(this.val);
    this.inspectiondata.emit(this.evaldata);
    // console.log(e.data, "this is going to show me OrderId")
  }
  onFocusedCellChanged(e: any) {
    this.onFocusedCellChanged1.emit(e);
  }

  PPriceDateFun(data) {
    if (!!data["PPriceDate"]) {
      return moment.utc(data["PPriceDate"]).local().format("DD-MM-YYYY");
    }
  }
}
