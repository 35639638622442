import { Component, ViewChild, OnInit, Input, ElementRef } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { SearchService } from "src/app/services/search/search.service";
import { ProcessActionComponent } from "../../processAction";
import { IonicSelectableComponent } from "ionic-selectable";
import readXlsxFile from 'read-excel-file';
import { ExportAsExcelService } from "src/app/services/export-as-excel.service";
import { DecimalPipe, formatNumber } from "@angular/common";
import { ManageCertificationPage } from "../manage-certification/manage-certification.page";

@Component({
  selector: 'app-mark-as-sold',
  templateUrl: './mark-as-sold.component.html',
  styleUrls: ['./mark-as-sold.component.scss']
})
export class MarkAsSoldComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild('myInput') inputFileVariable: ElementRef;
  @ViewChild('selectableComponent') selectableComponent: IonicSelectableComponent;
  @ViewChild('selectableRefPersonComponent') selectableRefPersonComponent: IonicSelectableComponent;

  @ViewChild('priceFile')
  myInputVariable: ElementRef;

  public page = 'MarkAsSold';
  public stoneIds: any // 'N0106495\nN0106494\nN0106761\nN0106785\nN0106999';
  public columns: any = [];
  public actionIdList = [];
  public noOfRecords = 10;
  public stonesList = [];
  public allStonesList = []
  public invoiceNo;
  public showFilter: boolean = false;
  public selected: any = [];
  public totalPcs = 0;
  public totalCts = 0;
  public totalAmount = 0;
  public totalCtsVal: any
  public refreshGrid: boolean = false;
  public recalculteSummary: boolean = false;
  public selectedParty = '';
  public customerslist = [];
  public listOfTransType = [];
  public selectedTransType = [];
  public editCts: boolean = false;
  public partyName: any;
  public pageNumber = 0;
  public isNext = true;
  public userData: any;
  public voucherIds = [];
  public selectedVoucherId = [];
  public voucherIdsSelected = [];
  public currencyMasterId: any;
  public location: any;
  public disableCurrencyRate: boolean = false;
  public selectedRefCustomer: any;
  public addSrNo = null;
  public dataLoaded = false;
  public showParty = false;
  public sumOfJangadAmt = 0;
  public sumOfRsAmt = 0;
  public selectedFile: File = null;
  public totalSummary = [
    {
      "fieldName": "memoAmt",
      "summaryType": "custom",
      "name": "Memo Amount"
    },
    {
      "fieldName": "stoneCts",
      "summaryType": "custom",
      "name": "Cts"
    },
    {
      "fieldName": "offerPrice",
      "summaryType": "avg",
      "name": "Offer Price"
    },
    {
      "fieldName": "requestPrice",
      "summaryType": "custom",
      "name": "Req Price"
    },
    {
      "fieldName": "amount",
      "summaryType": "custom",
      "name": "Amount"
    }
  ]
  public listOfActions = [
    // {
    //   id: 1,
    //   action: 'Memo Client',
    // },
    {
      id: 2,
      action: "Customer Confirm",
    },
    {
      id: 3,
      action: 'Consignment Confirm',
    },
    {
      id: 4,
      action: 'Consignment in Stock',
    }
  ]
  public listOfMasterActions = [];
  public selectedAction: any;
  public listOfCustomers: any;
  public selectedCustomerList = []
  public selectedCustomer: any;
  public invoiceDate = null;
  public customerId: any;
  public transTypeId: any;
  public requestUserArray: any = [];
  public transTypeArray: any = [];
  public businessTypeArray: any = [];
  public brokerIdArray: any = [];
  public termsArray: any = [];
  public customerReferenceArray: any = [];
  public currencyArray: any = [];
  public locationArray: any = [];
  public exgRateArray: any = [];
  public voucherIdArray: any = [];
  public salesPerson: any;
  public kamPersonList: any;
  public kamIdList = [];
  public refCustomerList: any = [];
  public selectedStoneList = [];
  public businessTypeList = []
  public businessType: any;
  public businessTypeIdList = []
  public termList = [];
  public selectedTermList = [];
  public termName: any;
  public discountPremium: any;
  public transType: any;
  public voucherBookId: any;
  public accuInvoiceNo: any;
  public refPartyList = []
  public customerReferenceId: any;
  public refCustomerName: any;
  public refDescription: any;
  public listOfAddress = [];
  public selectedAddressIdList = [];
  public selectedAddress: any;
  public currencyRate: any;
  public totalExchangeRate = 0
  public jangadNoList = [];
  public selectedjangadNo: any;
  public currencyIdList = []
  public currencyList = []
  public selectedCurrency: any;
  public defaultCurrency: any;
  public brokerList = [];
  public selectedBrokerList = []
  public brokerId: any;
  public disc1: any = 0;
  public disc2: any = 0;
  public disc1Array = [];
  public disc2Array = [];

  constructor(
    private es: SearchService,
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService,
    public _decimalPipe: DecimalPipe

  ) {


  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfTransType = data.data;
    }
  }


  async offerValueUpdate(event) {
    this.updatePrice(event.value, event.column.dataField, event.rowIndex)
  }

  async ctsValueUpdate(event) {
    this.updatePrice(event.value, event.column.dataField, event.rowIndex)
  }


  //update price in cart
  async updatePrice(value, cell, rowIndex) {
    this.stonesList[rowIndex][cell] = parseFloat(value);

    this.stonesList = [...this.stonesList];
    // this.editing[rowIndex + '-' + cell] = false;
    let errorPrice = 'errorPrice'
    if (!!this.stonesList[rowIndex][cell] && this.stonesList[rowIndex][cell] > 0
    ) {
      this.isNext = true;
      this.stonesList[rowIndex][errorPrice] = false;
      let cts = Number(this.stonesList[rowIndex].stoneCts)

      if (cell == 'offerCts') {
        cts = Number(this.stonesList[rowIndex][cell])
      }
      let amt = cts * Number(this.stonesList[rowIndex].offerPrice)
      // let amt1 : number = parseFloat( amt)
      this.stonesList[rowIndex]['amount'] = Number(amt).toFixed(2);
    } else {
      this.stonesList[rowIndex][errorPrice] = true;

      this.stonesList[rowIndex][cell] = 0;
    }
    let flag: boolean;
    // this.stonesList.forEach(item => {
    //   if (parseFloat(item.offerPrice) == null || parseFloat(item.offerPrice) == undefined ||
    //     parseFloat(item.offerPrice) <= 0) {
    //     this.isNext = false;
    //   }
    // })
    // this.issueStoneList.forEach(item => {
    //   if (parseFloat(item.offerPrice) > 0 && item.size != "") {
    //     flag = true;
    //     this.isNext = true;
    //   }
    // })
    // if (flag == false || flag == undefined) {
    //   this.isNext = false;
    // }
  }

  async OnSelectRows(event) {
    this.isNext = true
    this.selected = event.selectedRowsData
    this.selectedStoneList = [];
    this.totalPcs = 0;
    this.totalCts = 0;
    this.totalAmount = 0
    this.selected.forEach(item => {
      let cts;
      if (this.editCts) {
        cts = item.offerCts;
      } else {
        cts = item.stoneCts;
      }

      this.totalPcs++;
      this.totalCts += parseFloat(cts);
      this.totalCts = Number(this.totalCts.toFixed(3))
      this.totalAmount += cts * item.offerPrice
      this.totalAmount = Number(this.totalAmount.toFixed(3))
      //this.totalCtsVal = this.totalCts.toFixed(2);
      this.selectedStoneList.push(item.stoneId)
      if (parseFloat(item.offerPrice) == null || parseFloat(item.offerPrice) == undefined ||
        parseFloat(item.offerPrice) <= 0) {
        this.isNext = false;
      }
    })
  }

  async getAllCurrency() {
    this.currencyIdList = [];
    let res: any;
    res = await this.httpApiService.getCurrencyList();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.currencyList = data.data;
      if (this.currencyList.length > 0) {
        this.currencyList.filter(el => {
          if (el.isDefault) {
            this.currencyRate = 0;
            this.currencyIdList = []
            this.currencyMasterId = el.id
            this.currencyIdList.push({ id: el.id, name: el.name })
            this.defaultCurrency = el.id;
            // this.getLatestRateByCurrencyName(el.name)
          }
        })
      }
    }
  }

  async getAllCustomer(action) {
    let res: any;
    res = await this.httpApiService.findAllPartyByAction(action.trim(), null, null);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfCustomers = data.data;
    }
  }

  async getAddressesByPartyId(id) {
    let res: any;
    res = await this.httpApiService.getAddressesByPartyId(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfAddress = data.data.filter(el => !!el.isActive)
    }
  }

  async getCustomerRefParty(pageNumber, noOfRecords, searchText) {
    let res: any;
    res = await this.httpApiService.findRefCustomerPagination(pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.refPartyList = data.data.content;
    }
  }

  async searchRefCustomer(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getCustomerRefParty(this.pageNumber, this.noOfRecords, searchText);
  }

  onChangeRefCustomer(event: { component: IonicSelectableComponent; value: any }) {
    console.log("onChangeRefCustomer:", event.value);
    if (!!event.value) {
      this.customerReferenceId = event.value.id
    } else {
      this.customerReferenceId = '';
    }
  }

  onAddRefName(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.refCustomerName = '';
    this.refDescription = '';
    // Show form.
    event.component.showAddItemTemplate();
  }

  async createRefCustomer() {
    let res: any;
    if (!!this.refCustomerName) {
      let jsonObj = {
        name: this.refCustomerName,
        description: this.refDescription,
      }
      await this.configService.showLoader();
      res = await this.httpApiService.createRefCustomer(jsonObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.selectableRefPersonComponent.hideAddItemTemplate();
        this.getCustomerRefParty(this.pageNumber = 0, this.noOfRecords, '')
      }

    } else {
      await this.configService.showToast('error', 'Please add name')
    }
  }

  async validateExRate(ev) {
    if (ev && ev.detail.value < 0) {
      await this.configService.showToast('error', 'Please add proper value')
    } else {
      this.stonesList = this.stonesList.map(x => {
        let obj = {
          ...x,
          exgRate: ev.detail.value
        }
        return obj
      })
      //console.log(this.stonesList )
    }
  }

  async dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    if (property == 'actionName') {
      if (idList.length > 0) {
        this.listOfCustomers = []
        this.selectedCustomerList = []
        this.selectedAction = obj[0].action
        if (this.selectedAction) {
          this.getAllCustomer(this.selectedAction);
        }
        this.accuInvoiceNo = '';
        this.selectedTransType = [];
        this.stonesList = []
        this.totalCts = 0;
        this.totalPcs = 0;
        this.totalAmount = 0;
        this.voucherIdArray = [];
        this.voucherIds = [];
        this.showFilter = false;
      } else {
        this.listOfCustomers = []
        this.selectedCustomerList = [];
        this.accuInvoiceNo = '';
        this.selectedTransType = [];
        this.stonesList = []
        this.totalCts = 0;
        this.totalPcs = 0;
        this.totalAmount = 0;
      }
    } else if (property == 'partyName') {
      if (!!ev.idList && ev.idList.length > 0) {
        this.selectedCustomer = obj[0].partyName;
        this.customerId = idList[0]
        this.getAddressesByPartyId(idList[0])
      }
    } else if (property == "transType") {
      if (idList.length > 0) {
        if (obj[0].name == "MIX") {
          this.editCts = true
        } else {
          this.editCts = false
        }
        this.transTypeId = idList[0];
      } else {
        this.editCts = false
        this.transTypeId = null
      }
    } else if (property == 'kamPerson') {
      if (!!ev.idList && ev.idList.length > 0) {
        this.salesPerson = obj[0].fullName;
      }
    } else if (property == 'businessType') {
      if (idList.length > 0) {
        this.businessType = obj[0].name;
      } else {
        this.businessType = null;
      }
    } else if (property == "term") {
      if (idList.length > 0) {
        this.termList.filter(el => {
          if (el.id == idList[0]) {
            this.discountPremium = el.discount
            this.termName = el.name;
          }
        })
      } else {
        this.termName = null;
        this.discountPremium = 0;
      }
    } else if (property == 'voucherName') {
      this.selectedVoucherId = [];
      if (idList.length > 0) {
        this.selectedVoucherId = obj
        let selectedIds = await obj.map(function (item) {
          if (item.id) {
            return item.id
          }
        });
        this.voucherViseStones(selectedIds);
      } else {
        this.stonesList = []
      }
    } else if (property == 'selectedCurrency') {
      if (idList.length > 0) {
        this.currencyList.filter(el => {
          if (el.id == idList[0]) {
            this.currencyRate = 0;
          }
        })
        this.currencyMasterId = idList[0];
        if (obj[0].name == "US Dollar") {
          this.disableCurrencyRate = true;
          this.currencyRate = 0;
        }
        this.selectedCurrency = obj[0].name;
        // this.getLatestRateByCurrencyName(obj[0].name)
      } else {
        this.currencyMasterId = '';
      }
    } else if (property == 'selectedBroker') {
      if (idList.length > 0) {
        this.brokerId = idList[0];
      } else {
        this.brokerId = null;
      }
    } else if (property == 'address') {
      if (idList.length > 0) {
        this.location = obj[0].address;
        this.listOfAddress.filter(el => {
          if (el.id == idList[0]) {
            this.addSrNo = !!el.addSrNo ? el.addSrNo : null;
          }
        })
      } else {
        this.location = null;
        this.addSrNo = null;
      }
    }
  }

  async voucherViseStones(selectedIds) {
    this.stonesList = [];
    this.stonesList = await this.allStonesList.filter(function (v, i) {
      if (selectedIds.indexOf(v.voucherId) > -1) {
        return v;
      }
    });
    this.addInputFormsData()
  }

  // async getAllCustomer(name, pageNumber, noOfRecords) {
  //   let res: any;
  //   if (!!name) {
  //     this.pageNumber = 0;
  //   }
  //   res = await this.httpApiService.getAllCustomerBypage(name, pageNumber, noOfRecords);
  //   let data = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.customerslist = data.data.content;
  //   }
  // }

  async getBrokerDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.brokerList = data.data;
      }
    }
  }

  async ngOnInit() {
    await this.getUserData();
    await this.getAllActions();
    await this.getDepartmentWiseKamPersonList()
    await this.getAllCurrency()
    await this.getBrokerDetail('Broker');
    await this.getAllTransType();
    this.pageNumber = 0;
    await this.getCustomerRefParty(this.pageNumber, this.noOfRecords, "");
    this.businessTypeList = await this.configService.businessType();
  }

  async getUserData() {
    this.userData = await this.authService.userData();
  }

  async getTerm(transType) {
    let res: any;
    res = await this.httpApiService.findByTransTypeAndOrder(transType);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data;
      let data1 = []
      if (!!dataValueList && dataValueList.length > 0) {
        dataValueList.forEach(el => {
          if (el.isActive == true) {
            data1.push(el)
          }
        })
        this.termList = data1;
      }
      console.log("termList ", this.termList);
    }
  }

  async recalculate() {
    this.recalculteSummary = false
    for (let i = 0; i < this.stonesList.length; i++) {
      let op1 = this.stonesList[i]['requestPrice'];
      let newOp = op1;
      let disc1 = parseFloat(this.disc1) / 100

      if (this.disc1) {
        newOp = newOp - (newOp * disc1)
      }

      if (this.disc2 && this.disc2 > 0) {
        let disc2 = parseFloat(this.disc2) / 100
        newOp = newOp - (newOp * disc2)
      }
      this.stonesList[i]['offerPrice'] = Number(newOp).toFixed(2);

      let amt = Number(this.stonesList[i].stoneCts) * Number(this.stonesList[i].offerPrice)
      // let amt1 : number = parseFloat( amt)
      this.stonesList[i]['amount'] = Number(amt).toFixed(2);
    }
    this.recalculteSummary = true
    setTimeout(() => {
      this.recalculteSummary = false;
    }, 1000)
  }

  async getDepartmentWiseKamPersonList() {
    let res: any;
    res = await this.httpApiService.getDepartmentWiseKamPersonList("KAM Sales");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.kamPersonList = data.data;
      console.log("get All Customer", this.kamPersonList);
    }
  }

  async exportPrice() {
    await this.configService.showLoader()
    let fileName = "pricing " + moment().format("DD/MM/YYYY")
    let csvData = [];

    let stoneData = [];
    for (let i = 0; i < this.stonesList.length; i++) {
      let obj = {
        'stoneId': this.stonesList[i].stoneId,
        'offerPrice': this.stonesList[i].offerPrice
      }
      stoneData.push(obj)
    }
    // stoneData = await stoneData.filter(function (obj) {
    //   return stoneData.indexOf(obj.id) === -1;
    // });

    await this.exportAsExcelService.exportAsExcelFile(stoneData, fileName)

    await this.loadingController.dismiss()
  }

  async exportData() {
    await this.configService.showLoader()
    let fileName = "export " + moment().format("DD/MM/YYYY")
    let csvData = [];

    let stoneData = [];
    for (let i = 0; i < this.stonesList.length; i++) {
      let obj = {
        'id': this.stonesList[i].id,
        'stoneId': this.stonesList[i].stoneId,
        'voucherId': this.stonesList[i].voucherId,
        'stoneCts': this.stonesList[i].stoneCts,
        'requestPrice': this.stonesList[i].requestPrice,
        'offerPrice': this.stonesList[i].offerPrice,
        'amount': this.stonesList[i].amount
      }
      stoneData.push(obj)
    }
    // stoneData = await stoneData.filter(function (obj) {
    //   return stoneData.indexOf(obj.id) === -1;
    // });

    await this.exportAsExcelService.exportAsExcelFile(stoneData, fileName)

    await this.loadingController.dismiss()
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }

    row = row.slice(0, -1);
    str += row + '\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index = 0; index <= headerList.length; index++) {
        let head = headerList[index];
        let val = array[i][head] || '';

        if (headerList[index + 1]) {
          line += val + ",";
        } else {
          line += val
        }
      }
      str += line + '\n';
    }
    return str;
  }

  async downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['StoneId', 'Cts', 'Shape', 'Color', 'Clarity', 'Party Name', 'Depth', 'Diameter', 'Discount 1', 'Discount 2', 'Request User', 'Request Price', 'Offer Price', 'Rfid', 'Trans Type', 'Voucher Id']);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  async loadCloumnsList() {
    if (this.editCts) {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      }, {
        name: 'Stone Id', prop: 'stoneId'
      },
      { name: 'voucherId', prop: "voucherId" },
      {
        name: 'Cts', prop: 'stoneCts', "displayFormat": "{0}", "fieldName": "stoneCts",
        "summaryType": "sum",
        "showInGroupFooter": true
      },
      {
        name: 'Offer Cts', prop: 'offerCts'
      },
      {
        name: "Memo Rate per carat", prop: "requestPrice", "fieldName": "requestPrice",
        "summaryType": "custom",
        "showInGroupFooter": true
      },
      {
        name: 'Memo Amt.', prop: "memoAmt", "displayFormat": "{0}", "fieldName": "memoAmt",
        "summaryType": "sum",
        "showInGroupFooter": true
      },
      {
        name: "Offer Price", prop: "offerPrice", "displayFormat": "{0}", "fieldName": "offerPrice",
        "summaryType": "avg",
        "showInGroupFooter": true
      },
      { name: "Less 1", prop: "disc1" },
      { name: "Less 2", prop: "disc2" },
      { name: "Exg Rate", prop: "exgRate" },
      {
        name: "Offer Amount", prop: "amount", "fieldName": "amount", "displayFormat": "{0}",
        "summaryType": "sum",
        "showInGroupFooter": true
      }

      ]
    } else {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      }, {
        name: 'Stone Id', prop: 'stoneId'
      },
      { name: 'voucherId', prop: "voucherId" },
      {
        name: 'Cts', prop: 'stoneCts', "displayFormat": "{0}", "fieldName": "stoneCts",
        "summaryType": "sum",
        "showInGroupFooter": true
      },
      {
        name: "Memo Rate per carat", prop: "requestPrice", "fieldName": "requestPrice",
        "summaryType": "custom",
        "showInGroupFooter": true
      },
      {
        name: 'Memo Amt.', prop: "memoAmt", "displayFormat": "{0}", "fieldName": "memoAmt",
        "summaryType": "sum",
        "showInGroupFooter": true
      },
      {
        name: "Offer Price", prop: "offerPrice", "displayFormat": "{0}", "fieldName": "offerPrice",
        "summaryType": "avg",
        "showInGroupFooter": true
      },
      { name: "Less 1", prop: "disc1" },
      { name: "Less 2", prop: "disc2" },
      { name: "Exg Rate", prop: "exgRate" },
      {
        name: "Offer Amount", prop: "amount", "fieldName": "amount", "displayFormat": "{0}",
        "summaryType": "sum",
        "showInGroupFooter": true
      }

      ]
    }
  }

  async checkButtonDisabled() {
    if (this.selectedCustomer) {
      console.log('---1')
      if (!this.customerId || !this.transTypeId) {
        console.log('---2')
        return true
      }
    } else if (!this.accuInvoiceNo) {
      console.log('---3')
      return true
    } else {
      console.log('---4')
      return false
    }
  }

  async fetchInvoice() {
    this.actionIdList = [];
    this.listOfCustomers = [];
    this.selectedTransType = [];
    this.selectedCustomerList = [];
    this.showParty = true
    this.dataLoaded = false
    await this.loadCloumnsList()
    this.showFilter = false;
    this.voucherIds = [];
    this.selectedVoucherId = [];
    this.stonesList = [];
    this.voucherIdArray = [];
    this.totalCts = 0;
    this.totalPcs = 0;
    this.totalAmount = 0;
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.fetchInvocie(this.accuInvoiceNo);
    let data = res.json();
    this.stonesList = data.data.stoneList;
    this.allStonesList = this.stonesList;
    if (!!data.data.errorsOfStoneMovement && !!data.data.errorsOfStoneMovement.listOfErrorsAndStoneId && data.data.errorsOfStoneMovement.listOfErrorsAndStoneId.length > 0) {
      this.exportAsExcelService.exportAsExcelFile(data.data.errorsOfStoneMovement.listOfErrorsAndStoneId, 'Not_found_Stones')
    }
    if (this.stonesList.length > 0) {
      let party = this.stonesList[0]['partyMasterId'];

      this.listOfCustomers = [{
        'id': party,
        'partyName': this.stonesList[0]['partyName']
      }]
      this.selectedCustomerList = [{
        'id': party,
        'partyName': this.stonesList[0]['partyName']
      }]
      this.selectedTransType = this.listOfTransType.filter(x => x.name == this.stonesList[0]['transType'])
      await this.getAddressesByPartyId(party)
    } else {
      await this.configService.showToast("error", 'No stones found');
    }

    await this.loadingController.dismiss()
    setTimeout(() => {
      this.addInputFormsData()
    }, 500);
  }

  async fetch() {
    this.dataLoaded = false
    await this.loadCloumnsList()
    this.showFilter = false;
    this.voucherIds = [];
    this.selectedVoucherId = [];
    this.stonesList = [];
    this.voucherIdArray = [];
    this.totalCts = 0;
    this.totalPcs = 0;
    this.totalAmount = 0;
    await this.configService.showLoader()
    if (this.selectedCustomer && !!this.stoneIds) {
      this.requestUserArray = [];
      let stoneIds = this.stoneIds.split("\n");
      stoneIds = stoneIds.filter(n => n)
      let jsonObj = {
        "partyMasterId": this.customerId,
        "requestAction": this.selectedAction,
        "setOfStoneNames": stoneIds
      }

      if (this.transTypeId) {
        jsonObj['transTypeId'] = this.transTypeId
      }
      let res: any;
      res = await this.httpApiService.findAllTransferRequestedByPartyAndStones(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.stonesList = data.data.stoneList;
        if (!!data.data.errorsOfStoneMovement && !!data.data.errorsOfStoneMovement.listOfErrorsAndStoneId && data.data.errorsOfStoneMovement.listOfErrorsAndStoneId.length > 0) {
          this.exportAsExcelService.exportAsExcelFile(data.data.errorsOfStoneMovement.listOfErrorsAndStoneId, 'Not_found_Stones')
        }
      }
      this.allStonesList = this.stonesList;
      this.addInputFormsData()

      await this.loadingController.dismiss()
    } else {
      if (this.selectedCustomer) {
        let jsonObj = {
          "partyMasterId": this.customerId,
          "requestAction": this.selectedAction,
          //"transTypeId": this.transTypeId
        }
        if (this.transTypeId) {
          jsonObj['transTypeId'] = this.transTypeId
        }
        let res: any;
        res = await this.httpApiService.findAllTransferRequestedByPartyAndStones(jsonObj);
        let data = res.json();

        this.stonesList = data.data.stoneList;
        this.allStonesList = this.stonesList;
        if (!!data.data.errorsOfStoneMovement && !!data.data.errorsOfStoneMovement.listOfErrorsAndStoneId && data.data.errorsOfStoneMovement.listOfErrorsAndStoneId.length > 0) {
          this.exportAsExcelService.exportAsExcelFile(data.data.errorsOfStoneMovement.listOfErrorsAndStoneId, 'Not_found_Stones')
        }
        this.addInputFormsData()
        await this.loadingController.dismiss()
      }

      else {

        await this.loadingController.dismiss()
        await this.configService.showToast("error", 'Please Select Customer ');
      }

    }


    this.addInputFormsData()
  }

  async addInputFormsData() {
    this.businessTypeArray = [];
    this.voucherIdArray = [];
    this.requestUserArray = [];
    this.termsArray = [];
    this.currencyArray = [];
    this.exgRateArray = [];
    this.selectedAddressIdList = [];
    this.currencyIdList = [];
    this.locationArray = [];
    this.brokerIdArray = [];
    this.selectedBrokerList = [];
    this.kamIdList = [];
    this.salesPerson = '';
    this.brokerId = '';
    this.businessType = '';
    this.termName = '';
    this.selectedTermList = [];
    this.invoiceNo = '';
    this.currencyRate = '';
    this.disc1 = '';
    this.disc2 = '';
    this.addSrNo = null;
    this.disc1Array = [];
    this.disc2Array = [];
    this.sumOfJangadAmt = 0;
    this.sumOfRsAmt = 0;
    this.totalExchangeRate = 0;
    console.log(this.stonesList)
    if (this.stonesList.length > 0) {
      for (let i = 0; i < this.stonesList.length; i++) {
        this.invoiceDate = !!this.stonesList[0].invDate ? this.stonesList[0].invDate : '';
        if (!!this.invoiceDate) {
          this.invoiceDate = moment(this.invoiceDate).format('YYYY-MM-DD');
        }
        let voucherId = this.stonesList[i].voucherId;
        let jangadNo = this.stonesList[i].jangadNo;
        this.stonesList[i].stoneCts = this._decimalPipe.transform(this.stonesList[i].stoneCts, '.3-3');
        if (voucherId) {
          if (this.voucherIdArray.indexOf(voucherId) < 0 && !this.showFilter) {
            this.voucherIdArray.push(voucherId)
            let obj = {
              'id': voucherId,
              'voucherName': this.stonesList[i].shortCode + ' ' + this.stonesList[i].bookSerialNumber
            }
            this.voucherIds.push(obj);

            this.selectedVoucherId.push(obj)
            //this.voucherViseStones(voucherId)

          }
        }
        if (jangadNo) {
          if (this.jangadNoList.indexOf(jangadNo) < 0) {
            this.jangadNoList.push(jangadNo)
          }
        }

        let requestUser = this.stonesList[i].requestUser;
        if (requestUser) {
          if (this.requestUserArray.indexOf(requestUser) < 0) {
            this.requestUserArray.push(requestUser)
          }
        }

        let brokerId = this.stonesList[i].brokerId;
        console.log(brokerId)
        if (brokerId) {
          if (this.brokerIdArray.indexOf(brokerId) < 0) {
            this.brokerIdArray.push(brokerId)
          }
        } else {
          this.brokerIdArray = [];
        }

        let amt = (Number(this.stonesList[i].stoneCts) * Number(this.stonesList[i].requestPrice)).toFixed(3);

        this.stonesList[i]['amount'] = Number(amt)
        this.stonesList[i]['memoAmt'] = Number(amt)


        let transType = this.stonesList[i].transType;
        if (transType) {
          if (this.transTypeArray.indexOf(transType) < 0) {
            this.transTypeArray.push(transType)
          }
        }

        let businessType = this.stonesList[i].businessType;
        if (businessType) {
          if (this.businessTypeArray.indexOf(businessType) < 0) {
            this.businessTypeArray.push(businessType)
          }
        }

        let terms = this.stonesList[i].terms;
        if (terms) {
          if (this.termsArray.indexOf(terms) < 0) {
            this.termsArray.push(terms)
          }
        }

        let customerReferenceId = this.stonesList[i].customerReferenceId;
        if (customerReferenceId) {
          this.customerReferenceId = customerReferenceId;
          if (this.customerReferenceArray.indexOf(customerReferenceId) < 0) {
            this.customerReferenceArray.push(customerReferenceId)
          }
        }

        let disc1 = this.stonesList[i].disc1;
        if (disc1) {
          if (this.disc1Array.indexOf(disc1) < 0) {
            this.disc1Array.push(disc1)
          }
        } else {
          if (this.disc1Array.indexOf(null) < 0) {
            this.disc1Array.push(null)
          }
        }

        let disc2 = this.stonesList[i].disc2;
        if (disc2) {
          if (this.disc2Array.indexOf(disc2) < 0) {
            this.disc2Array.push(disc2)
          }
        } else {
          if (this.disc2Array.indexOf(null) < 0) {
            this.disc2Array.push(null)
          }
        }

        let currency = this.stonesList[i].currency || this.stonesList[i].currencyName;
        if (currency) {
          if (this.currencyArray.indexOf(currency) < 0) {
            this.currencyArray.push(currency)
          }
        }


        if (currency == "US Dollar") {
          this.disableCurrencyRate = true
        }

        let location = this.stonesList[i].location;
        if (location) {
          if (this.locationArray.indexOf(location) < 0) {
            this.locationArray.push(location)
          }
        }

        let exgRate = this.stonesList[i].exgRate;
        console.log("exgRate", exgRate)

        if (exgRate) {
          if (this.exgRateArray.indexOf(exgRate) < 0) {
            this.exgRateArray.push(exgRate)
          }
          this.sumOfRsAmt += Number(this.stonesList[i]['memoAmt'] * exgRate);
        }
        this.sumOfJangadAmt += Number(this.stonesList[i]['memoAmt']);
        // console.log(exgRate, this.stonesList[i]['memoAmt'], this.sumOfJangadAmt, this.sumOfRsAmt)
        // let terms = this.stonesList[i].terms;
        // if (businessType) {
        //   if (this.businessTypeArray.indexOf(transType) < 0) {
        //     this.businessTypeArray.push(businessType)
        //   }
        // }

        if (this.stonesList[i].subRate) {
          this.stonesList[i]['offerPrice'] = Number(this.stonesList[i].subRate)

          let amt = (Number(this.stonesList[i].stoneCts) * Number(this.stonesList[i].offerPrice)).toFixed(3);
          this.stonesList[i]['amount'] = Number(amt).toFixed(2);

        } else {
          let requestPrice = this.stonesList[i].requestPrice;
          this.stonesList[i]['offerPrice'] = requestPrice;
        }

        let requestCts = this.stonesList[i].stoneCts;
        this.stonesList[i]['offerCts'] = requestCts;
      }
      this.showFilter = true;

      if (this.requestUserArray.length == 1) {
        //this.salesPerson = this.requestUserArray[0];
        //let obj = this.kamPersonList.filter(x => x.username == this.salesPerson)[0];
        //this.kamIdList.push(obj);

        this.kamIdList = this.kamPersonList.filter(x => x.username == this.requestUserArray[0]);
        if (this.kamIdList.length > 0) {
          this.salesPerson = this.kamIdList[0].fullName
        } else {
          this.salesPerson = '';
        }
      }

      if (this.disc1Array.length == 1) {
        this.disc1 = this.disc1Array[0];
      }

      if (this.disc2Array.length == 1) {
        this.disc2 = this.disc2Array[0];
      }

      if (this.transTypeArray.length == 1) {
        this.transType = this.transTypeArray[0];
        await this.getTerm(this.transType)
      }

      if (this.brokerIdArray.length == 1) {
        this.selectedBrokerList = this.brokerList.filter(t => t.id == this.brokerIdArray[0])

        if (this.selectedBrokerList.length > 0) {
          this.brokerId = this.selectedBrokerList[0].id
        }
      }

      if (this.termsArray.length == 1) {
        this.selectedTermList = this.termList.filter(t => t.name == this.termsArray[0])

        if (this.selectedTermList.length > 0) {
          this.termName = this.selectedTermList[0].name;
        }
      }

      if (this.customerReferenceArray.length == 1) {
        this.selectedRefCustomer = this.refPartyList.filter(t => t.id == this.customerReferenceArray[0])[0]
      }

      if (this.businessTypeArray.length == 1) {
        this.businessTypeIdList = await this.businessTypeList.filter(x => x.id == this.businessTypeArray[0])

        if (this.businessTypeIdList.length > 0) {
          this.businessType = this.businessTypeIdList[0].name
        }
      }

      if (this.currencyArray.length == 1) {
        this.currencyIdList = await this.currencyList.filter(x => x.name == this.currencyArray[0])

        if (this.currencyIdList.length > 0) {
          this.currencyMasterId = this.currencyIdList[0].id
        }
      }

      if (this.locationArray.length == 1) {
        this.selectedAddressIdList = await this.listOfAddress.filter(x => x.address == this.locationArray[0])

        if (this.selectedAddressIdList.length > 0) {
          this.addSrNo = !!this.selectedAddressIdList[0].addSrNo ? this.selectedAddressIdList[0].addSrNo : null;
          this.location = this.selectedAddressIdList[0].address;
        }
      }
      if (this.exgRateArray.length == 1) {
        this.currencyRate = this.exgRateArray[0];
        this.currencyIdList = await this.currencyList.filter(x => x.name == "Indian Rupee");
        this.selectedCurrency = this.currencyIdList[0].name;
        this.disableCurrencyRate = false;
      }
      console.log(this.exgRateArray, '--->>>>')
      if (this.exgRateArray.length > 1) {
        let rate = this.sumOfRsAmt / this.sumOfJangadAmt;
        setTimeout(() => {
          this.currencyRate = this._decimalPipe.transform(rate, '.6-6');
        }, 500);
        this.currencyIdList = await this.currencyList.filter(x => x.name == "Indian Rupee");
        this.selectedCurrency = this.currencyIdList[0].name;
        this.disableCurrencyRate = false;
      }
      if (this.exgRateArray.length == 0) {
        this.currencyIdList = await this.currencyList.filter(x => x.name == "US Dollar");
        this.selectedCurrency = this.currencyIdList[0].name;
        this.disableCurrencyRate = true;
      }
      this.recalculteSummary = true
      setTimeout(() => {
        this.recalculteSummary = false;
      }, 1000)
      // setTimeout(() => {
      //   this.dataLoaded = true
      // }, 500);
    } else {
      await this.configService.showToast("error", 'No records');
    }
  }

  async getAllActions() {
    let res: any;
    this.userData = await this.authService.userData();
    res = await this.httpApiService.getAllActionsByUserID(this.userData.userId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfMasterActions = data.data;
    }
  }

  async getDefaultSetting(actionId) {
    let res: any;
    res = await this.httpApiService.getDefaultSetting(
      this.userData.userId,
      actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
      }
      console.log(this.voucherBookId);
    }
  }

  async getVoucherBook(actionName) {
    let actionArray = this.listOfMasterActions.filter(function (item) {
      if (item.actionName == actionName) {
        return item.actionName;
      }
    });
    console.log(actionArray)
    if (actionArray.length > 0) {
      await this.getDefaultSetting(actionArray[0].id);
    }
  }


  async markAsSold() {

    let validate = await this.validateSoldForm()
    if (!validate) {
      return;
    }
    let setOfStoneParameter = []
    let voucherIds = [];
    await this.configService.showLoader();
    this.selected.forEach((el, i) => {
      let cts;
      if (this.editCts) {
        cts = el.offerCts
      } else {
        cts = el.stoneCts
      }
      let obj = {
        "stoneId": el.id,
        "size": cts,
        "sellingPrice": el.offerPrice ? el.offerPrice.toString() : el.requestPrice.toString(),
        "exgRate": el.exgRate
      }
      setOfStoneParameter.push(obj)
      if (voucherIds.indexOf(el.voucherId) < 0) {
        voucherIds.push(el.voucherId)
      }
    });
    await this.getVoucherBook('Sold');
    if (!!this.voucherBookId) {
      if (this.disc1 == null && this.disc2 == null && this.disc1 == "" && this.disc2 == "") {
        this.disc1 = 0;
        this.disc2 = 0;
      }
      // this.termName = this.termName.split('Days')[0].trim()
      let dataObj = {
        setOfStoneParameter: setOfStoneParameter,
        setOfValues: this.selectedStoneList,
        partyMasterId: this.customerId,
        requestUser: this.salesPerson,
        setOfVoucherIds: voucherIds,
        remarks: "Sold Stone",
        status: "Sold Confirmed",
        type: 'Sold',
        transferOnRequestStatus: 'Pending',
        voucherDate: moment().format("YYYY-MM-DD"),
        voucherBookId: this.voucherBookId,
        businessType: this.businessType,
        terms: this.termName || '',
        location: this.location,
        currencyMasterId: this.currencyMasterId,
        exgRate: this.currencyRate,
        brokerId: this.brokerId,
        invoiceNo: !!this.accuInvoiceNo ? this.accuInvoiceNo.trim() : null,
        invoiceDate: this.invoiceDate,
        disc1: this.disc1,
        disc2: this.disc2,
        customerReferenceId: this.customerReferenceId,
        updateParametersForSold: true,
        addSrNo: this.addSrNo
      };
      if (!!this.invoiceDate) {
        dataObj.invoiceDate = moment(this.invoiceDate).format('DD-MMM-YYYY');
      }
      if (!!this.accuInvoiceNo && !this.invoiceDate) {
        await this.configService.showToast("error", 'Accusoft invoice date is not found');
        await this.configService.dismiss();
        return;
      }
      let res = await this.httpApiService.markMultipleStoneAsConfirmed(dataObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 200) {
        await this.configService.showToast("success", data.message);
        let d = { data: 10 };
        this.stonesList = [];
        this.businessTypeIdList = [];
        this.selectedTermList = [];
        this.kamIdList = [];
        this.selectedCustomerList = [];
        this.actionIdList = [];
        this.stoneIds = '';
        this.voucherIdArray = [];
        this.selectedStoneList = [];
        this.voucherBookId = ''
        this.businessType = '';
        this.salesPerson = '';
        this.termName = '';
        this.selected = [];
        this.location = null;
        this.currencyMasterId = null;
        this.currencyRate = null;
        this.brokerId = null;
        this.invoiceNo = null;
        this.customerId = null;
        this.voucherIds = [];
        this.showFilter = false;
        this.disc1Array = [];
        this.disc2Array = [];
        this.disc1 = '';
        this.disc2 = '';
        this.totalPcs = 0;
        this.totalCts = 0;
        this.totalAmount = 0
      }
      await this.configService.dismiss();

    } else {
      await this.configService.dismiss();
      await this.configService.showToast('error', 'Please select voucher book for Sold')
    }
  }

  async loadData(rows) {

  }

  async updatePriceSelected(event) {
    readXlsxFile(event.target.files[0]).then((rows) => {
      for (let i = 0; i < rows.length; i++) {
        let el = rows[i]
        if (!!el && i > 0) {
          let stoneId = el[0];
          let price = el[1];
          this.stonesList = this.allStonesList.map(x => {
            let stId = x.stoneId;
            if (stId == stoneId) {
              x['offerPrice'] = price;
              let amt = Number(x['stoneCts']) * Number(x['offerPrice'])
              x['amount'] = Number(amt).toFixed(2);
            }
            return x
          })
        }
      }
      this.myInputVariable.nativeElement.value = "";
    })
  }

  async onFileSelected(event) {
    this.stonesList = [];
    //let fileData = <File>event.target.files[0];
    //let files = event.target.files;
    readXlsxFile(event.target.files[0]).then((rows) => {
      for (let i = 0; i < rows.length; i++) {
        let el = rows[i]
        if (!!el && i > 0) {
          //console.log(el);
          let id = el[0];
          let offerPrice = el[5];
          let obj = this.allStonesList.filter(x => x.id == id)[0];
          obj['offerPrice'] = offerPrice;
          let amt = Number(obj['stoneCts']) * Number(obj['offerPrice'])
          obj['amount'] = Number(amt).toFixed(2);
          this.stonesList.push(obj)
        }
      }
    })
  }


  async validateSoldForm() {
    console.log(this.salesPerson)
    if (!this.brokerId && this.selectedAction == 'Customer Confirm') {
      await this.configService.showToast("error", 'Please add broker or NONE as broker');
      return false
    } else if (this.selectedCurrency == 'Indian Rupee' && !this.currencyRate && this.currencyRate == 0) {
      await this.configService.showToast("error", 'Please add exchange rate');
      return false;
    }
    else if (!this.location) {
      await this.configService.showToast("error", 'Please select address');
      return false;
    } else if (!this.termName) {
      await this.configService.showToast('error', 'Please select Terms')
      return false;
    } else if (!this.customerId) {
      await this.configService.showToast('error', 'Please select Party')
      return false;
    } else if (!this.salesPerson) {
      await this.configService.showToast('error', 'Please select Sales person')
      return false;
    }
    else {
      return true;
    }

  }

  async openModal() {
    let modalPage = await this.modalCtrl.create({
      component: ManageCertificationPage,
      backdropDismiss: false,
      componentProps: { type: '111', data: 0, title: 'Consignment Auto Sold' },
      cssClass: 'extraLargePage'
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return modalPage.present();
  }

  handleModalDismiss(d) {

  }

}
