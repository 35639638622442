import { Component, OnInit } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-manage-user",
  templateUrl: "./manage-user.page.html",
  styleUrls: ["./manage-user.page.scss"]
})
export class ManageUserPage implements OnInit {
  public allRoles: any = [];
  public roleId: any;
  public listOfActions = [];
  public id: any;
  public isModal: any;
  public name: any;
  public userData: any;
  public voucherType: any;
  public voucherId: any;
  public dropdownList: any;
  public dropdownSettings: any;
  public actionName: any;
  public listOfRoles = [];
  public selectedUserId: any;
  public isPasswordChange: boolean = false;

  //Add new User
  public username = "";
  public password = "";
  public companyName = "";
  public appName = "";
  public fullName = "";
  public mobileNumber = "";
  public email = "";
  public deptList = [];
  public listOfLocations: any;
  public usersList = [];
  public listOfVoucherBook: any;
  public voucherBook: any;
  public companyIdList = [];
  public toUser: number;
  public toLocation: number;
  public toDept: number;
  public fromDept: number;
  public toActionId: number;
  public items: any;
  public flag: any = 2;
  public picked: any;
  public pickedFlag = false;
  public boxed: any;
  public boxedFlag = false;
  public defaultData: any;
  public fromUser: any;
  public dept: any;
  public recutEnable: Boolean = false;
  public observationEnable: Boolean = false;
  public certification: Boolean = false;
  public mixing: Boolean = false;
  public requisition: Boolean = false;
  public consignment: Boolean = false;
  public stoneReturn: Boolean = false;
  public consignmentConfirm: Boolean = false;
  public memoClient: Boolean = false;
  public sold: Boolean = false;
  public customerConfirm: Boolean = false;
  public memoService: Boolean = false;
  public consignmentInStock: Boolean = false;
  public selfObservationEnable: Boolean = false;
  public memoClientEnable: Boolean = false;
  public soldEnable: Boolean = false;
  public customerHoldEnable: Boolean = false;
  public customerUnHoldEnable: Boolean = false;
  public customerConfirmEnable: Boolean = false;
  public memoServiceEnable: Boolean = false;
  public blockForSaleEnable: Boolean = false;
  public consignmentConfirmEnable: Boolean = false;
  public consignmentInStockEnable: Boolean = false;
  public certificationEnable: Boolean = false;
  public requisitionEnable: Boolean = false;
  public requestToKAMEnable: Boolean = false;
  public convertToMixEnable: Boolean = false;
  public unblockForSaleEnable: Boolean = false;
  public toRole: any;
  public fromRole: any;
  public priceListName: any;
  public scanBoardUrl: any;

  public fromDeptidList = [];
  public toDeptidList = [];
  public toLocationidList = [];
  public voucheridList = [];
  public toUseridList = [];
  public fromUseridList = [];
  public priceidList = []
  public multiplePriceIdList = []
  public toRoleIdList = []
  public fromRoleIdList = []
  public requestVoucherBook: any;
  public requestVoucherIdList = []

  public allPriceNameList = [];
  public toLocationObvidList = []
  public partyMasterIdList = []

  public defaultObjRecut = {};
  public defaultObjObservation = {};
  public defaultObjSelfObservation = {}
  public defaultObjMemoClient = {};
  public defaultObjSold = {};
  public defaultObjUnblockForSale = {};
  public defaultObjCustomerConfirm = {};
  public defaultObjCustomerUnHold = {};
  public defaultObjMemoService = {};
  public defaultObjConsignmentConfirm = {};
  public defaultObjConsignmentInStock = {};
  public defaultObjCertification = {}
  public defaultObjRequisition = {}
  public defaultObjCustomerHold = {};
  public defaultObjRequestToKAM = {};
  public defaultObjConvertToMix = {};
  public defaultObjBlockForSale = {};
  public typeList = [];
  public typeIdList = [];
  public type: any;

  public boxTypeList = [];
  public boxType: any;
  public boxTypeIdList = [];
  public partyTypeList = [];
  public partyTypeMemoClientIdList = []
  public partyTypeSoldIdList = [];
  public partyTypeCConfirmIdList = []
  public partyTypeForMemoServiceIdList = []
  public partyTypeForConsignmentConfirmIdList = []
  public voucherForUnblockForSaleIdList = []
  public partyTypeForConsignmentInStockIdList = []
  public partyTypeSelfObservationIdList = []
  public partyTypeBlockForSaleIdList = []
  public partyTypeForCertificationIdList = []
  public partyTypeForRequisitionIdList = []
  public partyTypeRequestToKAMIdList = []
  public partyTypeIdList = []
  public partyType = [];
  public transType: any;
  public transTypeList = [];
  public transTypeIdList = []
  public partyTypeCustomerHoldIdList = []
  public limit: Number;
  public ctsDifference: Number;
  public internalStatuslist = []
  public internalStatusIdList = []
  public externalStatuslist = []
  public internalStatus: any

  public isAllowedStatus: [];
  public isAllowedStatusIdList = [];

  public listOfPrices = []
  public voucherForHoldCustomerIdList = []
  public voucherForUnHoldCustomerIdList = []

  public isDirectCustomerConfirm = false;
  public isDirectMemoClient = false;
  public isDirectSold = false;
  public isDirectConsignmentConfirm = false;
  public isDirectRecut = false;
  public isDirectObservation = false;
  public isDirectMemoService = false;
  public isDirectConsignmentInStock = false;
  public isDirectCertification = false;
  public isDirectConvertToMix = false;
  public voucherBookList = [];
  public voucherbookIdList = [];

  public deptForPriceInReport: any;
  public transTypeForPriceInReport: any;
  public priceForReport: any;

  public priceForReportIdList = [];
  public deptForReportIdList = [];
  public transTypePriceIdList = [];

  public reports = []
  public basePriceListName: any;
  public basePriceIdList = []

  public voucherForBlockForSaleIdList = []
  public extStatusCConfirmIdList = []
  public extStatusMemoClientIdList = []
  public extStatusSoldIdList = []
  public extStatusSelfObvIdList = []
  public extStatusMemoServiceIdList = []
  public extStatusConsignmentInStockIdList = []
  public extStatusConsignmentConfirmIdList = []
  public extStatusRequisitionIdList = []
  public extStatusRecutIdList = []
  public extStatusObservationIdList = []
  public extStatusCertificationIdList = []
  public defaultTempalateList = [];
  public matchFromDB: Boolean = false;
  public accusoftSync: Boolean = false;
  public newStockProcessingStatus = '';
  public compareJson = '';
  public svcKam;
  public salesPersonList = []
  public fullStockProcessList = [];
  public defaultExportList = [];
  public defaultTemplateId: any;
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public configService: ConfigService,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public event: Events
  ) { }

  // async getAllCompanies() {
  //   let res: any;
  //   res = await this.httpApiService.getAllCompanies();
  //   let data = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.listOfLocations = data.data;
  //     console.log("getAllCompanies res", res, this.listOfLocations);
  //   }
  // }

  async ngOnInit() {
    this.getAllDepartment();
    this.getBoxType();
    this.getAllPartyMasterType()
    this.getAllRoles();
    this.getAllDefaultTemplates();
    this.getAllStoneStatus();
    this.getAllTransType();
    await this.getAppName();
    await this.getFromLocation();
    //this.getAllCompanies();
    // await this.getAllCompany();

    // this.listOfLocations.forEach(item=>{
    //   if(item.companyName ==  this.companyName.toLowerCase()){
    //     this.toLocation = item.id
    //   }
    // });
    // await this.getAllToUser(this.toLocation);
    this.username = "";
    this.isModal = this.navParams.get("flgModal");
    this.id = this.navParams.get("id");
    if (this.isModal == "addUserRole") {
      await this.getAllRole();
      this.name = this.navParams.get("username");
      this.id = this.navParams.get("id");
    } else if (this.isModal == "updateUserDefault") {
      this.selectedUserId = this.navParams.get("selectedUserId")
      console.log(this.selectedUserId)
      this.actionName = this.id.actionName;
      this.toActionId = this.id.actionId;
      if (this.actionName == 'Pending Transfer Approval') {
        await this.getVoucherBookListForPendingApproval()
      } else {
        if (this.id.voucherType) {
          this.voucherType = this.id.voucherType;
          let res: any;
          res = await this.httpApiService.getAllVouchersForSelectedType(
            this.voucherType
          );
          let data = res.json();
          console.log(data)
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfVoucherBook = data.data;
            console.log(this.listOfVoucherBook);
          }
        }
      }
      this.id = this.id.id;
      this.getDefaultSetting(this.toActionId, this.id);
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "roles") {
      this.roleId = idList[0];
    } else if (property == "fromDepartment") {
      this.fromDept = idList[0];
    } else if (property == "toDepartment") {
      this.toDept = idList[0];
    } else if (property == "internalStatus") {
      if (idList.length > 0) {
        this.internalStatus = idList[0];
      } else {
        this.internalStatus = null;
        this.internalStatusIdList = []
      }
    } else if (property == "toLocation") {
      if (idList.length > 0) {
        this.toUseridList = [];
        this.usersList = [];
        this.toLocation = idList[0];
        this.getAllToUser(this.toLocation);
      } else {
        this.toLocation = null;
        this.toUseridList = [];
        this.usersList = [];
      }
    } else if (property == "voucherBook") {
      this.voucherBook = idList[0];
    } else if (property == "voucherBookList") {
      if (idList.length > 0) {
        this.voucherBookList = idList;
      } else {
        this.voucherBookList = [];
      }
    }
    else if (property == "isAllowedStatus") {
      if (idList.length > 0) {
        this.isAllowedStatus = idList;
      } else {
        this.isAllowedStatus = [];
      }
    }
    else if (property == "voucherBookForHoldCustomer") {
      if (idList.length > 0) {
        this.defaultObjCustomerHold["voucherBookId"] = idList[0]
        this.defaultObjCustomerHold["enable"] = true;
      } else {
        delete this.defaultObjCustomerHold["voucherBookId"];
      }
    } else if (property == "voucherBookForBlockForSale") {
      if (idList.length > 0) {
        this.defaultObjBlockForSale["voucherBookId"] = idList[0]
        this.defaultObjBlockForSale["enable"] = true;
      } else {
        delete this.defaultObjBlockForSale["voucherBookId"];
      }
    } else if (property == "voucherBookForUnHoldCustomer") {
      if (idList.length > 0) {
        this.defaultObjCustomerUnHold["voucherBookId"] = idList[0]
        this.defaultObjCustomerUnHold["enable"] = true;
      } else {
        delete this.defaultObjCustomerUnHold["voucherBookId"];
      }
    } else if (property == "voucherBookForUnblockForSale") {
      if (idList.length > 0) {
        this.defaultObjUnblockForSale["voucherBookId"] = idList[0]
        this.defaultObjUnblockForSale["enable"] = true;
      } else {
        delete this.defaultObjUnblockForSale["voucherBookId"];
      }
    } else if (property == "requestVoucherBook") {
      this.requestVoucherBook = idList[0];
    }
    else if (property == "toUsers") {
      this.toUser = idList[0];
      console.log("toUseridList", this.toUseridList);
    } else if (property == "fromUsers") {
      this.fromUser = idList[0];
    } else if (property == "allDepartment") {
      this.dept = idList[0];
    } else if (property == "price") {
      if (idList.length > 0) {
        this.priceListName = idList[0];
      } else {
        this.priceListName = null;
      }
    }
    else if (property == "basePrice") {
      if (idList.length > 0) {
        this.basePriceListName = idList[0];
      } else {
        this.basePriceListName = null;
      }
    } else if (property == "multiplePrice") {
      if (idList.length > 0) {
        this.listOfPrices = idList;
      } else {
        this.listOfPrices = []
      }
    } else if (property == "toRoles") {
      if (idList.length > 0) {
        this.toRole = idList[0];
      } else {
        this.toRole = null;
      }
    } else if (property == "fromRoles") {
      if (idList.length > 0) {
        this.fromRole = idList[0];
      } else {
        this.fromRole = null;
      }
    } else if (property == "toLocationForRecut") {
      if (idList.length > 0) {
        this.defaultObjRecut["toLocation"] = idList[0];
        this.defaultObjRecut["enable"] = true;
      } else {
        delete this.defaultObjRecut["toLocation"];
      }
      console.log(this.defaultObjRecut)
    } else if (property == "toLocationForObservation") {
      if (idList.length > 0) {
        this.defaultObjObservation["toLocation"] = idList[0];
        this.defaultObjObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["toLocation"];
      }
      console.log(this.defaultObjObservation)
    } else if (property == "partMaster") {
      if (idList.length > 0) {
        this.defaultObjSelfObservation["partyMaster"] = idList[0];
        this.defaultObjSelfObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["toLocation"];
      }
    } else if (property == "typeId") {
      if (idList.length > 0) {
        this.type = obj[0].name;
      } else {
        this.type = null;
      }
    } else if (property == "boxType") {
      if (idList.length > 0) {
        this.boxType = obj[0].type;
      } else {
        this.boxType = null;
      }
    } else if (property == "partyTypeForMemoClient") {
      if (idList.length > 0) {
        this.defaultObjMemoClient["partyType"] = obj.map(a => a.type);
        this.defaultObjMemoClient["enable"] = true;
      } else {
        delete this.defaultObjMemoClient["partyType"];
      }
    } else if (property == "partyTypeForSold") {
      if (idList.length > 0) {
        this.defaultObjSold["partyType"] = obj.map(a => a.type);
        this.defaultObjSold["enable"] = true;
      } else {
        delete this.defaultObjSold["partyType"];
      }
    } else if (property == "partyTypeForRequestToKAM") {
      if (idList.length > 0) {
        this.defaultObjRequestToKAM["partyType"] = obj.map(a => a.type);
        this.defaultObjRequestToKAM["enable"] = true;
      } else {
        delete this.defaultObjRequestToKAM["partyType"];
      }
    } else if (property == "partyTypeForCustomerHold") {
      if (idList.length > 0) {
        this.defaultObjCustomerHold["partyType"] = obj.map(a => a.type);
        this.defaultObjCustomerHold["enable"] = true;
      } else {
        delete this.defaultObjCustomerHold["partyType"];
      }
    } else if (property == "partyTypeForBlockForSale") {
      if (idList.length > 0) {
        this.defaultObjBlockForSale["partyType"] = obj.map(a => a.type);
        this.defaultObjBlockForSale["enable"] = true;
      } else {
        delete this.defaultObjBlockForSale["partyType"];
      }
    } else if (property == "partyTypeForCConfirm") {
      if (idList.length > 0) {
        this.defaultObjCustomerConfirm["partyType"] = obj.map(a => a.type);
        this.defaultObjCustomerConfirm["enable"] = true;
      } else {
        delete this.defaultObjCustomerConfirm["partyType"];
      }
    } else if (property == "partyTypeForMemoService") {
      if (idList.length > 0) {
        this.defaultObjMemoService["partyType"] = obj.map(a => a.type);
        this.defaultObjMemoService["enable"] = true;
      } else {
        delete this.defaultObjCustomerConfirm["partyType"];
      }
    } else if (property == "partyTypeForConsignmentInStock") {
      if (idList.length > 0) {
        this.defaultObjConsignmentInStock["partyType"] = obj.map(a => a.type);;
        this.defaultObjConsignmentInStock["enable"] = true;
      } else {
        delete this.defaultObjConsignmentInStock["partyType"];
      }
    } else if (property == "partyTypeForConsignmentConfirm") {
      if (idList.length > 0) {
        this.defaultObjConsignmentConfirm["partyType"] = obj.map(a => a.type);
        this.defaultObjConsignmentConfirm["enable"] = true;
      } else {
        delete this.defaultObjConsignmentConfirm["partyType"];
      }
    } else if (property == "partyTypeForRequisition") {
      if (idList.length > 0) {
        this.defaultObjRequisition["partyType"] = obj.map(a => a.type);
        this.defaultObjRequisition["enable"] = true;
      } else {
        delete this.defaultObjRequisition["partyType"];
      }
    } else if (property == "partyTypeForCertification") {
      if (idList.length > 0) {
        this.defaultObjCertification["partyType"] = obj.map(a => a.type);
        this.defaultObjCertification["enable"] = true;
      } else {
        delete this.defaultObjCertification["partyType"];
      }
    } else if (property == "partyType") {
      if (idList.length > 0) {
        this.partyType = obj.map(a => a.type)
      } else {
        this.partyType = []
      }
    } else if (property == "transTypeList") {
      if (idList.length > 0) {
        this.transType = obj[0].name
      } else {
        this.transType = null
      }
    } else if (property == "reportTransTypeList") {
      console.log(this.defaultData)
      if (idList.length > 0) {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.transTypeList = idList;
                item.reportValue.transTypeIdList = obj;
              }
            })
          }
        })
      } else {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.transTypeList = []
                item.reportValue.transTypeIdList = []
              }
            })
          }
        })
      }
      console.log(this.defaultData)
    } else if (property == "reportDepartmentList") {
      if (idList.length > 0) {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.departmentList = idList;
                item.reportValue.departmentIdList = obj;
                console.log(item.reportValue.departmentIdList)
              }
            })
          }
        })
      } else {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.departmentList = []
                item.reportValue.departmentIdList = [];
              }
            })
          }
        })
      }
      console.log(this.defaultData)
    } else if (property == "defaultTemplateList") {
      if (idList.length > 0) {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.defaultExportList = idList;
                item.reportValue.defaultExportIdList = obj;
                console.log(item.reportValue.defaultExportIdList)
              }
            })
          }
        })
      } else {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.defaultExportList = []
                item.reportValue.defaultExportIdList = [];
              }
            })
          }
        })
      }
      console.log(this.defaultData)
    } else if (property == "reportRapPriceList") {
      if (idList.length > 0) {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.priceListName = obj;
              }
            })
          }
        })
      } else {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.priceListName = []
              }
            })
          }
        })
      }
    }
    else if (property == 'defaultTemplate') {
      if (idList.length > 0) {
        this.defaultTemplateId = idList[0];
      } else {
        this.defaultTemplateId = null;
      }
    }
    else if (property == "deptForPriceInReport") {
      if (idList.length > 0) {
        this.deptForPriceInReport = obj[0]
      } else {
        this.deptForPriceInReport = null;
      }
    } else if (property == "transTypeForPriceInReport") {
      if (idList.length > 0) {
        this.transTypeForPriceInReport = obj[0]
      } else {
        this.transTypeForPriceInReport = null;
      }
    } else if (property == "priceForReport") {
      if (idList.length > 0) {
        this.priceForReport = obj[0]
      } else {
        this.priceForReport = null;
      }
    } else if (property == "extStatusForMemoClient") {
      if (idList.length > 0) {
        this.defaultObjMemoClient["externalStatus"] = idList[0];
        this.defaultObjMemoClient["enable"] = true;
      } else {
        delete this.defaultObjMemoClient["externalStatus"];
      }
    } else if (property == "extStatusForSold") {
      if (idList.length > 0) {
        this.defaultObjSold["externalStatus"] = idList[0];
        this.defaultObjSold["enable"] = true;
      } else {
        delete this.defaultObjSold["externalStatus"];
      }
    }
    else if (property == "extStatusForCConfirm") {
      if (idList.length > 0) {
        this.defaultObjCustomerConfirm["externalStatus"] = idList[0];
        this.defaultObjCustomerConfirm["enable"] = true;
      } else {
        delete this.defaultObjCustomerConfirm["externalStatus"];
      }
    } else if (property == "extStatusForMemoService") {
      if (idList.length > 0) {
        this.defaultObjMemoService["externalStatus"] = idList[0];
        this.defaultObjMemoService["enable"] = true;
      } else {
        delete this.defaultObjMemoService["externalStatus"];
      }
    } else if (property == "extStatusForConsignmentInStock") {
      if (idList.length > 0) {
        this.defaultObjConsignmentInStock["externalStatus"] = idList[0];
        this.defaultObjConsignmentInStock["enable"] = true;
      } else {
        delete this.defaultObjConsignmentInStock["externalStatus"];
      }
    } else if (property == "extStatusForConsignmentConfirm") {
      if (idList.length > 0) {
        this.defaultObjConsignmentConfirm["externalStatus"] = idList[0];
        this.defaultObjConsignmentConfirm["enable"] = true;
      } else {
        delete this.defaultObjConsignmentConfirm["externalStatus"];
      }
    } else if (property == "extStatusForRequisition") {
      if (idList.length > 0) {
        this.defaultObjRequisition["externalStatus"] = idList[0];
        this.defaultObjRequisition["enable"] = true;
      } else {
        delete this.defaultObjRequisition["externalStatus"];
      }
    } else if (property == "extStatusForCertification") {
      if (idList.length > 0) {
        this.defaultObjCertification["externalStatus"] = idList[0];
        this.defaultObjCertification["enable"] = true;
      } else {
        delete this.defaultObjCertification["externalStatus"];
      }
    } else if (property == "extStatusForRecut") {
      if (idList.length > 0) {
        this.defaultObjRecut["externalStatus"] = idList[0];
        this.defaultObjRecut["enable"] = true;
      } else {
        delete this.defaultObjRecut["externalStatus"];
      }
    } else if (property == "extStatusForObservation") {
      if (idList.length > 0) {
        this.defaultObjObservation["externalStatus"] = idList[0];
        this.defaultObjObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["externalStatus"];
      }
    } else if (property == "fullStockProcess") {
      if (idList.length > 0) {
        this.fullStockProcessList = idList;
      } else {
        this.fullStockProcessList = [];
      }
    }

  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transTypeList = data.data;
    }
  }

  // async getVoucherBookListForPendingApproval() {
  //   let res: any;
  //   res = await this.httpApiService.getAllVouchersForSelectedType(
  //     'Returnable'
  //   );
  //   let data = res.json();
  //   console.log(data)
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.listOfVoucherBook = data.data;
  //     console.log(this.listOfVoucherBook);
  //   }
  // }

  async getAllPartyMasterType() {
    let res: any;
    res = await this.httpApiService.getAllPartyMasterType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      console.log(dataList)
      this.partyTypeList = []
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          this.partyTypeList.push({ id: i + 1, type: dataList[i] })
        }
      }
    }
    console.log("boxTypeList", this.boxTypeList);
  }

  async getAllRoles() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfRoles = data.data;
      console.log("getAllRoles res", res, this.listOfRoles);
    }
  }

  async getBoxType() {
    let res;
    res = await this.httpApiService.getAllBoxType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.boxTypeList = data.data;
      console.log("boxTypeList", this.boxTypeList);
    }
  }

  async UpdateUserDefaultData() {
    let mainObj = this.defaultData.map(function (key, value) {
      return key.paramName;
    })
    console.log(mainObj)
    let jsonObj = {
      userId: this.id,
      actionId: this.toActionId,
      fromDepartment: this.fromDept,
      toDepartment: this.toDept,
      defaultTemplate: this.defaultTemplateId,
      toUser: this.toUser,
      toLocation: this.toLocation,
      fromUser: this.fromUser,
      voucherBook: this.voucherBook,
      requestVoucherBook: this.requestVoucherBook,
      toRole: this.toRole,
      fromRole: this.fromRole,
      type: this.type,
      Limit: this.limit,
      ctsDifference: this.ctsDifference,
      internalStatus: this.internalStatus,
      boxType: this.boxType,
      priceListName: this.priceListName,
      basePriceListName: this.basePriceListName,
      listOfPrices: JSON.stringify(this.listOfPrices),
      transType: this.transType,
      voucherBookList: JSON.stringify(this.voucherBookList),
      fullStockProcess: this.fullStockProcessList.toString(),
      matchFromDB: this.matchFromDB,
      newStockProcessingStatus: this.newStockProcessingStatus,
      compareJson: this.compareJson,
      'SVCKAMMapping': this.svcKam,
      'Accusoft sync': this.accusoftSync,
      'Return': this.stoneReturn,
      'Recut': Object.keys(this.defaultObjRecut).length > 0 ? JSON.stringify(this.defaultObjRecut) : this.defaultObjRecut["enable"] = false,
      'Observation': Object.keys(this.defaultObjObservation).length > 0 ? JSON.stringify(this.defaultObjObservation) : this.defaultObjObservation["enable"] = false,
      'Self Observation': Object.keys(this.defaultObjSelfObservation).length > 0 ? JSON.stringify(this.defaultObjSelfObservation) : this.defaultObjSelfObservation["enable"] = false,
      'Requisition': Object.keys(this.defaultObjRequisition).length > 0 ? JSON.stringify(this.defaultObjRequisition) : this.defaultObjRequisition["enable"] = false,
      'Certification': Object.keys(this.defaultObjCertification).length > 0 ? JSON.stringify(this.defaultObjCertification) : this.defaultObjCertification["enable"] = false,
      'Consignment Confirm': Object.keys(this.defaultObjConsignmentConfirm).length > 0 ? JSON.stringify(this.defaultObjConsignmentConfirm) : this.defaultObjConsignmentConfirm["enable"] = false,
      'Memo Client': Object.keys(this.defaultObjMemoClient).length > 0 ? JSON.stringify(this.defaultObjMemoClient) : this.defaultObjMemoClient["enable"] = false,
      'Sold': Object.keys(this.defaultObjSold).length > 0 ? JSON.stringify(this.defaultObjSold) : this.defaultObjSold["enable"] = false,
      'Request To KAM': Object.keys(this.defaultObjRequestToKAM).length > 0 ? JSON.stringify(this.defaultObjRequestToKAM) : this.defaultObjRequestToKAM["enable"] = false,
      'Hold Customer': Object.keys(this.defaultObjCustomerHold).length > 0 ? JSON.stringify(this.defaultObjCustomerHold) : this.defaultObjCustomerHold["enable"] = false,
      'Block For Sale': Object.keys(this.defaultObjBlockForSale).length > 0 ? JSON.stringify(this.defaultObjBlockForSale) : this.defaultObjBlockForSale["enable"] = false,
      'Unhold Customer': Object.keys(this.defaultObjCustomerUnHold).length > 0 ? JSON.stringify(this.defaultObjCustomerUnHold) : this.defaultObjCustomerUnHold["enable"] = false,
      'Unblock For Sale': Object.keys(this.defaultObjUnblockForSale).length > 0 ? JSON.stringify(this.defaultObjUnblockForSale) : this.defaultObjUnblockForSale["enable"] = false,
      'Customer Hold': Object.keys(this.defaultObjCustomerHold).length > 0 ? JSON.stringify(this.defaultObjCustomerHold) : this.defaultObjCustomerHold["enable"] = false,
      'Customer Confirm': Object.keys(this.defaultObjCustomerConfirm).length > 0 ? JSON.stringify(this.defaultObjCustomerConfirm) : this.defaultObjCustomerConfirm["enable"] = false,
      'Memo Service': Object.keys(this.defaultObjMemoService).length > 0 ? JSON.stringify(this.defaultObjMemoService) : this.defaultObjMemoService["enable"] = false,
      'Consignment In Stock': Object.keys(this.defaultObjConsignmentInStock).length > 0 ? JSON.stringify(this.defaultObjConsignmentInStock) : this.defaultObjConsignmentInStock["enable"] = false,
      'partyType': JSON.stringify(this.partyType),
      'Convert To Mix': Object.keys(this.defaultObjConvertToMix).length > 0 ? JSON.stringify(this.defaultObjConvertToMix) : this.defaultObjConvertToMix["enable"] = false,
      // mixing: this.mixing,
    }

    let filtered: any = this.filter(jsonObj, score => mainObj.indexOf(score) != -1);
    filtered.actionId = this.toActionId;
    filtered.userId = this.id;
    // if (this.defaultObjRecut["enable"] == false) {
    //   delete filtered['Recut'];
    // } if (this.defaultObjCertification["enable"] == false) {
    //   delete filtered['Certification'];
    // } if (this.defaultObjRequisition["enable"] == false) {
    //   delete filtered['Requisition'];
    // } if (this.defaultObjObservation["enable"] == false) {
    //   delete filtered['Observation'];
    // } if (this.defaultObjSelfObservation["enable"] == false) {
    //   delete filtered['Self Observation'];
    // } if (this.defaultObjMemoClient["enable"] == false) {
    //   delete filtered['Memo Client'];
    // } if (this.defaultObjConsignmentInStock["enable"] == false) {
    //   delete filtered['Consignment In Stock'];
    // } if (this.defaultObjMemoService["enable"] == false) {
    //   delete filtered['Memo Service'];
    // } if (this.defaultObjCustomerConfirm["enable"] == false) {
    //   delete filtered['Customer Confirm'];
    // } if (this.defaultObjConsignmentConfirm["enable"] == false) {
    //   delete filtered['Consignment Confirm'];
    // } if (this.partyType.length == 0) {
    //   delete filtered['partyType'];
    // }
    if (this.actionName == "Reports") {
      this.defaultData.forEach(el => {
        console.log(el.paramName)
        if (el.paramName == 'reports') {
          filtered.reports = JSON.stringify(el.paramValue)
        }
      })
    }
    console.log(filtered)
    let res;
    console.log(jsonObj)
    res = await this.httpApiService.updateUserDefault(filtered);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.roleId = "";
      await this.configService.showToast(
        "success",
        "User updated successfully"
      );
      await this.modalCtrl.dismiss();
    }
  }

  async getDefaultSetting(actionId, id) {
    console.log(actionId, id)
    this.defaultData = []
    let res: any;
    res = await this.httpApiService.getAllActionPermissionById(actionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue)
      this.flag = 1;
      if (dataValue && dataValue.listOfActionDefaults) {
        this.defaultData = dataValue.listOfActionDefaults;
        console.log("******", this.defaultData)
        if (this.actionName == 'Reports') {
          this.defaultData.forEach(el => {
            if (el.paramName == 'reports') {
              if (el.paramValue) {
                el.paramValue = JSON.parse(el.paramValue)
              }
            }
          })
        }
        await this.getDefaultValue(actionId, id);
        // this.defaultData.forEach(element => {

        // if(element.paramName == "Picked"){
        //   this.pickedFlag = true
        //   // console.log("element.picked",element.paramValue)
        // }
        // if (element.paramName == "Boxed") {
        //   this.boxedFlag = true;
        //   // console.log("element.boxed",element.paramValue)
        // }
        // });
      } else {
        this.flag = 2;
      }
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }

  async findAllTypeForPricing() {
    let res: any;
    res = await this.httpApiService.findAllTypeForPricing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let tempArray = []
      for (let i = 0; i < dataValue.length; i++) {
        tempArray.push({ id: i + 1, name: dataValue[i] })
      }
      this.typeList = tempArray;
      console.log(this.typeList)
    }
  }

  async getDefaultValue(actionId, id) {
    await this.configService.showLoader();
    await this.getAllRole()
    await this.getAllCompany();
    await this.getAllDepartment();
    await this.getAllPriceListName();
    await this.getAllTransType();
    await this.getBoxType();
    await this.findAllTypeForPricing()
    await this.getAllStoneStatus()
    await this.getAllPartyMasterType()
    let res;
    res = await this.httpApiService.findUserActionDefaultsByUserIdAndActionIdUsingDTO(
      id,
      actionId
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.fromDeptidList = [];
      this.toDeptidList = [];
      this.toLocationidList = [];
      this.fromUseridList = [];
      this.voucheridList = [];
      this.fromDeptidList = [];
      this.boxTypeIdList = [];
      this.requestVoucherIdList = []
      this.priceidList = []
      this.toRoleIdList = []
      this.defaultExportList = []
      this.fromRoleIdList = []
      this.multiplePriceIdList = []
      this.partyTypeMemoClientIdList = []
      this.partyTypeMemoClientIdList = []
      this.partyTypeCConfirmIdList = []
      this.voucherbookIdList = []
      this.transTypeIdList = []
      this.partyMasterIdList = []
      this.partyTypeForMemoServiceIdList = []
      this.partyTypeForConsignmentConfirmIdList = []
      this.partyTypeSoldIdList = []
      this.soldEnable = false;
      this.partyTypeForConsignmentInStockIdList = []
      this.partyTypeForCertificationIdList = []
      this.partyTypeIdList = []
      this.partyTypeForRequisitionIdList = []
      this.internalStatusIdList = []
      this.voucherForHoldCustomerIdList = []
      this.voucherForUnHoldCustomerIdList = []
      this.convertToMixEnable = false;
      console.log("QWQ", data.data);
      // let dataValue = data.data;
      let dataValueList = data.data;
      console.log(dataValueList, this.actionName)
      if (this.actionName == 'Reports') {
        if (dataValueList.length > 0) {
          dataValueList.forEach(el => {
            if (el.paramName = 'reports') {
              if (el.paramValue) {
                el.paramValue = JSON.parse(el.paramValue)
              }
            }
          })
          this.defaultData = dataValueList
        } else {
          this.flag = 0;
        }

      } else {
        if (dataValueList.length > 0) {
          dataValueList.filter(el => {
            if (el.paramName == 'voucherBook') {
              this.listOfVoucherBook.forEach(element => {
                if (element.id == el.paramValue) {
                  let data = { id: element.id, bookName: element.bookName };
                  this.voucheridList.push(data);
                }
              });
              this.voucherBook = parseInt(el.paramValue);
            }
            if (el.paramName == 'defaultTemplate') {
              this.defaultTemplateId = el.paramValue
              this.defaultTempalateList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.defaultExportList.push(item)
                }
              })
            }
            if (el.paramName == 'voucherBookList') {
              this.voucherbookIdList = []
              if (!!el.paramValue) {
                this.voucherBookList = JSON.parse(el.paramValue);
                for (let i = 0; i < this.listOfVoucherBook.length; i++) {
                  let flag = this.voucherBookList.some(element => element == this.listOfVoucherBook[i].id)
                  if (flag) {
                    this.voucherbookIdList.push({ id: this.listOfVoucherBook[i].id, bookName: this.listOfVoucherBook[i].bookName })
                  }
                }
              } else {
                this.voucherBookList = []
              }
            }
            if (el.paramName == 'requestVoucherBook') {
              this.listOfVoucherBook.forEach(element => {
                if (element.id == el.paramValue) {
                  let data = { id: element.id, bookName: element.bookName };
                  this.requestVoucherIdList.push(data);
                }
              });
              this.requestVoucherBook = parseInt(el.paramValue);
            }
            if (el.paramName == 'Limit') {
              this.limit = el.paramValue;
            }
            if (el.paramName == 'ctsDifference') {
              this.ctsDifference = el.paramValue;
            }
            if (el.paramName == 'fromDepartment') {
              this.deptList.forEach(element => {
                if (element.id == el.paramValue) {
                  let data = { id: element.id, name: element.name };
                  this.fromDeptidList.push(data);
                }
              });
              this.fromDept = parseInt(el.paramValue);
            }
            if (el.paramName == 'toDepartment') {
              this.deptList.forEach(element => {
                if (element.id == el.paramValue) {
                  let data = { id: element.id, name: element.name };
                  this.toDeptidList.push(data);
                }
              });
              this.toDept = parseInt(el.paramValue);
            }
            if (el.paramName == 'internalStatus') {
              this.internalStatuslist.forEach(element => {
                if (element.id == el.paramValue) {
                  let data = { id: element.id, name: element.name };
                  this.internalStatusIdList.push(data);
                }
              });
              this.internalStatus = el.paramValue;
            }
            if (el.paramName == 'isAllowedStatus') {
              let statusList = JSON.parse(el.paramValue);
              this.isAllowedStatusIdList = [];
              for (let i = 0; i < this.internalStatuslist.length; i++) {
                let flag = statusList.some(element => element == this.internalStatuslist[i].id)
                if (flag) {
                  this.isAllowedStatusIdList.push({ id: this.internalStatuslist[i].id, name: this.internalStatuslist[i].name })
                }
              }
            }
            if (el.paramName == 'toLocation') {
              this.listOfLocations.forEach(element => {
                if (element.id == el.paramValue) {
                  let data = { id: element.id, companyName: element.companyName };
                  this.toLocationidList.push(data);
                }
              });
              this.toLocation = parseInt(el.paramValue);
              this.getAllToUser(this.toLocation);
            }
            if (el.paramName == 'toUser') {
              this.toUseridList = [];
              this.usersList.forEach(element => {
                if (element.id == parseInt(el.paramValue)) {
                  let data = { id: element.id, username: element.username };
                  this.toUseridList.push(data);
                }
              });
              this.toUser = parseInt(el.paramValue);
            }
            if (el.paramName == 'boxType') {
              this.boxTypeList.forEach(element => {
                if (element.type == el.paramValue) {
                  let data = { id: element.id, type: element.type };
                  this.boxTypeIdList.push(data);
                }
              });
              this.boxType = el.paramValue;
            }
            if (el.paramName == 'transType') {
              this.transType = el.paramValue;
              this.transTypeIdList = []
              this.transTypeList.forEach(item => {
                if (item.name == el.paramValue) {
                  this.transTypeIdList.push({ id: item.id, name: item.name })
                }
              })
              console.log(this.transTypeIdList)
            }
            if (el.paramName == 'fromUser') {
              this.usersList.forEach(element => {
                if (element.id == parseInt(el.paramValue)) {
                  let data = { id: element.id, username: element.username };
                  this.fromUseridList.push(data);
                }
              });
              this.fromUser = parseInt(el.paramValue);
            }

            if (el.paramName == 'newStockProcessingStatus') {
              if (!!el.paramValue) {
                this.newStockProcessingStatus = el.paramValue
              }
            }

            if (el.paramName == 'compareJson') {
              if (!!el.paramValue) {
                this.compareJson = el.paramValue
              }
            }

            if (el.paramName == 'fullStockProcess') {
              if (!!el.paramValue) {
                this.companyIdList = []
                let companyList = el.paramValue.split(',')
                companyList = companyList.map(x => Number(x))
                this.listOfLocations.forEach(item => {
                  console.log(item.id)
                  if (companyList.indexOf(item.id) > -1) {
                    let obj = {
                      'id': item.id,
                      'companyName': item.companyName
                    }
                    this.companyIdList.push(obj)
                  }
                })
              }
            }
            if (el.paramName == 'matchFromDB') {
              if (!!el.paramValue) {
                this.matchFromDB = el.paramValue
              }
            }

            if (el.paramName == 'Accusoft sync') {
              if (!!el.paramValue) {
                this.accusoftSync = el.paramValue
              }
            }

            if (el.paramName == 'SVCKAMMapping') {
              if (!!el.paramValue) {
                this.svcKam = el.paramValue
              }
            }

            if (el.paramName == 'priceListName') {
              this.priceidList = []
              this.allPriceNameList.forEach(element => {
                if (element.id == parseInt(el.paramValue)) {
                  let data = { id: element.id, name: element.name };
                  this.priceidList.push(data);
                }
              });
              this.priceListName = parseInt(el.paramValue);
            }
            if (el.paramName == 'basePriceListName') {
              this.basePriceIdList = []
              this.allPriceNameList.forEach(element => {
                if (element.id == parseInt(el.paramValue)) {
                  let data = { id: element.id, name: element.name };
                  this.basePriceIdList.push(data);
                }
              });
              this.basePriceListName = parseInt(el.paramValue);
            }
            if (el.paramName == 'type') {
              this.typeIdList = []
              this.typeList.forEach(element => {
                if (element.name == el.paramValue) {
                  let data = { id: element.id, name: element.name };
                  this.typeIdList.push(data);
                }
              });
              this.type = el.paramValue;
            }
            if (el.paramName == 'toRole') {
              this.toRoleIdList = []
              this.allRoles.forEach(element => {
                if (element.id == parseInt(el.paramValue)) {
                  let data = { id: element.id, roleName: element.roleName };
                  this.toRoleIdList.push(data);
                }
              });
              this.toRole = parseInt(el.paramValue);
            }
            if (el.paramName == 'fromRole') {
              this.fromRoleIdList = []
              this.allRoles.forEach(element => {
                if (element.id == parseInt(el.paramValue)) {
                  let data = { id: element.id, roleName: element.roleName };
                  this.fromRoleIdList.push(data);
                }
              });
              this.fromRole = parseInt(el.paramValue);
            }
            if (el.paramName == 'Return') {
              if (el.paramValue == 'true') {
                this.stoneReturn = true
              } else {
                this.stoneReturn = false
              }
            }
            if (el.paramName == 'Certification') {
              this.defaultObjCertification["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.certificationEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjCertification["isDirect"] = dataJson.isDirect;
                  this.isDirectCertification = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjCertification["partyType"] = dataJson.partyType;
                  this.partyTypeForMemoServiceIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForCertificationIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjCertification["externalStatus"] = dataJson.externalStatus;
                  this.extStatusCertificationIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusCertificationIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.certificationEnable) {
                  this.defaultObjCertification["enable"] = true;
                } else {
                  this.defaultObjCertification["enable"] = false;
                }
              } else {
                this.defaultObjCertification["enable"] = false;
              }
            }
            if (el.paramName == 'Requisition') {
              this.defaultObjRequisition["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.requisitionEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjRequisition["partyType"] = dataJson.partyType;
                  this.partyTypeForRequisitionIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForRequisitionIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjRequisition["externalStatus"] = dataJson.externalStatus;
                  this.extStatusRequisitionIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusRequisitionIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.requisitionEnable) {
                  this.defaultObjRequisition["enable"] = true;
                } else {
                  this.defaultObjRequisition["enable"] = false;
                }
              } else {
                this.defaultObjRequisition["enable"] = false;
              }
            }
            if (el.paramName == 'Recut') {
              this.defaultObjRecut["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.recutEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjRecut["isDirect"] = dataJson.isDirect;
                  this.isDirectRecut = dataJson.isDirect;
                }
                if (!!dataJson.toLocation) {
                  this.toLocation = dataJson.toLocation;
                  this.defaultObjRecut["toLocation"] = this.toLocation;
                  this.toLocationidList = []
                  this.listOfLocations.forEach(item => {
                    if (item.id == this.toLocation) {
                      this.toLocationidList.push({ id: item.id, companyName: item.companyName })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjRecut["externalStatus"] = dataJson.externalStatus;
                  this.extStatusRecutIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusRecutIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.recutEnable) {
                  this.defaultObjRecut["enable"] = true;
                } else {
                  this.defaultObjRecut["enable"] = false;
                }
              } else {
                this.recutEnable = dataJson.enable;
                this.observationEnable = false;
              }
              console.log(this.recutEnable)

            }
            if (el.paramName == 'Observation') {
              this.defaultObjObservation["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.observationEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjObservation["isDirect"] = dataJson.isDirect;
                  this.isDirectObservation = dataJson.isDirect;
                }
                if (!!dataJson.toLocation) {
                  this.toLocation = dataJson.toLocation;
                  this.toLocationObvidList = []
                  this.listOfLocations.forEach(item => {
                    if (item.id == this.toLocation) {
                      this.toLocationObvidList.push({ id: item.id, companyName: item.companyName })
                    }
                  })
                  this.defaultObjObservation["toLocation"] = this.toLocation;
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjObservation["externalStatus"] = dataJson.externalStatus;
                  this.extStatusObservationIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusObservationIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.observationEnable) {
                  this.defaultObjObservation["enable"] = true;
                } else {
                  this.defaultObjObservation["enable"] = false;
                }
              } else {
                this.defaultObjObservation["enable"] = false;
                this.observationEnable = false;
              }
            }
            if (el.paramName == 'Self Observation') {
              this.defaultObjSelfObservation["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.selfObservationEnable = dataJson.enable;
                if (this.selfObservationEnable) {
                  this.defaultObjSelfObservation["enable"] = true;
                } else {
                  this.defaultObjSelfObservation["enable"] = false;
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjSelfObservation["externalStatus"] = dataJson.externalStatus;
                  this.extStatusSelfObvIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusSelfObvIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
              } else {
                this.defaultObjSelfObservation["enable"] = false;
                this.selfObservationEnable = false;
              }

            }
            if (el.paramName == 'Memo Service') {
              this.defaultObjMemoService["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.memoServiceEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjMemoService["isDirect"] = dataJson.isDirect;
                  this.isDirectMemoService = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjMemoService["partyType"] = dataJson.partyType;
                  this.partyTypeForMemoServiceIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForMemoServiceIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjMemoService["externalStatus"] = dataJson.externalStatus;
                  this.extStatusMemoServiceIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusMemoServiceIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.memoServiceEnable) {
                  this.defaultObjMemoService["enable"] = true;
                } else {
                  this.defaultObjMemoService["enable"] = false;
                }
              } else {
                this.defaultObjMemoService["enable"] = false;
              }
            }
            if (el.paramName == 'Memo Client') {
              this.defaultObjMemoClient["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.memoClientEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjMemoClient["isDirect"] = dataJson.isDirect;
                  this.isDirectMemoClient = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjMemoClient["partyType"] = dataJson.partyType;
                  this.partyTypeMemoClientIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeMemoClientIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjMemoClient["externalStatus"] = dataJson.externalStatus;
                  this.extStatusMemoClientIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusMemoClientIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.memoClientEnable) {
                  this.defaultObjMemoClient["enable"] = true;
                } else {
                  this.defaultObjMemoClient["enable"] = false;
                }
              } else {
                this.defaultObjMemoClient["enable"] = false;
              }
            }
            if (el.paramName == 'Sold') {
              this.defaultObjSold["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.soldEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjSold["isDirect"] = dataJson.isDirect;
                  this.isDirectSold = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjSold["partyType"] = dataJson.partyType;
                  this.partyTypeSoldIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeSoldIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjSold["externalStatus"] = dataJson.externalStatus;
                  this.extStatusSoldIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusSoldIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.soldEnable) {
                  this.defaultObjSold["enable"] = true;
                } else {
                  this.defaultObjSold["enable"] = false;
                }
              } else {
                this.soldEnable = false;
              }
              console.log(this.partyTypeSoldIdList, this.soldEnable)
            }
            if (el.paramName == 'Request To KAM') {
              this.defaultObjRequestToKAM["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.requestToKAMEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjRequestToKAM["partyType"] = dataJson.partyType;
                  this.partyTypeRequestToKAMIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeRequestToKAMIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.requestToKAMEnable) {
                  this.defaultObjRequestToKAM["enable"] = true;
                } else {
                  this.defaultObjRequestToKAM["enable"] = false;
                }
              } else {
                this.requestToKAMEnable = false;
              }
              console.log(this.partyTypeRequestToKAMIdList, this.requestToKAMEnable)
            }
            if (el.paramName == 'Hold Customer') {
              this.defaultObjCustomerHold["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.customerHoldEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjCustomerHold["partyType"] = dataJson.partyType;
                  this.partyTypeCustomerHoldIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeCustomerHoldIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.voucherBookId) {
                  this.defaultObjCustomerHold["voucherBookId"] = dataJson.voucherBookId;
                  this.voucherForHoldCustomerIdList = []
                  this.listOfVoucherBook.forEach(item => {
                    console.log(item.id, dataJson.voucherBookId)
                    if (item.id == dataJson.voucherBookId) {
                      this.voucherForHoldCustomerIdList.push({ id: item.id, bookName: item.bookName })
                    }
                  })
                }
                if (this.customerHoldEnable) {
                  this.defaultObjCustomerHold["enable"] = true;
                } else {
                  this.defaultObjCustomerHold["enable"] = false;
                }
              } else {
                this.defaultObjCustomerHold["enable"] = false;
              }
            }
            if (el.paramName == 'Unhold Customer') {
              this.defaultObjCustomerUnHold["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.customerUnHoldEnable = dataJson.enable;
                if (!!dataJson.voucherBookId) {
                  this.defaultObjCustomerUnHold["voucherBookId"] = dataJson.voucherBookId;
                  this.voucherForUnHoldCustomerIdList = []
                  this.listOfVoucherBook.forEach(item => {
                    if (item.id == dataJson.voucherBookId) {
                      this.voucherForUnHoldCustomerIdList.push({ id: item.id, bookName: item.bookName })
                    }
                  })
                }
                if (this.customerUnHoldEnable) {
                  this.defaultObjCustomerUnHold["enable"] = true;
                } else {
                  this.defaultObjCustomerUnHold["enable"] = false;
                }
              } else {
                this.customerUnHoldEnable = false;
              }
            }
            if (el.paramName == 'Unblock For Sale') {
              this.defaultObjUnblockForSale["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.unblockForSaleEnable = dataJson.enable;
                if (!!dataJson.voucherBookId) {
                  this.defaultObjUnblockForSale["voucherBookId"] = dataJson.voucherBookId;
                  this.voucherForUnblockForSaleIdList = []
                  this.listOfVoucherBook.forEach(item => {
                    if (item.id == dataJson.voucherBookId) {
                      this.voucherForUnblockForSaleIdList.push({ id: item.id, bookName: item.bookName })
                    }
                  })
                }
                if (this.unblockForSaleEnable) {
                  this.defaultObjUnblockForSale["enable"] = true;
                } else {
                  this.defaultObjUnblockForSale["enable"] = false;
                }
              } else {
                this.unblockForSaleEnable = false;
              }
            }
            if (el.paramName == 'Block For Sale') {
              this.defaultObjBlockForSale["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.blockForSaleEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjBlockForSale["partyType"] = dataJson.partyType;
                  this.partyTypeBlockForSaleIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeBlockForSaleIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.voucherBookId) {
                  this.defaultObjBlockForSale["voucherBookId"] = dataJson.voucherBookId;
                  this.voucherForBlockForSaleIdList = []
                  this.listOfVoucherBook.forEach(item => {
                    console.log(item.id, dataJson.voucherBookId)
                    if (item.id == dataJson.voucherBookId) {
                      this.voucherForBlockForSaleIdList.push({ id: item.id, bookName: item.bookName })
                    }
                  })
                }
                if (this.blockForSaleEnable) {
                  this.defaultObjBlockForSale["enable"] = true;
                } else {
                  this.defaultObjBlockForSale["enable"] = false;
                }
              } else {
                this.defaultObjBlockForSale["enable"] = false;
              }
            }
            if (el.paramName == 'partyType') {
              if (!!el.paramValue) {
                this.partyTypeIdList = []
                this.partyType = JSON.parse(el.paramValue)
                for (let i = 0; i < this.partyTypeList.length; i++) {
                  let flag = this.partyType.some(element => element == this.partyTypeList[i].type)
                  if (flag) {
                    this.partyTypeIdList.push({ id: this.partyTypeList[i].id, type: this.partyTypeList[i].type })
                  }
                }
              } else {
                this.partyType = []
              }
            }
            if (el.paramName == 'listOfPrices') {
              if (!!el.paramValue) {
                this.multiplePriceIdList = []
                this.listOfPrices = JSON.parse(el.paramValue)
                for (let i = 0; i < this.allPriceNameList.length; i++) {
                  let flag = this.listOfPrices.some(element => element == this.allPriceNameList[i].id)
                  if (flag) {
                    this.multiplePriceIdList.push({ id: this.allPriceNameList[i].id, name: this.allPriceNameList[i].name })
                  }
                }
              } else {
                this.listOfPrices = []
              }
            }
            if (el.paramName == 'Consignment Confirm') {
              this.defaultObjConsignmentConfirm["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.consignmentConfirmEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjCustomerConfirm["isDirect"] = dataJson.isDirect;
                  this.isDirectCustomerConfirm = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjConsignmentConfirm["partyType"] = dataJson.partyType;
                  this.partyTypeForConsignmentConfirmIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForConsignmentConfirmIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjConsignmentConfirm["externalStatus"] = dataJson.externalStatus;
                  this.extStatusCConfirmIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusCConfirmIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.consignmentConfirmEnable) {
                  this.defaultObjConsignmentConfirm["enable"] = true;
                } else {
                  this.defaultObjConsignmentConfirm["enable"] = false;
                }
              } else {
                this.defaultObjConsignmentConfirm["enable"] = false;
              }
            }
            if (el.paramName == 'Consignment In Stock') {
              this.defaultObjConsignmentInStock["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.consignmentInStockEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjConsignmentInStock["isDirect"] = dataJson.isDirect;
                  this.isDirectConsignmentInStock = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjConsignmentInStock["partyType"] = dataJson.partyType;
                  this.partyTypeForConsignmentInStockIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForConsignmentInStockIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjConsignmentInStock["externalStatus"] = dataJson.externalStatus;
                  this.extStatusConsignmentInStockIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusConsignmentInStockIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.consignmentInStockEnable) {
                  this.defaultObjConsignmentInStock["enable"] = true;
                } else {
                  this.defaultObjConsignmentInStock["enable"] = false;
                }
              } else {
                this.defaultObjConsignmentInStock["enable"] = false;
              }
            }
            if (el.paramName == 'Customer Confirm') {
              this.defaultObjCustomerConfirm["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.customerConfirmEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjCustomerConfirm["partyType"] = dataJson.partyType;
                  this.partyTypeCConfirmIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeCConfirmIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjCustomerConfirm["externalStatus"] = dataJson.externalStatus;
                  this.extStatusConsignmentConfirmIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusConsignmentConfirmIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.customerConfirmEnable) {
                  this.defaultObjCustomerConfirm["enable"] = true;
                } else {
                  this.defaultObjCustomerConfirm["enable"] = false;
                }
              } else {
                this.defaultObjCustomerConfirm["enable"] = false;
              }
            }
            if (el.paramName == 'Convert To Mix') {
              this.defaultObjConvertToMix["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                console.log(el.paramValue)
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.convertToMixEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjConvertToMix["isDirect"] = dataJson.isDirect;
                  this.isDirectConvertToMix = dataJson.isDirect;
                }
                if (this.convertToMixEnable) {
                  this.defaultObjConvertToMix["enable"] = true;
                } else {
                  this.defaultObjConvertToMix["enable"] = false;
                }
              } else {
                this.convertToMixEnable = false;
              }
              console.log(this.convertToMixEnable)
            }
          })
        } else {
          this.flag = 0;
        }
      }

    }
  }

  async getAllCompany() {
    let res: any;
    // let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
    }
  }
  async getAllToUser(toLocation) {
    this.usersList = [];
    this.toUser = null;
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllUsersByCompany(appName, toLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.length > 0) {
        this.usersList = dataValue;
      } else {
        this.usersList = [];
      }
    }
  }

  async getAllActions() {
    let res: any;
    res = await this.httpApiService.getAllActionsByUserID(this.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
      this.items = this.listOfActions;
    }
  }
  async getAllDepartment() {
    let res: any;
    res = await this.httpApiService.getAllDepartment(
      this.configService.appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptList = data.data;
    }
  }

  async getAppName() {
    this.appName = await this.configService.getAppName();
  }

  async getFromLocation() {
    this.companyName = await this.configService.getFromLocation();
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async getAllRole() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allRoles = data.data;
    }
  }

  //get internal status list

  async getAllStoneStatus() {
    let res: any;
    res = await this.httpApiService.getStatus();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let internalArray = []
      let externalArray = []
      if (dataValue.length > 0) {
        dataValue.forEach(el => {
          if (el.type == 'Internal' && el.isActive == true) {
            internalArray.push(el);
          } else if (el.type == 'External' && el.isActive == true) {
            externalArray.push(el);
          }
        })
        this.internalStatuslist = internalArray;
        this.externalStatuslist = externalArray;
      }
      console.log(this.internalStatuslist)
    }
  }

  //Create New User

  async createUser() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let jsonObj = {
        username: this.username,
        password: this.password,
        companyName: this.companyName,
        appName: this.appName,
        fullName: this.fullName,
        mobileNumber: this.mobileNumber,
        email: this.email,
        departmentId: this.dept,
        scanBoardUrl: this.scanBoardUrl,
        isPasswordChange: this.isPasswordChange,
        url: window.location.origin + '/changePassword',
      };
      let res: any;
      res = await this.httpApiService.createUser(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.allRoles = data;
        await this.configService.showToast(
          "success",
          "User created successfully"
        );
        await this.modalCtrl.dismiss();
        this.resetForm();
      }
    }
  }

  // Save Role for User

  async saveRoleForUser() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        roleId: this.roleId,
        userId: this.id
      };
      let res: any;
      res = await this.httpApiService.saveRoleForUser(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.roleId = "";
        await this.configService.showToast(
          "success",
          "Role assigned successfully"
        );
        await this.modalCtrl.dismiss()
      }
    }
  }

  async validateControls() {
    if (this.isModal == "addUserRole") {
      if (
        this.roleId != "" &&
        this.roleId != null &&
        this.roleId != undefined
      ) {
        return false;
      } else {
        await this.configService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return true;
      }
    } else {
      if (
        this.username != "" &&
        this.password != "" &&
        this.appName != "" &&
        this.fullName != "" &&
        this.companyName != ""
      ) {
        return false;
      } else {
        await this.configService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return true;
      }
    }
  }

  filter(obj, predicate) {
    return Object.keys(obj)
      .filter(key => predicate(key, obj[key]))
      .reduce((res, key) => (res[key] = obj[key], res), {});
  }

  async saveUserDefault() {
    let jsonObj: any;
    let mainObj = this.defaultData.map(function (key, value) {
      return key.paramName;
    })
    jsonObj = {
      userId: this.id,
      actionId: this.toActionId,
      fromDepartment: this.fromDept,
      toDepartment: this.toDept,
      toUser: this.toUser,
      toLocation: this.toLocation,
      fromUser: this.fromUser,
      voucherBook: this.voucherBook,
      requestVoucherBook: this.requestVoucherBook,
      toRole: this.toRole,
      fromRole: this.fromRole,
      defaultTemplate: this.defaultTemplateId,
      type: this.type,
      Limit: this.limit,
      ctsDifference: this.ctsDifference,
      internalStatus: this.internalStatus,
      priceListName: this.priceListName,
      basePriceListName: this.basePriceListName,
      listOfPrices: JSON.stringify(this.listOfPrices),
      boxType: this.boxType,
      transType: this.transType,
      voucherBookList: JSON.stringify(this.voucherBookList),
      isAllowedStatus: JSON.stringify(this.isAllowedStatus),
      matchFromDB: this.matchFromDB,
      newStockProcessingStatus: this.newStockProcessingStatus,
      compareJson: this.compareJson,
      'SVCKAMMapping': this.svcKam,
      'Accusoft sync': this.accusoftSync,
      'Return': this.stoneReturn,
      'Recut': Object.keys(this.defaultObjRecut).length > 0 ? JSON.stringify(this.defaultObjRecut) : this.defaultObjRecut["enable"] = false,
      'Observation': Object.keys(this.defaultObjObservation).length > 0 ? JSON.stringify(this.defaultObjObservation) : this.defaultObjObservation["enable"] = false,
      'Self Observation': Object.keys(this.defaultObjSelfObservation).length > 0 ? JSON.stringify(this.defaultObjSelfObservation) : this.defaultObjSelfObservation["enable"] = false,
      'Requisition': Object.keys(this.defaultObjRequisition).length > 0 ? JSON.stringify(this.defaultObjRequisition) : this.defaultObjRequisition["enable"] = false,
      'Certification': Object.keys(this.defaultObjCertification).length > 0 ? JSON.stringify(this.defaultObjCertification) : this.defaultObjCertification["enable"] = false,
      'Consignment Confirm': Object.keys(this.defaultObjConsignmentConfirm).length > 0 ? JSON.stringify(this.defaultObjConsignmentConfirm) : this.defaultObjConsignmentConfirm["enable"] = false,
      'Memo Client': Object.keys(this.defaultObjMemoClient).length > 0 ? JSON.stringify(this.defaultObjMemoClient) : this.defaultObjMemoClient["enable"] = false,
      'Sold': Object.keys(this.defaultObjSold).length > 0 ? JSON.stringify(this.defaultObjSold) : this.defaultObjSold["enable"] = false,
      'Request To KAM': Object.keys(this.defaultObjRequestToKAM).length > 0 ? JSON.stringify(this.defaultObjRequestToKAM) : this.defaultObjRequestToKAM["enable"] = false,
      'Hold Customer': Object.keys(this.defaultObjCustomerHold).length > 0 ? JSON.stringify(this.defaultObjCustomerHold) : this.defaultObjCustomerHold["enable"] = false,
      'Unhold Customer': Object.keys(this.defaultObjCustomerUnHold).length > 0 ? JSON.stringify(this.defaultObjCustomerUnHold) : this.defaultObjCustomerUnHold["enable"] = false,
      'Unblock For Sale': Object.keys(this.defaultObjUnblockForSale).length > 0 ? JSON.stringify(this.defaultObjUnblockForSale) : this.defaultObjUnblockForSale["enable"] = false,
      'Customer Confirm': Object.keys(this.defaultObjCustomerConfirm).length > 0 ? JSON.stringify(this.defaultObjCustomerConfirm) : this.defaultObjCustomerConfirm["enable"] = false,
      'Memo Service': Object.keys(this.defaultObjMemoService).length > 0 ? JSON.stringify(this.defaultObjMemoService) : this.defaultObjMemoService["enable"] = false,
      'Consignment In Stock': Object.keys(this.defaultObjConsignmentInStock).length > 0 ? JSON.stringify(this.defaultObjConsignmentInStock) : this.defaultObjConsignmentInStock["enable"] = false,
      'partyType': JSON.stringify(this.partyType),
      'Convert To Mix': Object.keys(this.defaultObjConvertToMix).length > 0 ? JSON.stringify(this.defaultObjConvertToMix) : this.defaultObjConvertToMix["enable"] = false,
      // mixing: this.mixing,
    }
    console.log(jsonObj)

    let filtered: any = this.filter(jsonObj, score => mainObj.indexOf(score) != -1);

    if (this.actionName == "Reports") {
      this.defaultData.forEach(el => {
        console.log(el.paramName)
        if (el.paramName == 'reports') {
          // el.paramValue.forEach(item => {
          //   delete item.reportValue.departmentIdList;
          //   delete item.reportValue.transTypeIdList;
          // })
          filtered.reports = JSON.stringify(el.paramValue)
        }
      })
    }
    filtered.actionId = this.toActionId;
    filtered.userId = this.id;
    console.log(filtered)
    if (this.defaultObjRecut["enable"] == false) {
      delete filtered['Recut'];
    } if (this.defaultObjObservation["enable"] == false) {
      delete filtered['Observation'];
    } if (this.defaultObjCertification["enable"] == false) {
      delete filtered['Certification'];
    } if (this.defaultObjRequisition["enable"] == false) {
      delete filtered['Requisition'];
    } if (this.defaultObjSelfObservation["enable"] == false) {
      delete filtered['Self Observation'];
    } if (this.defaultObjMemoClient["enable"] == false) {
      delete filtered['Memo Client'];
    } if (this.defaultObjConsignmentInStock["enable"] == false) {
      delete filtered['Consignment In Stock'];
    } if (this.defaultObjMemoService["enable"] == false) {
      delete filtered['Memo Service'];
    } if (this.defaultObjCustomerConfirm["enable"] == false) {
      delete filtered['Customer Confirm'];
    } if (this.defaultObjConsignmentConfirm["enable"] == false) {
      delete filtered['Consignment Confirm'];
    } if (this.partyType.length == 0) {
      delete filtered['partyType'];
    }
    let res;
    res = await this.httpApiService.addUserDefault(filtered);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.roleId = "";
      await this.configService.showToast(
        "success",
        "User default update successfully"
      );
      await this.modalCtrl.dismiss();
    }
  }

  resetForm() {
    this.username = "";
    this.password = "";
    this.mobileNumber = "";
    this.toDeptidList = [];
    this.dept = null
    this.email = "";
    this.fullName = "";
  }

  checkRecut(isDirect) {
    if (this.recutEnable) {
      this.defaultObjRecut["enable"] = true;
      if (isDirect) {
        this.defaultObjRecut["isDirect"] = true;
      }
      else {
        this.defaultObjRecut["isDirect"] = false;
      }
    } else {
      this.defaultObjRecut["enable"] = false;
      delete this.defaultObjRecut["toLocation"];
      delete this.defaultObjRecut["isDirect"];
      delete this.defaultObjRecut["externalStatus"];
    }
    console.log(this.defaultObjRecut)
  }

  checkObservation(isDirect) {
    if (this.observationEnable) {
      this.defaultObjObservation["enable"] = true;
      if (isDirect) {
        this.defaultObjObservation["isDirect"] = true;
      }
      else {
        this.defaultObjObservation["isDirect"] = false;
      }
    } else {
      this.defaultObjObservation["enable"] = false;
      delete this.defaultObjObservation["toLocation"];
      delete this.defaultObjObservation["isDirect"];
      delete this.defaultObjObservation["externalStatus"];
    }
    console.log(this.defaultObjObservation)
  }
  checkSelfObservation() {
    if (this.selfObservationEnable) {
      this.defaultObjSelfObservation["enable"] = true;
    } else {
      this.defaultObjSelfObservation["enable"] = false;
      delete this.defaultObjSelfObservation["externalStatus"];
    }
    console.log(this.defaultObjSelfObservation)
  }

  checkMemoClient(isDirect) {
    if (this.memoClientEnable) {
      this.defaultObjMemoClient["enable"] = true;
      if (isDirect) {
        this.defaultObjMemoClient["isDirect"] = true;
      }
      else {
        this.defaultObjMemoClient["isDirect"] = false;
      }
    } else {
      this.partyTypeMemoClientIdList = []
      this.defaultObjMemoClient["enable"] = false;
      delete this.defaultObjMemoClient["partyType"];
      delete this.defaultObjMemoClient["externalStatus"];
    }
    console.log(this.defaultObjMemoClient)
  }

  checkSold(isDirect) {
    if (this.soldEnable) {
      this.defaultObjSold["enable"] = true;
      if (isDirect) {
        this.defaultObjSold["isDirect"] = true;
      }
      else {
        this.defaultObjSold["isDirect"] = false;
      }
    } else {
      this.partyTypeSoldIdList = []
      this.defaultObjSold["enable"] = false;
      delete this.defaultObjSold["partyType"];
      delete this.defaultObjSold["isDirect"];
      delete this.defaultObjSold["externalStatus"];
    }
    console.log(this.defaultObjSold)
  }

  checkRequestToKAM() {
    if (this.requestToKAMEnable) {
      this.defaultObjRequestToKAM["enable"] = true;
    } else {
      this.partyTypeRequestToKAMIdList = []
      this.defaultObjRequestToKAM["enable"] = false;
      delete this.defaultObjRequestToKAM["partyType"];
    }
    console.log(this.defaultObjRequestToKAM)
  }

  checkConvertToMix(isDirect) {
    if (this.convertToMixEnable) {
      this.defaultObjConvertToMix["enable"] = true;
      if (isDirect) {
        this.defaultObjConvertToMix["isDirect"] = true;
      }
      else {
        this.defaultObjConvertToMix["isDirect"] = false;
      }
    } else {
      this.defaultObjConvertToMix["enable"] = false;
      delete this.defaultObjConvertToMix["isDirect"];
    }
    console.log(this.defaultObjConvertToMix)
  }

  checkCustomerHold() {
    if (this.customerHoldEnable) {
      this.defaultObjCustomerHold["enable"] = true;
    } else {
      this.partyTypeCustomerHoldIdList = []
      this.voucherForHoldCustomerIdList = []
      this.defaultObjCustomerHold["enable"] = false;
      delete this.defaultObjCustomerHold["partyType"];
      delete this.defaultObjCustomerHold["voucherBookId"];
      delete this.defaultObjCustomerHold["externalStatus"];
    }
    console.log(this.defaultObjCustomerHold)
  }

  checkBlockForSale() {
    if (this.blockForSaleEnable) {
      this.defaultObjBlockForSale["enable"] = true;
    } else {
      this.partyTypeBlockForSaleIdList = []
      this.voucherForBlockForSaleIdList = []
      this.defaultObjBlockForSale["enable"] = false;
      delete this.defaultObjBlockForSale["partyType"];
      delete this.defaultObjBlockForSale["voucherBookId"];
    }
    console.log(this.defaultObjBlockForSale)
  }

  checkCustomerUnHold() {
    if (this.customerUnHoldEnable) {
      this.defaultObjCustomerUnHold["enable"] = true;
    } else {
      this.voucherForUnHoldCustomerIdList = []
      this.defaultObjCustomerUnHold["enable"] = false;
      delete this.defaultObjCustomerHold["voucherBookId"];
    }
    console.log(this.defaultObjCustomerUnHold)
  }

  checkUnblockForSale() {
    if (this.unblockForSaleEnable) {
      this.defaultObjUnblockForSale["enable"] = true;
    } else {
      this.voucherForUnblockForSaleIdList = []
      this.defaultObjUnblockForSale["enable"] = false;
      delete this.defaultObjUnblockForSale["voucherBookId"];
    }
    console.log(this.defaultObjUnblockForSale)
  }


  checkCConfirm(isDirect) {
    if (this.customerConfirmEnable) {
      this.defaultObjCustomerConfirm["enable"] = true;
      if (isDirect) {
        this.defaultObjCustomerConfirm["isDirect"] = true;
      } else {
        this.defaultObjCustomerConfirm["isDirect"] = false;
      }
    } else {
      this.partyTypeCConfirmIdList = []
      this.defaultObjCustomerConfirm["enable"] = false;
      delete this.defaultObjCustomerConfirm["partyType"];
      delete this.defaultObjCustomerConfirm["externalStatus"];

    }
    console.log(this.defaultObjCustomerConfirm)
  }

  checkMemoService(isDirect) {
    if (this.memoServiceEnable) {
      this.defaultObjMemoService["enable"] = true;
      if (isDirect) {
        this.defaultObjMemoService["isDirect"] = true;
      } else {
        this.defaultObjMemoService["isDirect"] = false;
      }
    } else {
      this.partyTypeForMemoServiceIdList = []
      this.defaultObjMemoService["enable"] = false;
      delete this.defaultObjMemoService["partyType"];
      delete this.defaultObjMemoService["isDirect"];
      delete this.defaultObjMemoService["externalStatus"];
    }
    console.log(this.defaultObjMemoService)
  }

  checkConsignmentConfirm(isDirect) {
    if (this.consignmentConfirmEnable) {
      this.defaultObjConsignmentConfirm["enable"] = true;
      if (isDirect) {
        this.defaultObjConsignmentConfirm["isDirect"] = true;
      } else {
        this.defaultObjConsignmentConfirm["isDirect"] = false;
      }
    } else {
      this.partyTypeForConsignmentConfirmIdList = []
      this.defaultObjConsignmentConfirm["enable"] = false;
      delete this.defaultObjConsignmentConfirm["partyType"];
      delete this.defaultObjConsignmentConfirm["isDirect"];
      delete this.defaultObjConsignmentConfirm["externalStatus"];
    }
    console.log(this.defaultObjConsignmentConfirm)
  }


  checkConsignmentInStock(isDirect) {
    if (this.consignmentInStockEnable) {
      this.defaultObjConsignmentInStock["enable"] = true;
      if (isDirect) {
        this.defaultObjConsignmentInStock["isDirect"] = true;
      } else {
        this.defaultObjConsignmentInStock["isDirect"] = false;
      }
    } else {
      this.partyTypeForConsignmentInStockIdList = []
      this.defaultObjConsignmentInStock["enable"] = false;
      delete this.defaultObjConsignmentInStock["partyType"];
      delete this.defaultObjConsignmentInStock["isDirect"];
      delete this.defaultObjConsignmentInStock["externalStatus"];
    }
    console.log(this.defaultObjConsignmentInStock)
  }

  checkCertification(isDirect) {
    if (this.certificationEnable) {
      this.defaultObjCertification["enable"] = true;
      if (isDirect) {
        this.defaultObjCertification["isDirect"] = true;
      } else {
        this.defaultObjCertification["isDirect"] = false;
      }
    } else {
      this.partyTypeForCertificationIdList = []
      this.defaultObjCertification["enable"] = false;
      delete this.defaultObjCertification["partyType"];
      delete this.defaultObjCertification["isDirect"];
      delete this.defaultObjCertification["externalStatus"];
    }
    console.log(this.defaultObjCertification)
  }

  checkRequisition() {
    if (this.requisitionEnable) {
      this.defaultObjRequisition["enable"] = true;
    } else {
      this.partyTypeForRequisitionIdList = []
      this.defaultObjRequisition["enable"] = false;
      delete this.defaultObjRequisition["partyType"];
      delete this.defaultObjRequisition["externalStatus"];
    }
    console.log(this.defaultObjRequisition)
  }

  checkForReport(paramValueList, item, index) {
    console.log(paramValueList, item, index)
    if (item.reportValue.enable) {

      paramValueList[index].reportValue.enable = true;
    } else {
      item.reportValue.departmentList = []
      item.reportValue.transTypeList = []
      item.reportValue.pricingJson = []
      item.reportValue.departmentIdList = []
      item.reportValue.showPrice = false;
      item.reportValue.transTypeIdList = []
      paramValueList[index].reportValue.enable = false;
    }
  }

  addInPricing(dataObj) {
    this.defaultData.filter(el => {
      if (el.paramValue) {
        el.paramValue.filter(item => {
          if (item.reportName == dataObj.reportName) {
            let data = { department: this.deptForPriceInReport, transType: this.transTypeForPriceInReport, priceListName: this.priceForReport }
            if (item.reportValue.pricingJson.length > 0) {
              let isExist = false;
              isExist = item.reportValue.pricingJson.some(x => (x.department.id == this.deptForPriceInReport.id && x.transType.id == this.transTypeForPriceInReport.id));
              if (!isExist) {
                item.reportValue.pricingJson.push(data);
              } else {
                this.configService.showToast('error', 'Record already exist')
              }
            } else {
              item.reportValue.pricingJson.push(data);
            }
          }
        })
      }
    })

    this.deptForPriceInReport = null;
    this.transTypeForPriceInReport = null;
    this.priceForReport = null;
    this.deptForReportIdList = [];
    this.transTypePriceIdList = [];
    this.priceForReportIdList = [];
    console.log(this.defaultData)
  }

  removeFromPricingJson(pricingItemArr, index) {
    console.log(pricingItemArr, index)
    pricingItemArr.splice(index, 1)
  }

  async resetUserActionDefaultByUserIdAndActionid() {
    let res: any;
    await this.configService.showLoader()
    res = await this.httpApiService.resetUserActionDefaultByUserIdAndActionid(this.selectedUserId, this.toActionId);
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.dismiss();
      await this.modalCtrl.dismiss()
    }
  }

  async getVoucherBookListForPendingApproval() {
    let res: any;
    res = await this.httpApiService.getAllVouchersForSelectedTypes(
      'Returnable,Non Returnable'
    );
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfVoucherBook = data.data;
      console.log(this.listOfVoucherBook);
    }
  }

  async getAllDefaultTemplates() {
    let res, userTemp: any;
    res = await this.httpApiService.findAllTemplate(true)
    userTemp = await this.httpApiService.fetchAllTemplateByUserId(this.selectedUserId)
    let data = res.json();
    let data1 = userTemp.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.defaultTempalateList = this.defaultTempalateList.concat(data.data);
      this.defaultTempalateList = this.defaultTempalateList.concat(data1.data);
    }
  }
}
