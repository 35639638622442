import { Component, OnInit, Input, ViewChild } from "@angular/core";

import {
  ModalController,
  NavParams,
  AlertController,
  LoadingController,
} from "@ionic/angular";

import * as moment from "moment";

import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-manage-requests",
  templateUrl: "./manage-requests.page.html",
  styleUrls: ["./manage-requests.page.scss"],
})
export class ManageRequestsPage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;

  @Input() voucherRequestData: any;

  public voucherId: any;
  public data: any;
  public flag: any;
  public memo: any;
  public userData: any;
  public requestAction: any = [];
  public requestIdList: any = [];
  public selectedAction: any;
  public labList: any;
  public filtered = [];
  public allCustomer: any;
  public requestId: any;
  public labId: any;
  public requestActionFlag: any;
  public userId: any;
  public voucherBookId: any;
  public actionId: any;
  public type: any;
  public columns: any;
  public allCustomerContact: any;
  public noPagination = true;
  public stoneForCertificate: any[] = [];
  public stoneForCertificateId = [];
  public requestJson: any = {};
  public selected = [];
  public listOfLocations: any;
  public usersList: any;
  public customerId: any;
  public toUser: any;
  public customerIdList = [];
  public allKAMPersonList = [];
  public voucherType: any;
  public isSelect: Boolean;
  public status = "External";
  public custIdList = [];
  public userIdList = [];
  public locationIdList = [];
  public columnsForCertification = [];
  public servicesList = [];
  public showSelected = true;
  public roleId: any;
  public companyDetails: any;
  public serviceIdList = [];
  public salesPersonName: any;
  public flagOpenDoc = false;
  public customerDetails: any;
  public nameOfPersonReceivingGood: any;
  public transType: any;
  public deliveryChallanNo: any;
  public locationId: any;
  public fxRate: any;
  public customerContactId: any;
  public transporterDetails: any;
  public lab: any;
  public boardBaseUrl: any;
  public terms: any;
  public disc1: any;
  public disc2: any;
  public inventoryManagerType: any;
  public partyType: any;
  public kamIdList = [];
  public rfidList = [];
  public modeOfTransport: any;
  public url: any;
  public remarks: any;
  public isMerge: Boolean;
  public multipleChallanList = [];
  public trackingNo: any;
  public toRole: any;
  public pageNumber = 0;
  public noOfRecords = 3000;
  public labServiceList = [];
  public pageSize = 50;
  public ledOnStonesIndex: any = [];
  public boxDetail = [];
  public scanCount = 0;
  public currentScanCount = 0;
  public invoiceDate = "";
  public scannedRFid = [];
  public ledOn = false;
  public addSrNo = null;
  public companyId: any;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public httpApiService: HttpApiService,
    public configService: ConfigService
  ) {
    this.data = this.navParams.get("data");
    this.flag = this.navParams.get("type");
    this.inventoryManagerType = this.navParams.get("inventoryManagerType");
  }

  async dropDownSelection(ev) {
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "requestAction") {
      this.isSelect = false;
      if (idList.length > 0) {
        this.kamIdList = [];
        this.stoneForCertificate = [];
        this.requestId = idList[0];
        this.requestActionFlag = obj[0].actionName;
        this.selectedAction = obj[0].actionName;
        //for request
        if (this.requestActionFlag == "Certification") {
          await this.getDefaultSetting(this.userId, this.requestId);
          this.getAllCustomer(this.selectedAction);
          // this.getTransporterDetail('Transport');
        } else if (
          this.requestActionFlag == "Recut" ||
          this.requestActionFlag == "Observation"
        ) {
          this.userIdList = [];
          this.toUser = null;
          this.locationIdList = [];
          this.getAllCompany();
          // this.getTransporterDetail('Transport');
          this.getDefaultSetting(this.userId, this.requestId);
        } else if (this.requestActionFlag == "Requisition") {
          this.getDefaultSetting(this.userId, this.requestId);
          this.findUserByRequestAction(this.selectedAction);
          // this.getAllKAMPerson();
        } else if (this.requestActionFlag == "Memo Service") {
          this.customerIdList = [];
          this.custIdList = [];

          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.selectedAction);
        } else if (this.requestActionFlag == "Self Observation") {
          this.customerIdList = [];
          this.custIdList = [];
          await this.findUserByRequestAction(this.selectedAction);
          // this.getAllKAMPerson();
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          // this.getAllCustomer(this.selectedAction);
        } else if (this.requestActionFlag == "Convert To Mix") {
          this.customerIdList = [];
          this.custIdList = [];
          await this.getDefaultSetting(this.userId, this.requestId);
          await this.getRequestedStoneList(
            this.selectedAction,
            null,
            this.pageNumber,
            this.noOfRecords,
            null
          );
          this.allCustomerContact = [];
        } else if (this.requestActionFlag == "Memo Client") {
          this.customerIdList = [];
          this.custIdList = [];
          this.customerId = null;
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.selectedAction);
        } else if (this.requestActionFlag == "Sold") {
          this.customerIdList = [];
          this.custIdList = [];
          this.customerId = null;
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.selectedAction);
        } else if (this.requestActionFlag == "Customer Hold") {
          this.customerIdList = [];
          this.custIdList = [];
          this.customerId = null;
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.selectedAction);
        } else if (this.requestActionFlag == "Customer Confirm") {
          this.customerIdList = [];
          this.custIdList = [];
          this.customerId = null;
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.selectedAction);
        } else if (
          this.requestActionFlag == "Consignment Confirm" ||
          this.requestActionFlag == "Consignment In Stock"
        ) {
          this.customerIdList = [];
          this.custIdList = [];
          this.customerId = null;
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.selectedAction);
        }
      } else {
        this.selected = [];
        this.customerIdList = [];
        this.customerId = [];
        this.custIdList = [];
        this.locationId = null;
        this.stoneForCertificate = [];
        this.requestActionFlag = "";
      }
      //form data
    } else if (property == "Certification") {
      this.selected = [];
      if (idList.length > 0) {
        this.serviceIdList = [];
        this.lab = obj[0].partyName;
        this.customerId = idList[0];
        this.getCustomerDetails(idList[0]);
        this.getAllServiceByLab(this.customerId);
        this.getRequestedStoneList(
          this.selectedAction,
          this.customerId,
          this.pageNumber,
          this.noOfRecords,
          null
        );
      } else {
        this.serviceIdList = [];
        this.labServiceList = [];
        this.customerIdList = [];
        this.allCustomerContact = [];
        this.lab = null;
        this.customerId = null;
        this.stoneForCertificate = [];
      }
    } else if (property == "partyMaster") {
      this.selected = [];
      if (idList.length > 0) {
        this.customerId = idList[0];
        // this.lab = obj[0].partyName;
        // console.log(obj,this.lab)
        this.getCustomerDetails(this.customerId);
        this.getAllCustomerContact(this.customerId);
      } else {
        this.customerIdList = [];
        this.allCustomerContact = [];
        this.customerId = null;
        this.stoneForCertificate = [];
      }
    } else if (property == "partyContact") {
      this.selected = [];
      if (idList.length > 0) {
        this.customerContactId = idList[0];
        this.getRequestedStoneList(
          this.selectedAction,
          this.customerContactId,
          this.pageNumber,
          this.noOfRecords,
          null
        );
      } else {
        this.custIdList = [];
        this.customerContactId = null;
      }
    } else if (property == "Requisition") {
      this.selected = [];
      this.labId = idList[0];
      if (idList) {
        this.getRequestedStoneList(
          this.selectedAction,
          idList,
          this.pageNumber,
          this.noOfRecords,
          null
        );
      }
    } else if (property == "KAMPerson") {
      this.selected = [];
      this.toUser = idList[0];
      console.log(this.selectedAction);
      this.getStoneListByKAM(
        this.selectedAction,
        idList,
        null,
        this.pageNumber,
        this.noOfRecords
      );
    } else if (property == "Recut" || property == "Observation") {
      this.selected = [];
      if (idList.length > 0) {
        this.locationId = idList[0];
        this.toCompanyDetailByName(obj[0].companyName);
        this.getAllToUser(this.locationId);
      } else {
        this.locationId = null;
        this.userIdList = [];
        this.toUser = null;
      }
    } else if (property == "User") {
      this.selected = [];
      if (idList.length > 0) {
        this.nameOfPersonReceivingGood = obj[0].username;
        console.log(this.nameOfPersonReceivingGood);
        this.toUser = idList[0];
        this.getRequestedStoneList(
          this.selectedAction,
          idList,
          this.pageNumber,
          this.noOfRecords,
          null
        );
      } else {
        this.stoneForCertificate = [];
      }
    } else if (property == "selectService") {
      this.selected = [];
      if (idList.length > 0) {
        this.stoneForCertificate = [];
        this.getRequestedStoneList(
          this.selectedAction,
          this.customerId,
          this.pageNumber,
          this.noOfRecords,
          idList
        );
      } else {
        this.getRequestedStoneList(
          this.selectedAction,
          this.customerId,
          this.pageNumber,
          this.noOfRecords,
          null
        );
        this.stoneForCertificate = [];
      }
    } else if (property == "additionalServices") {
      this.selected = [];
      console.log(idList, obj, masterId);
      if (idList.length > 0) {
        this.stoneForCertificate.filter((el) => {
          if (el.id == masterId) {
            delete el.masterId;
            el[masterId] = idList;
            el.isSelected = true;
          }
        });
      } else {
        this.stoneForCertificate.filter((el) => {
          if (el.id == masterId) {
            delete el.masterId;
            el.isSelected = false;
          }
        });
      }
      console.log(this.stoneForCertificate);
    }
  }

  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId();
    this.multipleChallanList = [
      { id: 1, name: "ORIGINAL", isSelect: true },
      { id: 2, name: "DUPLICATE/TRANSPORTER", isSelect: true },
      { id: 3, name: "TRIPLICATE", isSelect: true },
    ];
    await this.getUserData();
    await this.getAllActions();
    await this.getCompanyDetail();
    // await this.getLatestRateByCurrencyName();
    this.columns = [
      {
        name: "Sr No",
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        },
      },
      { name: "StoneId", prop: "stoneId" },
      { name: "Cts", prop: "size" },
      { name: "Rate/Cts", prop: "requestPrice" },
      { name: "Exg Rate", prop: "exgRate" },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Purity", prop: "clarity" },
      { name: "By", prop: "requestUser" },
    ];
    this.columnsForCertification = [
      {
        name: "Sr No",
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        },
      },
      { name: "StoneId", prop: "stoneId" },
      { name: "Services", prop: "" },
      { name: "Cts", prop: "size" },
      { name: "Rate/Cts", prop: "requestPrice" },
      { name: "Exg Rate", prop: "exgRate" },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Purity", prop: "clarity" },
      { name: "By", prop: "requestUser" },
    ];

    if (!!this.voucherRequestData) {
      console.log(this.voucherRequestData);
      this.customerContactId = !!this.voucherRequestData.customerContactId
        ? this.voucherRequestData.customerContactId
        : null;
      this.customerId = !!this.voucherRequestData.partyMasterId
        ? this.voucherRequestData.partyMasterId
        : null;
      this.toUser = !!this.voucherRequestData.toUserId
        ? this.voucherRequestData.toUserId
        : null;
      let toLocation = !!this.voucherRequestData.toLocation
        ? this.voucherRequestData.toLocation
        : null;
      this.requestIdList = [];
      this.requestAction.filter((el) => {
        if (el.actionName == this.voucherRequestData.requestFor) {
          this.requestId = el.id;
          this.requestActionFlag = el.actionName;
          this.selectedAction = el.actionName;
          if (
            this.requestActionFlag == "Recut" ||
            this.requestActionFlag == "Observation"
          ) {
            this.toCompanyDetailByName(toLocation);
          } else if (
            this.requestActionFlag == "Self Observation" ||
            this.requestActionFlag == "Convert To Mix"
          ) {
          } else {
            this.getCustomerDetails(this.customerId);
          }
          this.getDefaultSetting(this.userId, this.requestId);
        }
      });

      await this.getRequestedStoneListByVoucherId(
        this.voucherRequestData.id,
        this.voucherRequestData.requestFor,
        this.pageNumber,
        this.noOfRecords
      );
    }
  }

  async backToTransfer() {
    this.flagOpenDoc = false;
    this.selected = [];
    this.isSelect = false;
    this.stoneForCertificateId = [];
    this.stoneForCertificate = [];
    this.requestJson = [];
    this.customerContactId = null;
    this.customerIdList = [];
    this.locationIdList = [];
    this.locationId = null;
    this.serviceIdList = [];
  }

  async getAllCustomer(action) {
    let res: any;
    res = await this.httpApiService.findByRequestAction(action);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allCustomer = data.data;
      console.log("getAllCustomers", this.allCustomer);
    }
  }

  async getAllKAMPerson() {
    let res: any;
    res = await this.httpApiService.getDepartmentWiseKamPersonList("KAM Sales");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allKAMPersonList = data.data;
      console.log("get All Customer", this.allCustomer);
    }
  }

  async getAllServiceByLab(id) {
    let res: any;
    res = await this.httpApiService.getAllServiceByLab(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.labServiceList = data.data;
      console.log("get All Service List", this.labServiceList);
    }
  }

  async findUserByRequestAction(action) {
    let res: any;
    res = await this.httpApiService.findUserByRequestAction(action);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allKAMPersonList = data.data;
      console.log("get All Customer", this.allCustomer);
    }
  }

  async getCompanyDetail() {
    let companyName = await this.configService.getFromLocation();
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.companyDetails = data.data;
    }
  }

  // To company detail

  async toCompanyDetailByName(companyName) {
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  async getAllCustomerContact(id) {
    let res: any;
    res = await this.httpApiService.getCustomerContactList(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allCustomerContact = data.data;
      if (this.allCustomerContact.length > 0) {
        this.customerIdList = [];
        this.customerIdList.push({
          id: this.allCustomerContact[0].id,
          name: this.allCustomerContact[0].name,
        });
        this.customerContactId = this.allCustomerContact[0].id;
        this.nameOfPersonReceivingGood = this.allCustomerContact[0].name;
        this.getRequestedStoneList(
          this.requestActionFlag,
          this.customerContactId,
          this.pageNumber,
          this.noOfRecords,
          null
        );
      } else {
        this.stoneForCertificate = [];
        this.customerIdList = [];
        this.customerContactId = null;
      }
      console.log("get All allCustomerContact", this.allCustomerContact);
    }
  }

  async getDefaultSetting(userId, ActionId) {
    let res: any;
    res = await this.httpApiService.getDefaultSetting(userId, ActionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook;
        }
        if (!!dataValue.map.partyType) {
          this.partyType = JSON.parse(dataValue.map.partyType);
        }
        if (!!dataValue.map.toRole) {
          this.toRole = JSON.parse(dataValue.map.toRole);
        }
      }
    }
  }

  async getAllActions() {
    await this.configService.showLoader();
    let res: any;
    this.userData = await this.authService.userData();
    console.log(this.userData);
    res = await this.httpApiService.findAllRequestedAction();
    let resp = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", resp.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", resp.error);
    } else {
      let data = resp.data;
      // let data1 = [];
      // data.forEach(el => {
      //   if (
      //     el.actionName == "Certification" ||
      //     el.actionName == "Recut" ||
      //     el.actionName == "Requisition" ||
      //     el.actionName == "Observation" ||
      //     el.actionName == "Consignment" ||
      //     el.actionName == "Customer Hold" ||
      //     el.actionName == "Memo Service" ||
      //     el.actionName == "Self Observation" ||
      //     el.actionName == "Memo Client" ||
      //     el.actionName == "Customer Confirm" ||
      //     el.actionName == "Consignment Confirm" ||
      //     el.actionName == "Consignment In Stock"
      //     // el.actionName == "Return"
      //   ) {
      //     data1.push({ id: el.id, actionName: el.actionName, voucherType: el.voucherType });
      //   }
      // });
      this.requestAction = resp.data;
      console.log("list of actions by http", this.requestAction);
    }
  }

  async getStoneListByKAM(action, id, kamId, pageNumber, noOfRecords) {
    console.log(action, id, kamId);
    let res: any;
    res = await this.httpApiService.getStoneListByRequest(
      action,
      id,
      kamId,
      this.pageNumber,
      this.noOfRecords,
      null
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneForCertificate = data.data.content;
      console.log("stoneForCertificate", this.stoneForCertificate);
    }
  }

  //list of stones requested for different actions
  async getRequestedStoneList(
    action,
    id,
    pageNumber,
    noOfRecords,
    selectedServices
  ) {
    let res: any;
    res = await this.httpApiService.getStoneListByRequest(
      action,
      id,
      null,
      pageNumber,
      noOfRecords,
      selectedServices
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneForCertificate = data.data.content;
      if (this.stoneForCertificate.length > 0) {
        this.addSrNo = !!this.stoneForCertificate[0].addSrNo
          ? this.stoneForCertificate[0].addSrNo
          : null;
        this.roleId = this.stoneForCertificate[0].roleId;
        this.salesPersonName = this.stoneForCertificate[0].requestUser;
        this.transType = this.stoneForCertificate[0].transType;
        this.remarks = !!this.stoneForCertificate[0].remarks
          ? this.stoneForCertificate[0].remarks
          : null;
        this.terms = !!this.stoneForCertificate[0].termName
          ? this.stoneForCertificate[0].termName
          : 0;
        this.disc1 = !!this.stoneForCertificate[0].disc1
          ? this.stoneForCertificate[0].disc1
          : 0;
        this.disc2 = !!this.stoneForCertificate[0].disc2
          ? this.stoneForCertificate[0].disc2
          : 0;
      }
      console.log(
        "stoneForCertificate",
        this.stoneForCertificate,
        this.requestJson
      );
    }
  }

  //list of requested stones voucher wise

  async getRequestedStoneListByVoucherId(
    voucherId,
    actionName,
    pageNumber,
    noOfRecords
  ) {
    let res: any;
    res = await this.httpApiService.getRequestedStoneListByVoucherId(
      voucherId,
      actionName,
      pageNumber,
      noOfRecords
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneForCertificate = data.data.content;
      if (this.stoneForCertificate.length > 0) {
        this.addSrNo = !!this.stoneForCertificate[0].addSrNo
          ? this.stoneForCertificate[0].addSrNo
          : null;
        this.roleId = this.stoneForCertificate[0].roleId;
        this.salesPersonName = this.stoneForCertificate[0].requestUser;
        this.transType = this.stoneForCertificate[0].transType;
        this.remarks = !!this.stoneForCertificate[0].remarks
          ? this.stoneForCertificate[0].remarks
          : null;
        this.terms = !!this.stoneForCertificate[0].termName
          ? this.stoneForCertificate[0].termName
          : 0;
        this.disc1 = !!this.stoneForCertificate[0].disc1
          ? this.stoneForCertificate[0].disc1
          : 0;
        this.disc2 = !!this.stoneForCertificate[0].disc2
          ? this.stoneForCertificate[0].disc2
          : 0;
      }
      console.log(
        "stoneForCertificate",
        this.stoneForCertificate,
        this.requestJson
      );
    }
  }

  async onPageChange(currentPage) {
    this.pageNumber = currentPage.offset;
    if (!!this.voucherRequestData) {
      await this.getRequestedStoneListByVoucherId(
        this.voucherRequestData.id,
        this.voucherRequestData.requestFor,
        this.pageNumber,
        this.noOfRecords
      );
    } else {
    }
  }

  // get all company List & user list for recut and observation

  async getAllCompany() {
    let res: any;
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
      console.log("this.listOfLocations", this.listOfLocations);
    }
  }
  async getAllToUser(toLocation) {
    this.usersList = [];
    this.toUser = null;
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllUsersByCompany(appName, toLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.length > 0) {
        this.usersList = dataValue;
        console.log("usersList", this.usersList);
      } else {
        this.usersList = [];
      }
    }
  }

  //end

  async getUserData() {
    let userData = await this.authService.userData();
    this.type = userData.departmentName;
    this.userId = userData.userId;
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
    console.log("userData", userData);
  }

  //close modal

  async closeModal() {
    if (!!this.boardBaseUrl) {
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1, 2]);
    }
    this.modalCtrl.dismiss("doNotRefresh");
  }

  //open confirm modal

  async openConfirmModal() {
    console.log(this.selected);
    let flgCheck;
    if (
      this.requestActionFlag != "Requisition" &&
      this.requestActionFlag != "Self Observation"
    ) {
      let checkFxRate = !!this.selected[0].exgRate
        ? this.selected[0].exgRate
        : 0;
      let index = this.selected.findIndex((el) => el.exgRate != checkFxRate);
      if (index > -1) {
        flgCheck = true;
      }
      if (flgCheck) {
        const alert = await this.alertController.create({
          header: "Confirm!",
          message:
            "FxRate is different for selected stones.Click ok to merge the request OR Cancel to make separate voucher for different FxRate!",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                this.isMerge = false;
                this.transferRequest();
              },
            },
            {
              text: "Okay",

              handler: () => {
                this.isMerge = true;
                this.transferRequest();
              },
            },
          ],
        });
        await alert.present();
      } else {
        this.isMerge = true;
        this.transferRequest();
      }
    } else {
      this.transferRequest();
    }
  }

  //transfer Request
  async transferRequest() {
    // this.flagOpenDoc = true;
    // await this.getCompanyDetail()
    //  window.print()
    // return
    if (!!this.boardBaseUrl) {
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1, 2]);
    }
    if (!!this.voucherBookId) {
      let validate = await this.validateControls();
      if (validate) {
        let res: any;
        let jsonObj;
        console.log(this.isMerge);
        await this.configService.showLoader();
        if (this.requestActionFlag == "Certification") {
          this.requestJson = {};
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice
                ? Number(el.requestPrice).toFixed(2)
                : null,
              requestAmt: !!el.requestPrice
                ? Number(
                    el.requestPrice * (!!el.size ? el.size : el.stoneCts)
                  ).toFixed(2)
                : !!el.price
                ? Number(
                    el.price * (!!el.size ? el.size : el.stoneCts)
                  ).toFixed(2)
                : 0,
              listOfServices: el.setOfServices,
              exgRate: el.exgRate,
              termName: !!el.termName ? el.termName : 0,
              modeOfTransport: !!el.modeOfTransport ? el.modeOfTransport : null,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
              websiteContactId: !!el.websiteContactId
                ? el.websiteContactId
                : null,
            };
          });
          jsonObj = {
            requestDate: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            partyMasterId: this.customerId,
            isMerge: this.isMerge,
            roleId: this.roleId,
            addSrNo: this.addSrNo,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (
          this.requestActionFlag == "Recut" ||
          this.requestActionFlag == "Observation"
        ) {
          let actionName = this.requestActionFlag;
          let findVoucherType = this.requestAction.filter(function (item) {
            if (item.actionName == actionName) {
              return item.actionName;
            }
          });
          if (findVoucherType.length > 0) {
            this.voucherType = findVoucherType[0].voucherType;
          }
          this.requestJson = {};
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice ? el.requestPrice.toString() : null,
              exgRate: el.exgRate,
              modeOfTransport: !!el.modeOfTransport ? el.modeOfTransport : null,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
            };
          });
          let jsonObj = {
            requestDate: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
            setOfStoneIds: this.stoneForCertificateId,
            voucherBookId: parseInt(this.voucherBookId),
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            remarks: "stone send by userId" + this.toUser,
            type: this.voucherType,
            isMerge: this.isMerge,
            roleId: this.roleId,
            userId: this.toUser,
            addSrNo: this.addSrNo,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (this.requestActionFlag == "Requisition") {
          let actionName = this.requestActionFlag;
          let findVoucherType = this.requestAction.filter(function (item) {
            if (item.actionName == actionName) {
              return item.actionName;
            }
          });
          if (findVoucherType.length > 0) {
            this.voucherType = findVoucherType[0].voucherType;
          }
          this.requestJson = {};
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice ? el.requestPrice.toString() : null,
              exgRate: el.exgRate,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
            };
          });
          let jsonObj = {
            voucherDate: moment().format("YYYY-MM-DD"),
            setOfStoneIds: this.stoneForCertificateId,
            voucherBookId: parseInt(this.voucherBookId),
            stoneRequestParams: this.requestJson,
            remarks: "stone send by userId" + this.toUser,
            type: this.voucherType,
            toUser: this.toUser,
            customerContactId: this.customerContactId,
            roleId: this.roleId,
            requestFor: "Requisition",
            addSrNo: this.addSrNo,
            companyId: this.companyId,
          };
          console.log(jsonObj);
          res = await this.httpApiService.issuePickedUp(jsonObj);
        } else if (this.requestActionFlag == "Self Observation") {
          this.requestJson = {};
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice ? el.requestPrice.toString() : null,
              exgRate: el.exgRate,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
            };
          });
          let jsonObj = {
            voucherDate: moment().format("YYYY-MM-DD"),
            setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            voucherBookId: parseInt(this.voucherBookId),
            toUser: this.toUser,
            roleId: this.roleId,
            customerContactId: this.customerContactId,
            requestFor: "Self Observation",
            addSrNo: this.addSrNo,
            companyId: this.companyId,
          };
          console.log(jsonObj);
          res = await this.httpApiService.issuePickedUp(jsonObj);
        } else if (this.requestActionFlag == "Convert To Mix") {
          this.requestJson = {};
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice ? el.requestPrice.toString() : null,
              exgRate: el.exgRate,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
            };
          });
          let jsonObj = {
            voucherDate: moment().format("YYYY-MM-DD"),
            setOfStoneIds: this.stoneForCertificateId,
            voucherBookId: parseInt(this.voucherBookId),
            stoneRequestParams: this.requestJson,
            toRole: this.toRole,
            roleId: this.toRole,
            requestFor: "Convert To Mix",
            addSrNo: this.addSrNo,
            companyId: this.companyId,
          };
          console.log(jsonObj);
          res = await this.httpApiService.issuePickedUp(jsonObj);
        } else if (
          this.requestActionFlag == "Customer Confirm" ||
          this.requestActionFlag == "Consignment Confirm" ||
          this.requestActionFlag == "Sold" ||
          this.requestActionFlag == "Consignment In Stock"
        ) {
          this.requestJson = {};
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice ? el.requestPrice.toString() : null,
              exgRate: el.exgRate,
              termName: !!el.termName ? el.termName : 0,
              modeOfTransport: !!el.modeOfTransport ? el.modeOfTransport : null,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
              websiteContactId: !!el.websiteContactId
                ? el.websiteContactId
                : null,
            };
          });

          jsonObj = {
            requestDate: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            // setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            isMerge: this.isMerge,
            customerContactId: this.customerContactId,
            roleId: this.roleId,
            memo: this.memo,
            invoiceDate: this.invoiceDate,
            invoiceNo: this.trackingNo,
          };
          if (!!this.invoiceDate) {
            jsonObj.invoiceDate = moment(this.invoiceDate).format(
              "DD-MMM-YYYY"
            );
          }
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (this.requestActionFlag == "Memo Client") {
          this.requestJson = {};
          console.log(this.selected);
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice ? el.requestPrice.toString() : null,
              exgRate: el.exgRate,
              termName: !!el.termName ? el.termName : 0,
              modeOfTransport: !!el.modeOfTransport ? el.modeOfTransport : null,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
            };
          });
          jsonObj = {
            requestDate: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            // setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            isMerge: this.isMerge,
            requestAction: this.selectedAction,
            roleId: this.roleId,
            customerContactId: this.customerContactId,
            trackingNo: this.trackingNo,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (this.requestActionFlag == "Customer Hold") {
          this.requestJson = {};
          console.log(this.selected);
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice ? el.requestPrice.toString() : null,
              exgRate: el.exgRate,
              termName: !!el.termName ? el.termName : 0,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
            };
          });
          jsonObj = {
            requestDate: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            // setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            isMerge: this.isMerge,
            roleId: this.roleId,
            customerContactId: this.customerContactId,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (this.requestActionFlag == "Memo Service") {
          this.requestJson = {};
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              requestUserId: el.requestUserId.toString(),
              rapPrice: !!el.rapPrice ? el.rapPrice : null,
              requestPrice: el.requestPrice ? el.requestPrice.toString() : null,
              exgRate: el.exgRate,
              modeOfTransport: !!el.modeOfTransport ? el.modeOfTransport : null,
              stoneRemarks: !!el.stoneRemarks ? el.stoneRemarks : "",
            };
          });
          jsonObj = {
            requestDate: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            // setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            isMerge: this.isMerge,
            roleId: this.roleId,
            customerContactId: this.customerContactId,
            addSrNo: this.addSrNo,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        }

        let data = res.json();
        await this.configService.dismiss();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          // await this.configService.showToast("error", data.error);
          if (data.error.includes("Lock wait")) {
            await this.configService.showToast(
              "error",
              "There's an issue while transferring stone. Please wait we are sending transfer request again"
            );
            this.transferRequest();
          } else {
            await this.configService.showToast("error", data.error);
          }
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          console.log(data.data);
          if (!!data.data && data.data.length == 1) {
            await this.configService.showToast("success", data.message);
            this.voucherId = data.data[0].id;
            if (data.data[0].shortCode && data.data[0].bookSerialNumber) {
              this.deliveryChallanNo =
                data.data[0].shortCode + "-" + data.data[0].bookSerialNumber;
            }
            // if (!!data.data[0].url) {
            //   this.url = data.data[0].url;
            // }
            if (!!data.data && !!data.data[0].exgRate) {
              this.fxRate = data.data[0].exgRate;
            }
            if (!!data.data[0].modeOfTransport) {
              console.log(data.data[0].modeOfTransport);
              this.modeOfTransport = data.data[0].modeOfTransport;
            }
            // if (!!data.data[0].isApproved && (this.requestActionFlag == 'Sold' || this.requestActionFlag == 'Certification' || this.requestActionFlag == 'Customer Hold' || this.requestActionFlag == 'Memo Service' || this.requestActionFlag == 'Memo Client' || this.requestActionFlag == 'Customer Confirm' || this.requestActionFlag == 'Recut' || this.requestActionFlag == 'Observation' || this.requestActionFlag == 'Consignment Confirm' || this.requestActionFlag == 'Consignment In Stock')) {
            //   this.flagOpenDoc = true;
            // } else {
            await this.modalCtrl.dismiss();
            // }
          } else if (!!data.data && data.data.length > 1) {
            await this.configService.showToast(
              "success",
              "Multiple voucher has been generated, You can download challan from external Inventory."
            );
            await this.modalCtrl.dismiss();
          } else {
            await this.configService.showToast("success", data.message);
            await this.modalCtrl.dismiss();
          }
          this.rfidList = [];
        }
      }
    } else {
      await this.configService.showToast("error", "Voucher book not found.");
    }
  }

  async validateControls() {
    console.log(this.stoneForCertificateId.length, this.customerContactId);
    if (this.requestActionFlag == "Certification") {
      if (this.stoneForCertificateId.length > 0 && !!this.customerId) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (
      this.requestActionFlag == "Recut" ||
      this.requestActionFlag == "Observation" ||
      this.requestActionFlag == "Requisition"
    ) {
      if (this.stoneForCertificateId.length > 0 && !!this.toUser) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (
      this.requestActionFlag == "Customer Confirm" ||
      this.requestActionFlag == "Consignment Confirm" ||
      this.requestActionFlag == "Consignment In Stock" ||
      this.requestActionFlag == "Memo Client" ||
      this.requestActionFlag == "Sold" ||
      this.requestActionFlag == "Customer Hold"
    ) {
      if (this.stoneForCertificateId.length > 0 && !!this.customerContactId) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (this.requestActionFlag == "Memo Service") {
      if (this.stoneForCertificateId.length > 0 && !!this.customerContactId) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (this.requestActionFlag == "Self Observation") {
      if (this.stoneForCertificateId.length > 0 && !!this.toUser) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (this.requestActionFlag == "Convert To Mix") {
      if (this.stoneForCertificateId.length > 0 && !!this.toRole) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    }
  }

  // partial selection on request

  async selectAll(item) {
    if (item == true) {
      let arr = [];
      this.rfidList = [];
      this.selected = arr.concat(this.stoneForCertificate);
      this.isSelect = true;
      this.stoneForCertificateId = [];
      this.selected.forEach((el) => {
        if (el.rfid) {
          this.rfidList.push(el.rfid);
        }
        this.stoneForCertificateId.push(parseInt(el.id));
      });
    } else {
      this.rfidList = [];
      this.isSelect = false;
      this.selected = [];
      this.stoneForCertificateId = [];
    }
    console.log(this.stoneForCertificateId);
  }

  selectOutRequestData(ev) {
    console.log(ev);
    let dataArr = [];
    console.log(this.stoneForCertificateId.length);
    if (ev.currentDeselectedRowKeys.length > 0) {
      let deselectIds = ev.currentDeselectedRowKeys.map((el) => el.id);
      this.stoneForCertificateId = this.stoneForCertificateId.filter(function (
        el
      ) {
        return deselectIds.indexOf(el) < 0;
      });
      this.selected = this.selected.filter(function (el) {
        return (
          ev.currentDeselectedRowKeys.findIndex((item) => item.id == el.id) < 0
        );
      });
    }
    dataArr = ev.selectedRowKeys.filter(
      (el) =>
        this.stoneForCertificateId.filter((item) => item == el.id).length == 0
    );
    // this.selected = this.selected.concat(dataArr)
    this.selected = ev.selectedRowKeys;
    this.stoneForCertificateId = this.stoneForCertificateId.concat(
      dataArr.map((el) => el.id)
    );
    console.log(
      "SELECTEDDDDDD",
      this.stoneForCertificateId,
      this.selected,
      this.ledOnStonesIndex
    );
  }

  // temp
  async openDoc() {
    this.flagOpenDoc = true;
    await this.getCompanyDetail();
    await this.configService.print('');
    // await this.modalCtrl.dismiss();
  }

  //led on
  async findByLED() {
    // if (this.rfidList.length > 0) {
    // let rfidList = []
    // this.stoneForCertificate.forEach(el => {
    //   if (el.rfid) {
    //     rfidList.push(el.rfid)
    //   }
    // })

    let rfids = this.stoneForCertificate.map((el) => el.rfid);
    if (!!this.boardBaseUrl) {
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1, 2]);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.scanWithLightOn(this.boardBaseUrl, rfids);
      console.log(res);
      // let data = res.json();
      console.log("findBy LED", res);
      this.configService.dismiss();
    } else {
      await this.configService.showToast(
        "error",
        "Scanner Board Url not found"
      );
    }
    // }
    // else {
    //   await this.configService.showToast("error", "stone not found!");
    // }
  }

  //scanToSelect

  async scanToSelect() {
    this.filtered = [];
    let res;
    if (!!this.boardBaseUrl) {
      this.currentScanCount = 0;
      let rfIdList = [];
      this.scannedRFid = [];
      this.scanCount = 0;
      await this.configService.showLoader();
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1, 2]);
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      if (data.type == "error") {
        await this.configService.dismiss();
        await this.configService.showToast("error", data.type);
      } else {
        let rfids = [];
        if (data.result.length > 0) {
          this.selected = [];
          data.result = data.result.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });
          this.scannedRFid = data.result;
          let jsonObj;
          jsonObj = {
            paramName: "rfid",
            setOfValues: data.result,
            companyId: this.companyId,
          };
          let res2 = await this.httpApiService.getStoneIdByRfid(jsonObj);
          let resData = res2.json();
          this.boxDetail = resData.data.boxRfIdsList;
          this.scanCount = data.result.length;
          if (this.boxDetail.length > 0) {
            this.scanCount = this.scanCount - this.boxDetail.length;
          }
          for (let i = 0; i < this.stoneForCertificate.length; i++) {
            if (
              this.scannedRFid.indexOf(this.stoneForCertificate[i].rfid) > -1
            ) {
              let ledOnCount = this.ledOnStonesIndex.filter(
                (el) => el.id == this.stoneForCertificate[i].id
              ).length;
              // let ledOnCount = this.ledOnStonesIndex.filter(el => el == i).length
              if (ledOnCount == 0) {
                this.currentScanCount = this.currentScanCount + 1;
                this.ledOnStonesIndex.push(this.stoneForCertificate[i]);
                // rfIdList.push(this.stoneForCertificate[i].rfid)
              }
              let selectedCount = this.stoneForCertificateId.filter(
                (el) => el == this.stoneForCertificate[i].id
              ).length;
              if (selectedCount == 0) {
                this.selected.push(this.stoneForCertificate[i]);
                this.stoneForCertificateId.push(this.stoneForCertificate[i].id);
              }
            }
          }
          this.ledOn = true;
          setTimeout(() => {
            this.ledOn = false;
          }, 1000);
          // console.log(rfIdList)
          this.configService.dismiss();
          // if (rfIdList.length > 0) {
          //   await this.httpApiService.scanWithLightOn(this.boardBaseUrl, rfIdList);
          // }

          // this.selected = [];
          //   this.stoneForCertificateId = []
          //   for (let i = 0; i < data.result.length; i++) {
          //     let index = this.stoneForCertificate.findIndex(item => item.rfid == data.result[i]);
          //     if (index > -1) {
          //       rfids.push(data.result[i])

          //       this.ledOnStonesIndex.push(i);
          //       this.selected.push(this.stoneForCertificate[index])
          //       this.stoneForCertificateId.push(parseInt(this.stoneForCertificate[index].id));
          //     }
          //   }
          //   await this.httpApiService.scanLedOff(this.boardBaseUrl, [1, 2]);
          //   await this.httpApiService.scanWithLightOn(this.boardBaseUrl, rfids);
          //   if (this.stoneForCertificateId.length == this.stoneForCertificate.length) {
          //     this.isSelect = true;
          //   } else {
          //     this.isSelect = false;
          //   }
          console.log(this.selected, this.stoneForCertificateId);
        } else {
          await this.configService.showToast(
            "error",
            "Stones not found on board"
          );
        }
        await this.configService.dismiss();
      }
    } else {
      await this.configService.showToast(
        "error",
        "Scanner Board Url not found"
      );
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split("/", 3).join("/").length;
      return stoneId
        .split(stoneId.substring(index + 1, stoneId.length))
        .join(
          stoneId
            .substring(index + 1, stoneId.length)
            .slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))
        );
    } else {
      return stoneId;
    }
  }

  async download() {
    await this.httpApiService.downloadPackingList(this.url);
  }

  customizeSum(data) {
    if (!!data.value && typeof data.value == "number")
      return data.value.toFixed(3);
    else return data.value;
  }

  //Release stones Request

  async releaseStoneConfirm(ev) {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to release these stones?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteRequestedStones(ev.row.data.id);
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteRequestedStones(stoneId) {
    let res: any;
    let voucherAndStoneIdsList: any = [];
    await this.configService.showLoader();
    voucherAndStoneIdsList.push({
      voucherId: !!this.voucherRequestData.id
        ? this.voucherRequestData.id
        : this.data.id,
      stoneIds: [stoneId],
    });
    let payload = {
      voucherAndStoneIdsList: voucherAndStoneIdsList,
      isSvcRequest: false,
    };
    console.log(payload);
    res = await this.httpApiService.deleteRequestedStones(payload);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.dismiss();
      await this.configService.showToast("success", data.message);
      await this.getRequestedStoneListByVoucherId(
        this.voucherRequestData.id,
        this.voucherRequestData.requestFor,
        this.pageNumber,
        this.noOfRecords
      );
    }
  }
}
