import { Component, Input } from '@angular/core';
import {
  ModalController,
  LoadingController,
  Events,
  AlertController,
} from '@ionic/angular';
import { ProcessActionComponent } from '../../processAction';
import { HttpApiService } from '../../../services/http-api/http-api.service';
import { ConfigService } from '../../../services/config/config.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import CustomStore from 'devextreme/data/custom_store';
import Query from 'devextreme/data/query';

@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.scss'],
})
export class BulletinComponent implements ProcessActionComponent {
  @Input() data: any;

  columns: any[] = [];
  description: string = '';
  public pageSize = 50;
  listOfDataSource: any = {};
  pageNumber: number = 0;
  historyData: any[] = [];
  public refreshGrid = false;
  page = 'bulletin';

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public event: Events,
    public alertCtrl: AlertController
  ) { }

  async ngOnInit() {
    this.columns = [
      { name: 'Author', prop: 'createdBy' },
      { name: 'Notes', prop: 'description' },
      { name: 'Date & Time', prop: 'createdAt' },
    ];
    await this.getBulletinList();
    this.event.subscribe('Bulletin', (item) => {
      this.getBulletinList();
    });
  }

  async deleteData(id) {
    await this.configService.showLoader();
    let res = await this.httpApiService.deleteBulletinByID(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 400) {
      await this.configService.showToast('error', data.message);
    } else if (!data.isSuccess) {
      await this.configService.showToast('error', data.data);
    } else {
      await this.configService.showToast('success', data.data);
    }
    await this.getBulletinList();
  }

  deleteItem(ev) {
    this.confirmDeleteData(ev.row.data);
  }
  async confirmDeleteData(item) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm!',
      message: 'Are you sure you want to delete this ?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: del');
          },
        },
        {
          text: 'Okay',

          handler: () => {
            console.log('okay deleting');
            this.deleteData(item.id);
          },
        },
      ],
    });

    await alert.present();
  }

  async getBulletinList() {
    this.listOfDataSource = new CustomStore({
      key: 'id',
      load: async (loadOptions: any) => {
        console.log('loadOptions ', loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getBulletinList(
            this.pageNumber,
            this.pageSize
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast('error', data.error);
          } else if (res.status == 400) {
            await this.configService.showToast('error', data.error);
          } else {
            this.historyData = data.content;
            return {
              data: Query(data.content).toArray(),
              totalCount: data.totalElements,
              groupCount: data.totalElements,
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.historyData.length,
            groupCount: this.historyData.length,
          };
        }
      },
    });
  }

  async addDesc() {
    if (!!this.description) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        "item1": "",
        "item2": "",
        "description": this.description,
        "isDeleted": false,
      }
      console.log(jsonObj)
      res = await this.httpApiService.addBulletin(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        this.getBulletinList();
      }
    }
  }

}
