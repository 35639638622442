export default {
  reportNo: 'R-21',
  url: "master-service/api/stone_movement/assortToSales",
  method: "GET",
  size: 3,
  fields: [
    {
      title: ' From Date ',
      input: 'dateTime',
      key: 'fromDate',
      placeholder: 'Select From Date',
      property: 'fromDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
      title: ' To Date ',
      input: 'dateTime',
      key: 'toDate',
      placeholder: 'Select To Date',
      property: 'toDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
  ],
  columns: [
    { name: "Assort Cssp", prop: "fromCssp" },
    {
      name: "Cts", prop: "stoneSize",
      summaryType: 'sum',
      showInGroupFooter: true
    },
    { name: "Assort Price", prop: "assortmentPrice" },
    { name: "Assort Amount", prop: "assortmentAmount" },
    {
      name: "Transfer Cts", prop: "transferCts",
      summaryType: 'sum',
      showInGroupFooter: true
    },
    { name: "Sales Cssp", prop: "toCssp" },
    { name: "Sales Price", prop: "salesPrice" },
    { name: "Sales Amount", prop: "salesAmount" },
    { name: "Date & Time", prop: "createdDate" },
    { name: "Parcel No", prop: "toStoneboxLabel" }
  ],
};