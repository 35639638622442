import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AlertController,
  Events,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import {
  default as DataGrid,
  default as dxDataGrid,
} from 'devextreme/ui/data_grid';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import { ProcessActionComponent } from '../../processAction';
import { ManageLayoutsComponent } from '../manage-layouts/manage-layouts.component';
import CustomStore from 'devextreme/data/custom_store';
import Query from 'devextreme/data/query';
@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss'],
})
export class LayoutsComponent implements ProcessActionComponent {
  @Input() data: any;
  public selectedRole = '';
  @Input() selected: any = [];
  @Input() refreshGrid: boolean = false;
  @Input() refreshMobileGrid = false;
  dataGridInstance: DataGrid;
  dataSource: any = [];
  page = 'Layout';
  public temp = 0;
  public manageSelectedRole = '';
  listOfLayouts: any;
  public arrowKeyLocation = -1;
  public selectedRows = 0;
  public flagButton = '';
  public selectedRowKeys = [];
  propsForItem: {};
  public itemsActions: any;
  public listOfActions: any;
  public id: any;
  public roleName = '';
  public description: '';
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  items: any;
  public roleId: any;
  selectedRoleId: any;
  selectedDescription: any;
  public searchPermission: string = '';
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public columnName: any;
  categoryItems: any;
  layoutData: any;
  dataForUpdateApi: any;
  categoryIdforDelete: any;
  isActiveForCategory: any;
  public pageSize: any = 50;
  noOfRecords = 5;
  pageNumber = 0;
  totalCount = 0;
  public count: any;
  columns = [];
  constructor(
    private authService: AuthenticationService,
    private httpApiService: HttpApiService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadingController: LoadingController,
    private configService: ConfigService,
    private event: Events
  ) {
    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        },
      },
      { name: 'Layout Id', prop: 'layoutId' },
      { name: 'Created At', prop: 'createdDate' },
      { name: 'Total Pieces', prop: 'totalPcs' },
      { name: 'Total Carat', prop: 'totalCarat' },
      { name: 'Price Per Carat', prop: 'totalPricePerCarat' },
      { name: 'Total Price', prop: 'totalPrice' },
      { name: 'Shapes', prop: 'shapeRange' },
      { name: 'Color Range', prop: 'colorRange' },
      { name: 'Clarity Range', prop: 'clarityRange' },
      { name: 'Length Range', prop: 'lengthRange' },
      { name: 'Width Range', prop: 'widthRange' },
    ];
  }

  ngOnInit() {
    this.getAllLayouts();
  }

  getAllLayouts() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async (loadOptions: any) => {
        console.log('loadOptions ', loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllLayouts(
            this.noOfRecords,
            this.pageNumber
          );
          let data = res.json();

          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast('error', data.error);
          } else if (res.status == 400) {
            await this.configService.showToast('error', data.error);
          } else {
            this.layoutData = data.data.content;
            this.totalCount = data.data.totalCount;
            return {
              data: this.layoutData,
              totalCount: this.totalCount, //data.data.totalElements,
              groupCount: this.totalCount, // data.data.totalElements
            };
          }
        } else {
          return {
            data: this.layoutData,
            totalCount: this.totalCount,
            groupCount: this.totalCount,
          };
        }
      },
    });
  }

  OnSelectRows(el) {
    this.temp = 1;
  }

  async back() {
    this.temp = 0;
    // this.criteriaList = [];
  }
}
